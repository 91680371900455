import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { CookieService } from 'ngx-cookie-service';
import { musys } from 'src/environments/environment';
import jwtDecode from 'jwt-decode';
import { SharedService } from '../service/shared.service';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard {
  constructor(
    public auth: AuthService,
    private cookieService: CookieService,
    private shared: SharedService
  ) { }

  canActivate(marketId?: number):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    var returnUrl = marketId ? document.location.href  + `?mkt=${marketId}` : document.location.href;

    if (!this.auth.checkMusysToken()) {
      document.getElementById('principal').style.display = 'none'
      document.getElementById('loadingScreen').style.display = 'flex'
      if (!this.auth.checkFederationGatewayToken()) {
        //** one-time code returned, generate FG JWT token */
        if (document.location.href.indexOf('&id_token') != -1) {
          var tokenId = document.location.href
            .substring(document.location.href.indexOf('&id_token') + 10)
            .split('&')[0];
          var expirationTime = parseInt(
            document.location.href
              .substring(document.location.href.indexOf('&expires_in') + 12)
              .split('&')[0]
          );
          var date = new Date();
          var expires = new Date(date.getTime() + expirationTime * 1000);

          returnUrl = window.location.href.split('#')[0];
          this.cookieService.set(
            'Musys.Ui.User.Authentication.Token',
            tokenId,
            expires,
            '/'
          );
          window.location.href = returnUrl;
        }
        //** redirect to FG login screen */
        else {
          document.location.href = `${musys.keyCloakBaseUrl
            }/auth/realms/scania/protocol/openid-connect/auth?client_id=${musys.keyCloakClientId
            }&response_type=token%20id_token&scope=email&redirect_uri=${encodeURIComponent(
              returnUrl
            )}&nonce=foo`;
        }
      }
      //** use currently valid FG token to get a new Musys token */
      else {
        var decodedToken = jwtDecode<any>(
          this.auth.getFederationGatewayTokenFromCookie()
        );
        localStorage.setItem(
          'Musys.Ui.Markets.User',
          decodedToken.wam_user.global
        );

        marketId = marketId ? marketId : document.location.href.includes('?mkt=')  ? parseInt(document.location.href.substring(document.location.href.indexOf('?mkt=') + 5)) : -1
        
        var result = this.auth.getMusysToken(
          decodedToken.wam_user.global,
          marketId,
          this.auth.getFederationGatewayTokenFromCookie()
        );

        if (!result.Success) {
          console.log(`Error getting token:\n${result.Errors.join('\n')}`);
          location.href = returnUrl;
        }

        this.cookieService.set(
          'Musys.Ui.User.UserToken.Current',
          result.Data.AccessToken,
          new Date(result.Data.Expires), '/'
        );
        this.cookieService.set(
          'Musys.Ui.User.Username',
          decodedToken.wam_user.global, null, '/'
        );
        this.cookieService.set('Musys.Ui.User.UserFullName', decodedToken.name, null, '/');
        // Get User Permissions
        var decodedMusysToken = jwtDecode<any>(result.Data.AccessToken);
        decodedMusysToken = JSON.parse(decodedMusysToken.User);

        this.shared.setMarketCookies(
          decodedMusysToken.Market.Id,
          decodedMusysToken.Market.Code,
          decodedMusysToken.Market.Name,
          decodedMusysToken.Market.System == 1 ? 'MUSYS-BR' : 'MUSYS-LA'
        );
        
        if (!marketId) {
          this.shared.UpdatePermissions();
        }

        location.href.indexOf('?') != -1 ? location.href =  location.href.substring(0, location.href.indexOf('?')) : location.href.indexOf('#') != -1 ? location.href =  location.href.substring(0, location.href.indexOf('#')) : location.reload()
      }
    }
    return true;
  }
}
