import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccessComponent } from './component/menu/admin/access/access.component';
import { AdminComponent } from './component/menu/admin/admin.component';
import { SalesComponent } from './component/menu/sales/sales.component';
import { BasicDataComponent } from './component/menu/admin/basic-data/basic-data.component';
import { AppComponent } from './app.component';
import { HomeComponent } from './component/home/home.component';
import { LoginGuard } from './auth/login.guard';
import { PurchaseComponent } from './component/menu/purchase/purchase.component';
import { MarketsComponent } from './component/menu/admin/markets/markets.component';
import { PartnerInformationComponent } from './component/menu/admin/partner-information/partner-information.component';
import { MarketUnitInformationComponent } from './component/menu/admin/market-unit-information/market-unit-information.component';
import { DealerAdminComponent } from './component/menu/admin/dealer-admin/dealer-admin.component';
import { DeliveryAddressComponent } from './component/menu/admin/delivery-address/delivery-address.component';
import { FinancialParametersComponent } from './component/menu/admin/financial-parameters/financial-parameters.component';
import { ExchangeRateComponent } from './component/menu/admin/exchange-rate/exchange-rate.component';
import { RegionInformationComponent } from './component/menu/admin/region-information/region-information.component';
import { FfuAndSorderComponent } from './component/menu/admin/ffu-and-sorder/ffu-and-sorder.component';
import { DiscountRateComponent } from './component/menu/admin/discount-rate/discount-rate.component';
import { DealerAccessComponent } from './component/menu/admin/dealer-access/dealer-access.component';
import { LanguageComponent } from './component/menu/admin/language/language.component';
import { MarketParameterComponent } from './component/menu/admin/market-parameter/market-parameter.component';
import { DealerGovernanceComponent } from './component/menu/admin/dealer-governance/dealer-governance.component';
import { AdmMktDaysComponent } from './component/menu/admin/adm-mkt-days/adm-mkt-days.component';

const routes: Routes = [
  {
    path: "",
    canActivate: [() => inject(LoginGuard).canActivate()],
    children: [
      { path: "home", component: HomeComponent },
      {
        path: "admin",
        component: AdminComponent,
        data: { breadcrumb: { disable: true } },
        children: [
          { path: 'access', component: AccessComponent },
          { path: 'basic-data', component: BasicDataComponent},
          { path: 'markets', component: MarketsComponent},
          { path: 'partner-information', component: PartnerInformationComponent},
          { path: 'market-unit-information', component: MarketUnitInformationComponent},
          { path: 'dealer-admin', component: DealerAdminComponent},
          { path: 'delivery-address', component: DeliveryAddressComponent},
          { path: 'financial-parameters', component: FinancialParametersComponent},
          { path: 'region-information', component: RegionInformationComponent},
          { path: 'ffu-and-sorder', component: FfuAndSorderComponent},
          { path: 'discount-rate', component: DiscountRateComponent},
          { path: "exchange-rate", component: ExchangeRateComponent },
          { path: 'dealer-access', component: DealerAccessComponent},
          { path: 'adm-mkt-days', component: AdmMktDaysComponent}
,
          { path: 'language', component: LanguageComponent },
          { path: 'market-parameter', component: MarketParameterComponent},
          { path: 'dealer-governance', component: DealerGovernanceComponent}
        ],
      },
      { path: "purchase", component: PurchaseComponent },
      { path: "sales", component: SalesComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
