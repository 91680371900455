import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { Result } from '../models/result.model';
import { DeliveryAddress } from '../models/deliveryaddress.model';
import { musys } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class DeliveryAddressService {

  constructor(private httpClient: HttpClient, private auth: AuthService, private cookie: CookieService) { }


  getDeliveryAddressAll(): Observable<Result<DeliveryAddress[]>> {
    return this.httpClient.get<Result<DeliveryAddress[]>>(
      musys.api + `/delivery-address`
    );
  }

  postDeliveryAddress(request: DeliveryAddress): Observable<Result<any>> {
    return this.httpClient.post<any>(musys.api + '/delivery-address', JSON.stringify(request));
  }

  putDeliveryAddress(request: DeliveryAddress): Observable<Result<number>> {
    return this.httpClient.put<Result<number>>(musys.api + '/delivery-address', request
    );
  }

  getDeliveryAddress(countryCode: string): Observable<Result<DeliveryAddress[]>> {
    return this.httpClient.get<Result<DeliveryAddress[]>>(
      musys.api + `/delivery-address/${countryCode}`
    );
  }

  deleteDeliveryAddress(country: string, code: string): Observable<Result<DeliveryAddress[]>> {
    return this.httpClient.delete<Result<DeliveryAddress[]>>(
      musys.api + `/delivery-address/${country}/${code}`
    );
  }
}

