import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { City } from 'src/app/models/city.model';
import { Country } from 'src/app/models/country.model';
import { Result } from 'src/app/models/result.model';
import { State } from 'src/app/models/state.model';
import { musys } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeoService {

  constructor(private http: HttpClient) {}

  listCountries():Observable<Result<Country[]>> {
    return this.http.get<Result<Country[]>>(musys.api + `/geo/countries`);
  }

  listStates(country: string):Observable<Result<State[]>> {
    return this.http.get<Result<State[]>>(musys.api + `/geo/${country}/states`);
  }

  listCities(country: string, state: string):Observable<Result<City[]>> {
    return this.http.get<Result<City[]>>(musys.api + `/geo/${country}/${state}/cities`);
  }

  createCountry(request):Observable<Result<boolean>> {
    return this.http.post<Result<boolean>>(musys.api + `/geo/country`, request);
  }

  createState(request):Observable<Result<boolean>> {
    return this.http.post<Result<boolean>>(musys.api + `/geo/state`, request);
  }

  createCity(request):Observable<Result<boolean>> {
    return this.http.post<Result<boolean>>(musys.api + `/geo/city`, request);
  }

  updateCountry(request):Observable<Result<boolean>> {
    return this.http.put<Result<boolean>>(musys.api + `/geo/country`, request);
  }

  updateState(request):Observable<Result<boolean>> {
    return this.http.put<Result<boolean>>(musys.api + `/geo/state`, request);
  }

  updateCity(request):Observable<Result<boolean>> {
    return this.http.put<Result<boolean>>(musys.api + `/geo/city`, request);
  }

  deleteCountry(countryName: string):Observable<Result<boolean>> {
    return this.http.delete<Result<boolean>>(musys.api + `/geo/country/${countryName}`);
  }

  deleteState(request):Observable<Result<boolean>> {
    return this.http.delete<Result<boolean>>(musys.api + `/geo/state/${request.countryCode}/${request.stateName}`);
  }

  deleteCity(request):Observable<Result<boolean>> {
    return this.http.delete<Result<boolean>>(musys.api + `/geo/city/${request.countryCode}/${request.stateCode}/${request.cityname}`);
  }
}
