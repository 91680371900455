import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DiscountRateGroup } from 'src/app/models/discountrategroup.model';
import { DiscountRateType } from 'src/app/models/discountratetype.model';
import { DiscountRatePriceType } from 'src/app/models/discountratetypeprice.model';
import { DiscountRateService } from 'src/app/service/api/discount-rate.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-discount-rate',
  templateUrl: './discount-rate.component.html',
  styleUrls: ['./discount-rate.component.css']
})
export class DiscountRateComponent {
  searched: boolean = false;

  discountTypeList: DiscountRateType[] = [];
  priceTypeList: DiscountRatePriceType[] = [];
  discountRateGroup: DiscountRateGroup[];

  discountHeaderForm: FormGroup;
  get header() {
    return this.discountHeaderForm.controls;
  }

  discountRateList: FormGroup;
  get disc() {
    return this.discountRateList.get('DiscountRateRequest') as FormArray;
  }


  constructor(public shared: SharedService, private discountRate: DiscountRateService, private fb: FormBuilder) { }

  ngOnInit() {
    this.getDiscounRateHeader();
    this.createDiscountHeaderForm();
    this.createDiscountRateList();
    this.getDiscounRateGroup();
  }

  createDiscountHeaderForm() {
    this.discountHeaderForm = this.fb.group({
      discountTypeId: ["", Validators.required],
      discountPriceType: ["", Validators.required]
    });
  }

  createDiscountRateList() {
    this.discountRateList = this.fb.group({
      DiscountRateRequest: this.fb.array([]),
    });
  }

  setHeaderDropdown(event: any, index?: number) {
    var control = event.currentTarget.attributes.formcontrolname.value;
    this.header[control].patchValue(event.target.value);
  }

  setDetailDropdown(event: any, index?: number) {
    var control = event.currentTarget.attributes.formcontrolname.value.replace(/\d+/g, '');
    this.disc.controls[index].get(control).patchValue(event.target.value);
  }

  setDefaultDropdown(ReferenceNumber, index) {
    var value = this.discountRateGroup.find(d => d.Id == ReferenceNumber)
    document.getElementById(`ReferenceNumber${index}`).setAttribute('placeholder', value.Id);
  }
  
  // Função criada para transformar o valor do campo em número. Caso contrário, 
  // o valor ficará em formato de string, atrapalhando a requisição.
  setValueToNumber(index) {
    var value = isNaN(this.disc.controls[index].get('Value').value) ? '' : parseInt(this.disc.controls[index].get('Value').value)
    this.disc.controls[index].get('Value').patchValue(value);
  }

  setHeaderNames() {
    document.getElementById('role').setAttribute('column-title', this.shared.translate('Role'));
    document.getElementById('value').setAttribute('column-title', this.shared.translate('Value'));
    document.getElementById('validDate').setAttribute('column-title', this.shared.translate('Valid Date'));
  }

  getDiscounRateHeader() {
    this.discountRate.getDiscountRateHeader().subscribe({
      next: (result) => {
        this.discountTypeList = result.Data.DiscountTypes;
        this.priceTypeList = result.Data.PriceTypes;
      }
    })
  }

  getDiscounRateGroup() {
    this.discountRate.getDiscountRateGroup().subscribe({
      next: (result) => {
        this.discountRateGroup = result.Data;
      }
    })
  }

  getDiscountRateDetail() {
    if (!this.discountHeaderForm.valid) {
      this.shared.errorAlert(this.shared.translate("Please, fill all required fields!"))
      return
    }
    this.discountRate.getDiscountRate(this.discountHeaderForm.value).subscribe({
      next: (result) => {
        this.searched = true;
        
        this.createDiscountRateList();
        result.Data.forEach(d => {
          this.disc.push(this.fb.group({
            Type: [d.Type, Validators.required],
            ReferenceType: [d.ReferenceType, Validators.required],
            ReferenceNumber: [d.ReferenceNumber, Validators.required],
            ReferenceName: [this.discountRateGroup.find(x => x.Id == d.ReferenceNumber).Name],
            Value: [d.Value, Validators.required],
            ValidDate: [new Date(d.ValidDate).toISOString().substring(0, 10), Validators.required]
          }));
        });
      }
    })
  }

  addDiscountRate() {
    this.disc.push(this.fb.group({
      Type: [parseInt(this.discountHeaderForm.get('discountTypeId').value), Validators.required],
      ReferenceType: [this.discountHeaderForm.get('discountPriceType').value, Validators.required],
      ReferenceNumber: [null, Validators.required],
      ReferenceName: [null],
      Value: [null, Validators.required],
      ValidDate: [null, Validators.required]
    }))
  }

  saveDiscountRate() {
    if (!this.discountRateList.valid) {
      this.discountRateList.markAllAsTouched();
      this.shared.errorAlert(this.shared.translate("Please, fill all required fields!"))
      return
    }

    this.discountRate.saveDiscountRate(this.discountHeaderForm.get('discountTypeId').value, this.discountHeaderForm.get('discountPriceType').value, this.disc.value).subscribe({
      next: (result) => {
        this.shared.successAlert('Discount Rate saved successfully!');
      },
      error: (error) => {
        this.shared.translate('Error while saving Discount Rate!');
      }
    })
  }

  deleteDiscountRate(index) {
    this.disc.removeAt(index);
  }
}
