import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DealerGroupGovernanceResponse } from 'src/app/models/dealergroupgovernanceresponse.';
import { Result } from 'src/app/models/result.model';
import { musys } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DealerGovernanceService {

  constructor(private http: HttpClient) { }

  getList():Observable<Result<DealerGroupGovernanceResponse[]>> {
    return this.http.get<Result<DealerGroupGovernanceResponse[]>>(musys.api + `/dealer/governance`);
  }

  getCommercialCategories():Observable<Result<any>> {
    return this.http.get<Result<any>>(musys.api + `/dealer/governance/commercial-category`);
  }

  addManager(request):Observable<Result<any>> {
    return this.http.post<Result<any>>(musys.api + `/dealer/governance`, request);
  }

  deleteManager(request):Observable<Result<any>> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: request,
    };
    return this.http.delete<Result<any>>(musys.api + `/dealer/governance`, options);
  }
}
