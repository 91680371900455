import { Pipe, PipeTransform } from '@angular/core';
import { DealerUsersResponse } from '../models/dealerusersresponse.model';

@Pipe({
  name: 'filterDealers'
})
export class FilterDealersPipe implements PipeTransform {

  transform(dealers: DealerUsersResponse[], filter: string): any[] {
    if (!dealers || !filter) {
      return dealers;
    }

    return dealers.filter(d => d.Code.toString().includes(filter) || d.Name.toUpperCase().includes(filter.toUpperCase()) || (d.UsersList && d.UsersList.toUpperCase().includes(filter.toUpperCase())));
  }

}
