import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Currency } from 'src/app/models/currency.model';
import { Result } from 'src/app/models/result.model';
import { musys } from 'src/environments/environment';

@Injectable({
  providedIn: "root",
})
export class CurrencyService {
  constructor(private http: HttpClient) {}

  listCurrencies(): Observable<Result<Currency[]>> {
    return this.http.get<Result<Currency[]>>(musys.api + `/currency`)
  }

}
