import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListPartnersRequest } from 'src/app/models/listpartnersrequest.model';
import { PartnerDocumentType } from 'src/app/models/partnerdocumenttype.model';
import { PartnerListResponse } from 'src/app/models/partnerlistresponse.model';
import { PersonIdentity } from 'src/app/models/personidentity.model';
import { PartnerType } from 'src/app/models/partnertype.model';
import { Result } from 'src/app/models/result.model';
import { musys } from 'src/environments/environment';
import { PartnerAddressType } from 'src/app/models/partneraddresstypes.model';
import { Partner } from 'src/app/models/partner.model';
import { FormGroup } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(private http: HttpClient) { }


  listPartnerDocumentTypes(): Observable<Result<PartnerDocumentType[]>> {
    return this.http.get<Result<PartnerDocumentType[]>>(musys.api +  `/partner/doc-types`);
  }

  ListPartnerTypes():Observable<Result<PartnerType[]>> {
    return this.http.get<Result<PartnerType[]>>(musys.api +  `/partner/types`);
  }

  listPersonIdentites():Observable<Result<PersonIdentity[]>> {
    return this.http.get<Result<PersonIdentity[]>>(musys.api +  `/partner/person-identities`);
  }
  listPartnerAddressTypes():Observable<Result<PartnerAddressType[]>> {
    return this.http.get<Result<PartnerAddressType[]>>(musys.api +  `/partner/address-types`);
  }  

  list(parameters: any): Observable<Result<PartnerListResponse[]>> {
    var params = new HttpParams({ fromObject: parameters});
    return this.http.get<Result<PartnerListResponse[]>>(musys.api +  `/partner`, {params: params});
  }

  getPartnerInformation(id: number):Observable<Result<Partner>> {
    return this.http.get<Result<Partner>>(musys.api +  `/partner/${id}`);
  }

  create(partner: FormGroup): Observable<Result<number>> {
    return this.http.post<Result<number>>(musys.api +  `/partner`, JSON.stringify(partner));
  }

  update(partner: Partner) {
    return this.http.put(musys.api +  `/partner`, JSON.stringify(partner));
  }

  remove(partnerId: number, IsCustomerBr: boolean) {
    return this.http.delete(musys.api +  `/partner/${partnerId}/${IsCustomerBr}`); 
  }
}
