import { Component, EventEmitter, Input, Output } from '@angular/core';
import jwtDecode from 'jwt-decode';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/auth/auth.service';
import { LoginGuard } from 'src/app/auth/login.guard';
import { Language } from 'src/app/models/language.model';
import { Market } from 'src/app/models/market.model';
import { Menu } from 'src/app/models/menu.model';
import { Search } from 'src/app/models/search.model';
import { HeaderService } from 'src/app/service/header.service';
import { SharedService } from 'src/app/service/shared.service';
import { musys } from 'src/environments/environment';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent {
  @Input()
  isExpanded: boolean = false;
  @Output() expand = new EventEmitter();

  timer:any;
  lastDropdown: string = '';

  adminDropdown: boolean = false;
  salesDropdown: boolean = false;
  purchaseDropdown: boolean = false;
  searchBar: boolean = false;
  marketList: boolean = false;
  showMobileMenu: boolean = false;
  avatarMenu: boolean = false;
  languageList: boolean = false;
  searchStatus: boolean = false;

  searchInput: string = '';
  inputValue: string = '';
  market: Market;
  currentLanguage: string;

  languages: Language[] = [];
  markets: Market[] = [];
  searchResult: Search[] = [];

  menuAdmin: Menu[] = [];
  menuSales: Menu[] = [];
  menuPurchase: Menu[] = [];
  musys = musys;


  constructor(
    public service: HeaderService,
    public auth: AuthService,
    public shared: SharedService,
    private login: LoginGuard,
    private cookie: CookieService
  ) { }

  ngOnInit(): void {
      this.getLanguages();
      this.getMarkets();
      this.getMenus();
      this.market = this.shared.getCurrentMarket();
      this.currentLanguage = this.shared.getCurrentLanguage();
  }
  
  toggleMenus(dropdown: string): void {
    this.lastDropdown = dropdown;
    switch (dropdown) {
      case 'Admin':
        this.adminDropdown = !this.adminDropdown;
        break;
      case 'Sales':
        this.salesDropdown = !this.salesDropdown;
        break;
      case 'Purchase':
        this.purchaseDropdown = !this.purchaseDropdown;
        break;
      case 'Languages':
        this.languageList = !this.languageList;
        break;
      case 'Markets':
        this.marketList = !this.marketList;
        break;
      case 'Avatar':
        this.avatarMenu = !this.avatarMenu;
        break;
      case 'Search':
        this.searchBar = !this.searchBar;
        this.searchResult = [];
        this.inputValue = '';
        break;
      default:
        this.adminDropdown = false;
        this.salesDropdown = false;
        this.purchaseDropdown = false;
        this.languageList = false;
        this.marketList = false;
        this.avatarMenu = false;
    }
  }

  cancelCloseMenu(event: any) {
    if (event == this.lastDropdown) {
      clearTimeout(this.timer);
    }
  }

  closeMenus(dropdown: string) {
    if (dropdown == this.lastDropdown) {
      this.timer = setTimeout(() => {
        switch (dropdown) {
          case 'Admin':
            this.adminDropdown = false;
            break;
          case 'Sales':
            this.salesDropdown = false;
            break;
          case 'Purchase':
            this.purchaseDropdown = false;
            break;
          case 'Languages':
            this.languageList = false;
            break;
          case 'Markets':
            this.marketList = false;
            break;
          case 'Avatar':
            this.avatarMenu = false;
            break;
          case 'Search':
            this.searchBar = false;
            this.searchResult = [];
            this.inputValue = '';
            break;
          default:
            this.adminDropdown = false;
            this.salesDropdown = false;
            this.purchaseDropdown = false;
            this.languageList = false;
            this.marketList = false;
            this.avatarMenu = false;
        }
      }, 300)
    }
  }

  openMobileMenu(): void {
    this.showMobileMenu = !this.showMobileMenu;
  }

  search(parameter: string) {
    if (parameter == '') {
      this.shared.warningAlert(
        'Please inform a  valid popid, engine or order',
      );
      return;
    }
    this.searchResult = [];
    this.searchStatus = true;

    this.service.search(parameter).subscribe({
      next: (result) => {
        if (!result.Success) {
          this.shared.errorAlert('Error getting search result.', result.Errors[0])
          return;
        }
        if (result.Data.SearchResult.length > 0) {
          result.Data.SearchResult.forEach((item) => {
            switch (item.VehicleType) {
              case 3:
              case 21:
              case 4:
                item.Icon = 'bus';
                break;
              case 31:
              case 81:
              case 82:
              case 83:
              case 84:
                item.Icon = 'settings';
                break;
              case 1:
              case 2:
              case 11:
                item.Icon = 'truck';
            }
          });
          this.searchResult = result.Data.SearchResult;
        } else {
          this.shared.warningAlert(
            'There are no items that match this criteria'
          );
          
        }
      },
      error: (error) => {
        this.shared.errorAlert('Error getting search result', error.message)
        this.searchStatus = false
      },
      complete: () => {
        this.searchStatus = false;
      },
    });
  }

  searchContains(parameter: string): boolean {
    return this.searchResult.some((result) => result.Type == parameter);
  }

  getLanguages() {
    let languagesListKey = 'Musys.Ui.Language.List';

    if (localStorage.getItem(languagesListKey)) {
      this.languages = JSON.parse(localStorage.getItem(languagesListKey) + '');
      return;
    }

    this.service.getLanguages().subscribe({
      next: (result) => {
        if (!result.Success) {
          this.shared.errorAlert('Error getting Languages.', result.Errors[0])
          return;
        }
        localStorage.setItem(languagesListKey, JSON.stringify(result.Data));
        this.languages = result.Data;
      },
      error: (error) =>
        this.shared.errorAlert(
          'Error getting Languages.',
          error.message
        ),
    });
  }

  setLanguage(lcid: string) {
    this.shared.setLanguage(lcid, false);
    this.currentLanguage = this.shared.getCurrentLanguage();
    this.avatarMenu = false;
    this.languageList = false;
  }

  setMarket(
    id: number
  ) {
  
    var markets: Market[] = JSON.parse(
      localStorage.getItem('Musys.Ui.Markets.List') || ''
    );
    markets.forEach((value) => {
      if (value.Id == id) {
        this.market = value;

        var user = localStorage.getItem('Musys.Ui.Markets.User') != null ? localStorage.getItem('Musys.Ui.Markets.User') : '';
        var const_ck_permissions = user?.toUpperCase() + '_' + this.cookie.get('Musys.Ui.User.MarketUnitId.Current') + '_ACCESS';
        var cookieName = Buffer.from(const_ck_permissions, 'utf8').toString('base64');
        this.cookie.delete(cookieName, '/');

        this.cookie.delete('Musys.Ui.User.UserToken.Current', '/');
        this.cookie.delete('Musys.Ui.User.MarketUnitId.Current', '/');
        this.cookie.delete('Musys.Ui.User.MarketUnitCode.Current', '/');
        this.cookie.delete('Musys.Ui.User.MarketUnitDescription.Current', '/');
        this.cookie.delete('Musys.Ui.User.MarketUnitSystem.Current', '/');
        
        this.login.canActivate(id);
      }
    });
    this.marketList = false
  }

  getMarkets(): void {
    this.service.getMarkets().subscribe({
      next: (result) => {
        if (!result.Success) {
          this.shared.errorAlert('Error getting Markets.', result.Errors[0])
          return;
        }
        this.markets = result.Data;
        localStorage.setItem('Musys.Ui.Markets.List', JSON.stringify(result.Data));
      },
      error: (error) =>
        this.shared.errorAlert('Error getting Markets.', error.message),
    });
  }

  getMenus() {
    this.service.getMenus('W200').subscribe({
      next: (result) => {
        if (!result.Success) {
          this.shared.errorAlert('Error getting Admin menus.', result.Errors[0])
          return;
        }
        result.Data.forEach((element) => {
          if (this.shared.hasAuthorization(element.Code))
            this.menuAdmin.push(element);
        });
        this.menuAdmin.sort((a, b) => a.Name.localeCompare(b.Name));
      },
      error: (error) =>
        this.shared.errorAlert(
          'Error getting Admin menus.',
          error.message
        ),
    });

    this.service.getMenus('W300').subscribe({
      next: (result) => {
        if (!result.Success) {
          this.shared.errorAlert('Error getting Sales menus.', result.Errors[0])
          return;
        }
        result.Data.forEach((element) => {
          if (this.shared.hasAuthorization(element.Code))
            this.menuSales.push(element);
        });
        this.menuSales.sort((a, b) => a.Name.localeCompare(b.Name));
      },
      error: (error) =>
        this.shared.errorAlert(
          'Error getting Sales menus.',
          error.message
        ),
    });

    this.service.getMenus('W400').subscribe({
      next: (result) => {
        if (!result.Success) {
          this.shared.errorAlert('Error getting Purchase menus.', result.Errors[0])
          return;
        }
        result.Data.forEach((element) => {
          if (this.shared.hasAuthorization(element.Code))
            this.menuPurchase.push(element);
        });
        this.menuPurchase.sort((a, b) => a.Name.localeCompare(b.Name));
      },
      error: (error) =>
        this.shared.errorAlert(
          'Error getting Purchase menus.',
          error.message
        ),
    });

  }
}

