import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { share } from 'rxjs';
import { City } from 'src/app/models/city.model';
import { Country } from 'src/app/models/country.model';
import { State } from 'src/app/models/state.model';
import { GeoService } from 'src/app/service/api/geo.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-region-information',
  templateUrl: './region-information.component.html',
  styleUrls: ['./region-information.component.css']
})
export class RegionInformationComponent {

  requestType = '';

  countryList: Country[];
  stateList: State[];
  cityList: City[];

  selectedCountry: Country;
  selectedState: State;
  selectedCity: City;

  countryEdit: boolean = false;
  stateEdit: boolean = false;
  cityEdit: boolean = false;

  countryCode: string;
  countryName: string;

  countryForm: FormGroup;
  get country() {
    return this.countryForm.controls;
  }
  stateForm: FormGroup;
  get state() {
    return this.stateForm.controls;
  }
  cityForm: FormGroup;
  get city() {
    return this.cityForm.controls;
  }

  constructor(public shared: SharedService, private geo: GeoService, private fb: FormBuilder) {

  }

  ngOnInit() {
    this.getCountries();
    this.createCountryForm();
    this.createStateForm();
    this.createCityForm();
    // this.selectedCountry = {
    //   Code: 'BR',
    //   Name: ''
    // }

    // this.getStates()
  }

  openModal(modal: HTMLTdsModalElement) {
    switch (modal.id) {
      case 'countryModal':
        this.createCountryForm();
        this.countryEdit = false;
        break;
      case 'stateModal':
        this.createStateForm();
        this.stateEdit = false;
        this.cityList = null
        break;
      case 'cityModal':
        this.createCityForm();
        this.cityEdit = false;
        break;
    }
    this.requestType = 'POST';
    modal.showModal();
  }

  createCountryForm() {
    this.countryForm = this.fb.group({
      CountryCode: ['', Validators.required],
      CountryName: ['', Validators.required]
    });
  }
  createStateForm() {
    this.stateForm = this.fb.group({
      StateCode: ['', Validators.required],
      StateName: ['', Validators.required]
    });
  }
  createCityForm() {
    this.cityForm = this.fb.group({
      CityCode: ['', Validators.required],
      CityName: ['', Validators.required]
    });
  }

  setHeaderNames(region: string) {
    document.getElementById(`${region}Code`).setAttribute('column-title', this.shared.translate('Code'));
    document.getElementById(`${region}Name`).setAttribute('column-title', this.shared.translate('Name'));
  }

  setStateTableValues(index: number) {
    document.getElementById(`rowStateCode${index}`).setAttribute('cell-value', this.stateList[index].Code);
    document.getElementById(`rowStateName${index}`).setAttribute('cell-value', this.stateList[index].Name);
    document.getElementById(`rowStateEdit${index}`).setAttribute('cell-value', '');
    document.getElementById(`rowStateDelete${index}`).setAttribute('cell-value', '');
  }

  setCityTableValues(index: number) {
    document.getElementById(`rowCityCode${index}`).setAttribute('cell-value', this.cityList[index].CityCode);
    document.getElementById(`rowCityName${index}`).setAttribute('cell-value', this.cityList[index].Name);
    document.getElementById(`rowCityEdit${index}`).setAttribute('cell-value', '');
    document.getElementById(`rowCityDelete${index}`).setAttribute('cell-value', '');
  }

  getCountries() {
    this.geo.listCountries().subscribe({
      next: (result) => {
        this.countryList = result.Data;
      }
    })
  }

  getStates() {
    this.geo.listStates(this.selectedCountry.Code).subscribe({
      next: (result) => {
        this.stateList = result.Data;

        this.country['CountryCode'].patchValue(this.selectedCountry.Code);
        this.country['CountryName'].patchValue(this.selectedCountry.Name);
      }
    })
  }

  getCities() {
    this.geo.listCities(this.selectedState.CountryCode, this.selectedState.Code).subscribe({
      next: (result) => {
        this.cityList = result.Data;
      }
    })
  }

  editState(state: State) {
    this.selectedState = state;
    this.state['StateCode'].patchValue(state.Code);
    this.state['StateName'].patchValue(state.Name);
    document.getElementById('stateModal')['showModal']();

    this.getCities();
    this.stateEdit = true;
  }

  editCity(city: City) {
    this.selectedCity = city;
    this.city['CityCode'].patchValue(city.CityCode);
    this.city['CityName'].patchValue(city.Name);
    document.getElementById('cityModal')['showModal']();
    this.cityEdit = true;
  }

  saveCountry(modal?) {
    if (!this.countryForm.valid) {
      this.countryForm.markAllAsTouched()
      this.shared.errorAlert(this.shared.translate("Please, fill all required fields!"))
      return
    }

    var request;

    if (!this.countryEdit) {
      request = {
        countryCode: this.country['CountryCode'].value,
        countryName: this.country['CountryName'].value,
      }
      this.createCountry(request, modal);
    } else {
      request = {
        oldCountryCode: this.selectedCountry.Code,
        oldCountryName: this.selectedCountry.Name,
        newCountryCode: this.country['CountryCode'].value,
        newCountryName: this.country['CountryName'].value,
      }
      this.updateCountry(request);
    }
  }

  saveState(modal) {
    if (!this.stateForm.valid) {
      this.stateForm.markAllAsTouched()
      this.shared.errorAlert(this.shared.translate("Please, fill all required fields!"))
      return
    }

    var request;

    if (!this.stateEdit) {
      request = {
        countryCode: this.selectedCountry.Code,
        stateCode: this.state['StateCode'].value,
        stateName: this.state['StateName'].value,
      }
      this.createState(request, modal);
    } else {
      request = {
        oldCountryCode: this.selectedState.CountryCode,
        oldStateCode: this.selectedState.Code,
        oldStateName: this.selectedState.Name,
        newCountryCode: this.selectedState.CountryCode,
        newStateCode: this.state['StateCode'].value,
        newStateName: this.state['StateName'].value,
      }
      this.updateState(request, modal);
    }
  }

  saveCity(modal) {
    if (!this.cityForm.valid) {
      this.cityForm.markAllAsTouched()
      this.shared.errorAlert(this.shared.translate("Please, fill all required fields!"))
      return
    }

    var request;

    if (!this.cityEdit) {
      request = {
        countryCode: this.selectedCountry.Code,
        stateCode: this.selectedState.Code,
        cityCode: this.city['CityCode'].value,
        cityName: this.city['CityName'].value,
      }
      this.createCity(request, modal);
    } else {
      request = {
        oldCountryCode: this.selectedCity.CountryCode,
        oldStateCode: this.selectedCity.StateCode,
        oldCityCode: this.selectedCity.CityCode,
        oldCityName: this.selectedCity.Name,
        newCountryCode: this.selectedCity.CountryCode,
        newStateCode: this.selectedCity.StateCode,
        newCityCode: this.city['CityCode'].value,
        newCityName: this.city['CityName'].value,
      }
      this.updateCity(request, modal);
    }
  }

  createCountry(request, modal) {
    this.geo.createCountry(request).subscribe({
      next: (result) => {
        if (result.Data == true) {
          this.countryList.push({
            Code: this.country['CountryCode'].value,
            Name: this.country['CountryName'].value.toUpperCase(),
          })
          this.countryList.sort((a, b) => a.Name.localeCompare(b.Name));
          this.shared.successAlert(this.shared.translate(`Country created successfully!`));
          modal.closeModal();
        }
      },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate(`Error while creating Country!`), '', 'sm');
      }
    })
  }

  createState(request, modal) {
    this.geo.createState(request).subscribe({
      next: (result) => {
        if (result.Data == true) {
          this.stateList.push({
            CountryCode: request.countryCode,
            Code: this.state['StateCode'].value,
            Name: this.state['StateName'].value.toUpperCase(),
          })
          this.stateList.sort((a, b) => a.Name.localeCompare(b.Name));
          this.shared.successAlert(this.shared.translate(`State created successfully!`));
          modal.closeModal();
        }
      },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate(`Error while creating State!`), '', 'sm');
      }
    })
  }

  createCity(request, modal) {
    this.geo.createCity(request).subscribe({
      next: (result) => {
        if (result.Data == true) {
          this.cityList.push({
            CountryCode: request.countryCode,
            StateCode: request.stateCode,
            CityCode: this.city['CityCode'].value,
            Code: this.city['CityCode'].value,
            Name: this.city['CityName'].value.toUpperCase(),
          })
          this.cityList.sort((a, b) => a.Name.localeCompare(b.Name));
          this.shared.successAlert(this.shared.translate(`City created successfully!`));
          modal.closeModal();
        }
      },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate(`Error while creating City!`), '', 'sm');
      }
    })
  }

  updateCountry(request) {
    this.geo.updateCountry(request).subscribe({
      next: (result) => {
        if (result.Data == true) {

          this.countryList[this.countryList.indexOf(this.selectedCountry)].Name = request.newCountryName.toUpperCase();

          this.shared.successAlert(this.shared.translate(`Country updated successfully!`));

        } else {
          this.shared.errorAlert(this.shared.translate(`Error while updating Country!`), '', 'sm');
        }
      },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate(`Error while updating Country!`), '', 'sm');
      }
    })
  }

  updateState(request, modal) {
    this.geo.updateState(request).subscribe({
      next: (result) => {
        if (result.Data == true) {

          this.stateList[this.stateList.indexOf(this.selectedState)].Name = request.newStateName.toUpperCase();

          this.shared.successAlert(this.shared.translate(`State updated successfully!`));
          modal.closeModal();
        } else {
          this.shared.errorAlert(this.shared.translate(`Error while updating State!`), '', 'sm');
        }
      },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate(`Error while updating State!`), '', 'sm');
      }
    })
  }

  updateCity(request, modal) {
    this.geo.updateCity(request).subscribe({
      next: (result) => {
        if (result.Data == true) {

          this.cityList[this.cityList.indexOf(this.selectedCity)].Name = request.newCityName.toUpperCase();

          this.shared.successAlert(this.shared.translate(`City updated successfully!`));
          modal.closeModal();
        } else {
          this.shared.errorAlert(this.shared.translate(`Error while updating Country!`), '', 'sm');
        }
      },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate(`Error while updating City!`), '', 'sm');
      }
    })
  }

  removeCountry(country: Country, dropdown: HTMLTdsDropdownElement) {
    this.shared.warningOptionAlert(this.shared.translate('Are you sure you want to remove Country?')).then((option) => {
      if (option.isConfirmed) {
        this.geo.deleteCountry(country.Name).subscribe({
          next: (result) => {
            if (result.Data == true) {
              var removedCountryIndex = this.countryList.findIndex(c => c.Name == country.Name);
              this.countryList.splice(removedCountryIndex , 1);
              this.stateList = null;
              this.shared.successAlert('Country removed successfuly!');
              dropdown.reset();
            }else {
              this.shared.errorAlert('Error while removing Country!', '', 'sm');
            }

          },
          error: (error) => {
            this.shared.errorAlert('Error while removing Country!', '', 'sm');
          }
        });
      }
    })
  }

  removeState(state: State) {
    var request = {
      countryCode: state.CountryCode,
      stateName: state.Name
    }
    this.shared.warningOptionAlert(this.shared.translate('Are you sure you want to remove State?')).then((option) => {
      if (option.isConfirmed) {
        this.geo.deleteState(request).subscribe({
          next: (result) => {
            if (result.Data == true) {
              var removedStateIndex = this.stateList.findIndex(c => c.Name == state.Name);
              this.stateList.splice(removedStateIndex , 1);
              this.shared.successAlert('State removed successfuly!');
            }else {
              this.shared.errorAlert('Error while removing state!', '', 'sm');
            }

          },
          error: (error) => {
            this.shared.errorAlert('Error while removing state!', '', 'sm');
          }
        });
      }
    })
  }

  removeCity(city: City) {
    var request = {
      countryCode: city.CountryCode,
      stateCode: city.StateCode,
      cityname: city.Name
    }
    this.shared.warningOptionAlert(this.shared.translate('Are you sure you want to remove City?')).then((option) => {
      if (option.isConfirmed) {
        this.geo.deleteCity(request).subscribe({
          next: (result) => {
            if (result.Data == true) {
              var removedCityIndex = this.cityList.findIndex(c => c.Name == city.Name);
              this.cityList.splice(removedCityIndex , 1);
              this.shared.successAlert('City removed successfuly!');
            }else {
              this.shared.errorAlert('Error while removing city!', '', 'sm');
            }

          },
          error: (error) => {
            this.shared.errorAlert('Error while removing city!', '', 'sm');
          }
        });
      }
    })
  }

}
