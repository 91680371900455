<div class="tds-container">
    <tds-block>

        <tds-block>
            <div class="tds-row">
                <div class="tds-col  tds-col-xs-12 tds-u-flex">
                    <tds-icon class="tds-u-mr2 tds-u-pt2" name="global" size="40px"></tds-icon>
                    <h3>Country, States & Cities</h3>
                </div>

                <div class="tds-col-md-2 justify-end tds-col-xs-12 tds-u-flex">
                    <tds-button class="tds-u-mr2" id="clearCountry" (click)="stateList=null" type="button"
                        variant="secondary" size="sm" text="{{shared.translate('Clear')}}"></tds-button>
                    <tds-button class="tds-u-mr1" (click)="openModal(countryModal)" id="newCountry" type="button"
                        variant="primary" size="sm" text="{{shared.translate('New')}}"></tds-button>
                </div>
            </div>

            <div class="tds-row">
                <div class="tds-col-md-10 tds-col-xs-12">
                    <tds-dropdown style="z-index: 201;" #countries id="countries" #countryDropdown
                        label="{{shared.translate('Country')}}" label-position="outside"
                        placeholder="{{shared.translate('Select')}}" size="sm" open-direction="down">
                        <tds-dropdown-option *ngFor="let country of countryList" (tdsSelect)="selectedCountry = country"
                            value="{{country.Code}}">{{country.Name}}</tds-dropdown-option>
                    </tds-dropdown>
                </div>

                <div class="tds-col-md-2 tds-col-xs-12 tds-u-flex tds-u-alignitems-end justify-end tds-u-mt3">
                    <tds-button id="search" (click)="getStates()" type="button" variant="primary" size="sm"
                        text="{{shared.translate('Search')}}"></tds-button>
                </div>
            </div>
        </tds-block>

        <tds-block *ngIf="stateList">
            <tds-block>
                <div class="tds-row">
                    <div class="tds-col-md-10 tds-col-xs-12 tds-u-flex">
                        <!-- <tds-icon class="tds-u-mr2 tds-u-pt2" name="global" size="40px"></tds-icon> -->
                        <h3>COUNTRY INFORMATION - {{selectedCountry.Name}}</h3>
                    </div>
                    <div class="tds-col-md-2 tds-col-xs-12 tds-u-flex- justify-end">
                        
                        <img (click)="countryEdit = !countryEdit" class="tds-u-mr2"
                        style="width: 40px; margin-left: 10px; cursor: pointer"
                        src="../../../../../assets/images/edit.svg" id="EditCountry{{selectedCountry.Code}}">
                        
                        <tds-tooltip placement="bottom" selector="#EditCountry{{selectedCountry.Code}}"
                        text="{{shared.translate('Edit')}}" mouse-over-tooltip="true"> </tds-tooltip>
                        
                        <img (click)="removeCountry(selectedCountry, countryDropdown)" class="tds-u-mr2"
                            style="width: 40px; margin-left: 10px; cursor: pointer"
                            src="../../../../../assets/images/trash.svg" id="DeleteCountry{{selectedCountry.Code}}">

                        <tds-tooltip placement="bottom" selector="#DeleteCountry{{selectedCountry.Code}}"
                            text="{{shared.translate('Delete')}}" mouse-over-tooltip="true"> </tds-tooltip>
                    </div>
                </div>

                <form [formGroup]="countryForm">
                    <div class="tds-row tds-u-m2">
                        <div class="tds-col-md-5 tds-col-xs-12">
                            <tds-text-field read-only size="sm" ngDefaultControl formControlName="CountryCode"
                                placeholder="{{shared.translate('Country Code')}}"
                                label="{{shared.translate('Country Code')}}" label-position="outside"
                                value="{{countryCode}}">
                            </tds-text-field>
                        </div>
                        <div class="tds-col-md-5 tds-col-xs-12">
                            <tds-text-field readonly="{{!countryEdit}}" size="sm" ngDefaultControl
                                formControlName="CountryName" placeholder="{{shared.translate('Country Name')}}"
                                label="{{shared.translate('Country Name')}}" label-position="outside"
                                value="{{countryName}}">
                            </tds-text-field>
                        </div>
                        <div class="tds-col-md-2 tds-col-xs-12 justify-end tds-u-mt3">
                            <tds-button [disabled]="!countryEdit" id="editCountry" type="button" variant="secondary"
                                size="sm" (click)="saveCountry()" text="{{shared.translate('Save')}}"></tds-button>
                        </div>
                    </div>
                </form>
                <div class="tds-row tds-u-m2">
                    <div class="tds-col-md-10 tds-col-xs-12">
                        <h3>{{shared.translate('States')}}</h3>
                    </div>
                    <div class="tds-col-md-2  tds-col-xs-12 justify-end">
                        <tds-button id="newCity" (click)="openModal(stateModal)" type="button" variant="primary"
                            size="sm" text="{{shared.translate('New')}}"></tds-button>
                    </div>
                </div>
                <tds-table table-id="pagination-table" no-min-width vertical-dividers="false" compact-design="true"
                    responsive="true">
                    <tds-table-header>
                        <tds-header-cell id="stateCode" column-title="{{setHeaderNames('state')}}"></tds-header-cell>
                        <tds-header-cell id="stateName" column-title=""></tds-header-cell>
                        <tds-header-cell id="stateEdit" column-title=""></tds-header-cell>
                        <tds-header-cell id="stateDelete" column-title=""></tds-header-cell>
                    </tds-table-header>
                    <tds-table-body-row *ngFor="let state of stateList; let i = index">
                        <tds-body-cell id="rowStateCode{{i}}"></tds-body-cell>
                        <tds-body-cell id="rowStateName{{i}}"></tds-body-cell>
                        <tds-body-cell id="rowStateEdit{{i}}">
                            <img (click)="editState(state)" class="editStateButton stateModalButton"
                                style="width: 22px; margin-left: 10px; cursor: pointer"
                                src="../../../../../assets/images/edit.svg" id="EditState{{state.Code}}">

                            <tds-tooltip placement="bottom" selector="#EditState{{state.Code}}"
                                text="{{shared.translate('Edit')}}" mouse-over-tooltip="true"> </tds-tooltip>
                        </tds-body-cell>
                        <tds-body-cell id="rowStateDelete{{i}}" cell-value="{{setStateTableValues(i)}}">
                            <img (click)="removeState(state)" class="deleteStateButton"
                                style="width: 22px; margin-left: 10px; cursor: pointer"
                                src="../../../../../assets/images/trash.svg" id="DeleteState{{state.Code}}">

                            <tds-tooltip placement="bottom" selector="#DeleteState{{state.Code}}"
                                text="{{shared.translate('Delete')}}" mouse-over-tooltip="true"> </tds-tooltip>
                        </tds-body-cell>
                    </tds-table-body-row>
                    <tds-table-footer></tds-table-footer>
                </tds-table>
            </tds-block>
        </tds-block>
    </tds-block>
</div>

<!-- Country -->
<tds-modal selector=".countryModalButton" id="countryModal" size="sm" #countryModal actions-position="sticky"
    prevent="true">
    <span slot="body">
        <form [formGroup]="countryForm">
            <div class="tds-row">
                <div class="tds-col tds-u-flex">
                    <tds-icon class="tds-u-mr2 tds-u-pt2" name="global" size="40px"></tds-icon>
                    <h3>{{shared.translate('Country')}}</h3>
                </div>
            </div>
            <div class="tds-row">
                <div class="tds-col">
                    <tds-text-field ngDefaultControl formControlName="CountryCode" id="code" size="sm"
                        placeholder="{{shared.translate('Country Code')}}" max-length="2"
                        (focusout)="country['CountryCode'].markAsTouched()"
                        state="{{country['CountryCode'].touched && country['CountryCode'].errors ? 'error' : ''}}"
                        label="{{shared.translate('Country Code')}}" label-position="outside"
                        helper="{{country['CountryCode'].touched && country['CountryCode'].errors ? shared.translate('Required Field.') : ''}}">
                    </tds-text-field>
                </div>
                <div class="tds-col">
                    <tds-text-field ngDefaultControl formControlName="CountryName" id="name" size="sm"
                        placeholder="{{shared.translate('Country Name')}}"
                        (focusout)="country['CountryName'].markAsTouched()"
                        state="{{country['CountryName'].touched && country['CountryName'].errors ? 'error' : ''}}"
                        label="{{shared.translate('Country Name')}}" label-position="outside"
                        helper="{{country['CountryName'].touched && country['CountryName'].errors ? shared.translate('Required Field.') : ''}}">
                    </tds-text-field>
                </div>
            </div>
        </form>
    </span>
    <span slot="actions">
        <tds-button size="sm" text="{{shared.translate('Save')}}" (click)="saveCountry(countryModal)"></tds-button>
        <tds-button class="tds-u-ml1" size="sm" text="{{shared.translate('Cancel')}}" type="danger" data-dismiss-modal
            variant="danger"></tds-button>
    </span>
</tds-modal>

<!-- State -->
<tds-modal selector=".stateModalButton" id="stateModal" size="md" #stateModal actions-position="sticky" prevent="true">
    <span slot="body">
        <div class="tds-row">
            <div class="tds-col tds-u-flex">
                <tds-icon class="tds-u-mr2 tds-u-pt2" name="global" size="40px"></tds-icon>
                <h3>State</h3>
            </div>
        </div>
        <form [formGroup]="stateForm">
            <div class="tds-row tds-u-m2">
                <div class="tds-col-md tds-col-xs-12">
                    <tds-text-field ngDefaultControl formControlName="StateCode" id="code" size="sm" max-length="10"
                        placeholder="{{shared.translate('State Code')}}" readonly="{{this.stateEdit}}"
                        (focusout)="state['StateCode'].markAsTouched()"
                        state="{{state['StateCode'].touched && state['StateCode'].errors ? 'error' : ''}}"
                        label="{{shared.translate('State Code')}}" label-position="outside"
                        helper="{{state['StateCode'].touched && state['StateCode'].errors ? shared.translate('Required Field.') : ''}}">
                    </tds-text-field>
                </div>
                <div class="tds-col-md tds-col-xs-12">
                    <tds-text-field ngDefaultControl formControlName="StateName" id="name" size="sm"
                        placeholder="{{shared.translate('State Name')}}" (focusout)="state['StateName'].markAsTouched()"
                        state="{{state['StateName'].touched && state['StateName'].errors ? 'error' : ''}}"
                        label="{{shared.translate('State Name')}}" label-position="outside"
                        helper="{{state['StateName'].touched && state['StateName'].errors ? shared.translate('Required Field.') : ''}}">
                    </tds-text-field>
                </div>
                <div *ngIf="stateEdit" class="tds-col-md-3 tds-col-xs-12 justify-end">
                    <tds-button (click)="saveState(stateModal)" [disabled]="!stateEdit" id="saveState" type="button"
                        variant="primary" size="sm" text="{{shared.translate('Save')}}"></tds-button>
                </div>
            </div>
        </form>
        <div class="tds-row tds-u-m2" *ngIf="cityList">
            <div class="tds-col-md-10 tds-col-xs-12">
                <h3>{{shared.translate('Cities')}}</h3>
            </div>
            <div class="tds-col-md-2  tds-col-xs-12 justify-end">
                <tds-button id="newCity" (click)="openModal(cityModal)" type="button" variant="primary" size="sm"
                    text="{{shared.translate('New')}}"></tds-button>
            </div>
        </div>
        <div class="tds-row">
            <tds-table *ngIf="cityList" table-id="pagination-table" no-min-width vertical-dividers="false"
                compact-design="true" responsive="true">
                <tds-table-header>
                    <tds-header-cell id="cityCode" column-title="{{setHeaderNames('city')}}"></tds-header-cell>
                    <tds-header-cell id="cityName" column-title=""></tds-header-cell>
                    <tds-header-cell id="cityEdit" column-title=""></tds-header-cell>
                    <tds-header-cell id="cityDelete" column-title=""></tds-header-cell>
                </tds-table-header>
                <tds-table-body-row *ngFor="let city of cityList.slice(0, 25); let i = index">
                    <tds-body-cell id="rowCityCode{{i}}"></tds-body-cell>
                    <tds-body-cell id="rowCityName{{i}}"></tds-body-cell>
                    <tds-body-cell id="rowCityEdit{{i}}">
                        <img (click)="editCity(city)" class="editCityButton stateModalButton"
                            style="width: 22px; margin-left: 10px; cursor: pointer"
                            src="../../../../../assets/images/edit.svg" id="EditCity{{city.CityCode}}">

                        <tds-tooltip placement="bottom" selector="#EditCity{{city.CityCode}}"
                            text="{{shared.translate('Edit')}}" mouse-over-tooltip="true"> </tds-tooltip>
                    </tds-body-cell>
                    <tds-body-cell id="rowCityDelete{{i}}" cell-value="{{setCityTableValues(i)}}">
                        <img (click)="removeCity(city)" class="deleteCityButton"
                            style="width: 22px; margin-left: 10px; cursor: pointer"
                            src="../../../../../assets/images/trash.svg" id="DeleteCity{{city.CityCode}}">

                        <tds-tooltip placement="bottom" selector="#DeleteCity{{city.CityCode}}"
                            text="{{shared.translate('Delete')}}" mouse-over-tooltip="true"> </tds-tooltip>
                    </tds-body-cell>
                </tds-table-body-row>
                <tds-table-footer></tds-table-footer>
            </tds-table>
        </div>
    </span>
    <span slot="actions" *ngIf="!stateEdit">
        <tds-button size="sm" text="{{shared.translate('Save')}}" (click)="saveState(stateModal)"></tds-button>
        <tds-button class="tds-u-ml1" size="sm" text="{{shared.translate('Cancel')}}" type="danger" data-dismiss-modal
            variant="danger"></tds-button>
    </span>
</tds-modal>

<!-- City -->
<tds-modal selector=".cityModalButton" id="cityModal" size="sm" #cityModal actions-position="sticky" prevent="true">
    <span slot="body">
        <form [formGroup]="cityForm">
            <div class="tds-row">
                <div class="tds-col tds-u-flex">
                    <img class="tds-u-mr2" style="width: 50px;" src="../../../../../assets/images/city.svg">
                    <h3>{{shared.translate('City')}}</h3>
                </div>
            </div>
            <div class="tds-row">
                <div class="tds-col-md-5  tds-col-xs-12">
                    <tds-text-field no-min-width ngDefaultControl formControlName="CityCode" id="code" size="sm" max-length="5"
                        placeholder="{{shared.translate('City Code')}}" readonly="{{cityEdit}}"
                        (focusout)="city['CityCode'].markAsTouched()"
                        state="{{city['CityCode'].touched && city['CityCode'].errors ? 'error' : ''}}"
                        label="{{shared.translate('City Code')}}" label-position="outside"
                        helper="{{city['CityCode'].touched && city['CityCode'].errors ? shared.translate('Required Field.') : ''}}">
                    </tds-text-field>
                </div>
                <div class="tds-col-md-5 tds-col-xs-12">
                    <tds-text-field no-min-width ngDefaultControl formControlName="CityName" id="name" size="sm"
                        placeholder="{{shared.translate('City Name')}}" (focusout)="city['CityName'].markAsTouched()"
                        state="{{city['CityName'].touched && city['CityName'].errors ? 'error' : ''}}"
                        label="{{shared.translate('City Name')}}" label-position="outside"
                        helper="{{city['CityName'].touched && city['CityName'].errors ? shared.translate('Required Field.') : ''}}">
                    </tds-text-field>
                </div>
                <div class="tds-col-md-2  tds-col-xs-12 align-center">
                    <tds-button class="align-end" size="sm" text="{{shared.translate('Save')}}"
                        (click)="saveCity(cityModal)"></tds-button>
                </div>
            </div>
        </form>
    </span>
    <!-- <span slot="actions">
        <tds-button size="sm" text="{{shared.translate('Save')}}" (click)="saveRegion(cityForm)"></tds-button>
        <tds-button  class="tds-u-ml1" size="sm" text="{{shared.translate('Cancel')}}" type="danger"
            data-dismiss-modal variant="danger"></tds-button>
    </span> -->
</tds-modal>