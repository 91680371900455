<div class="tds-container">
    <tds-table table-id="pagination-table" no-min-width vertical-dividers="false" compact-design="true" responsive="true">
        <tds-table-header>
            <tds-header-cell id="id" column-title="Id" column-key="Id"></tds-header-cell>
            <tds-header-cell id="code" column-title="Code"></tds-header-cell>
            <tds-header-cell id="name" column-title="Name"></tds-header-cell>
            <tds-header-cell id="" column-key=""></tds-header-cell>
        </tds-table-header>
        <tds-table-body-row *ngFor="let market of markets; let i = index">
            <tds-body-cell id="rowId{{i}}"  cell-key="Id"></tds-body-cell>
            <tds-body-cell id="rowCode{{i}}" cell-key="Code"></tds-body-cell>
            <tds-body-cell id="rowName{{i}}" cell-key="Name"></tds-body-cell>
            <tds-body-cell id="rowSwitch{{i}}" cell-value="{{setTableValues(i)}}" (click)="setMarket(market.Id)"></tds-body-cell>
        </tds-table-body-row>
        <tds-table-footer pages="4" pagination></tds-table-footer>
    </tds-table>
</div>