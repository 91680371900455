import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-basic-data',
  templateUrl: './basic-data.component.html',
  styleUrls: ['./basic-data.component.css']
})
export class BasicDataComponent {
  constructor(private titleService:Title){
    this.titleService.setTitle("MUSYS - Basic Data");
  }
}
