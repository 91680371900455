import { Component } from '@angular/core';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { DealerGovernanceResponse, DealerGroupGovernanceResponse } from 'src/app/models/dealergroupgovernanceresponse.';
import { DealerGovernanceService } from 'src/app/service/api/dealer-governance.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-dealer-governance',
  templateUrl: './dealer-governance.component.html',
  styleUrls: ['./dealer-governance.component.css']
})
export class DealerGovernanceComponent {

  dealerGovernanceList: DealerGroupGovernanceResponse[];
  commercialCategories;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;

  constructor(public shared: SharedService, private dealer: DealerGovernanceService) {}

  ngOnInit() {
    this.getList();
    //this.getCommercialCategories();
  }

  setTableValues(index, branch: DealerGovernanceResponse) {
    document.getElementById(`rowCode${index}`).setAttribute('cell-value', branch.Codigo.toString());
    document.getElementById(`rowPartnerName${index}`).setAttribute('cell-value', branch.PartnerName);
    // document.getElementById(`rowPartnerName${index}`).setAttribute('cell-value', this.dealerGovernanceList[index].Branches[index2].PartnerName);
  }
  
  setHeaderNames(index, value) {
    document.getElementById(`categ${index}`).setAttribute('column-title', value);
    //document.getElementById(`categ${index}`).setAttribute('column-title', this.commercialCategories[index].RvMeaning);
  }
  
  getList() {
    this.dealer.getList().subscribe({
      next: (result) => {
        this.dealerGovernanceList = result.Data
        this.dealerGovernanceList.forEach(dg => {
          dg.Branches.forEach(b => {
            b.BusinessManagerList.forEach(bm => {
              bm.Usernames.forEach(u => {
                u.Usernames = u.Username?.split(',')
              })
            })
          })
        })
        },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate('Error While getting dealers!'));
      }
    })
  }

  getCommercialCategories() {
    this.dealer.getCommercialCategories().subscribe({
      next: (result) => {
        this.commercialCategories = result.Data
        },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate('Error While getting commercial categories!'));
      }
    })
  }

  addManager(branch, codCategComl, event) {
    var request = {
      PartnerId: branch.PartnerId,
      CodCategComl: codCategComl,
      Username: event.value.toUpperCase()
    }

    this.dealer.addManager(request).subscribe({
      next: (result) => {
        if (!result.Success) {
          this.shared.useToast('Error!', 'Error while adding manager!', 'error');
        }else {
          
          // Verificação para criar array em situações onde a categoria ainda não tinha nenhum manager, fazendo o array não ser inicializado.
          var usernames = this.dealerGovernanceList.find(d => d.Headquarter == branch.CliCodigo).Branches.find(b => b.PartnerId  == branch.PartnerId).BusinessManagerList.find(bm => bm.CodCategComl == codCategComl).Usernames[0].Usernames;
          if (!usernames) {
            this.dealerGovernanceList.find(d => d.Headquarter == branch.CliCodigo).Branches.find(b => b.PartnerId  == branch.PartnerId).BusinessManagerList.find(bm => bm.CodCategComl == codCategComl).Usernames[0].Usernames = [];
          }
          
          this.dealerGovernanceList.find(d => d.Headquarter == branch.CliCodigo).Branches.find(b => b.PartnerId  == branch.PartnerId).BusinessManagerList.find(bm => bm.CodCategComl == codCategComl).Usernames[0].Usernames.push(event.value.toUpperCase());
          
          event.chipInput!.clear();
          this.shared.useToast('Success!', 'Manager added sucessfully!', 'success');
        }
      }
    })
    
  }

  deleteManager(branch, codCategComl, username) {
    var request = {
      PartnerId: branch.PartnerId,
      CodCategComl: codCategComl,
      Username: username
    }
    
    this.dealer.deleteManager(request).subscribe({
      next: (result) => {
        if (!result.Success) {
          this.shared.useToast('Error!', 'Error while removing manager!\n' + result.Errors[0], 'error');
        }else {
                  this.dealerGovernanceList.find(d => d.Headquarter == branch.CliCodigo).Branches.find(b => b.PartnerId  == branch.PartnerId).BusinessManagerList.find(bm => bm.CodCategComl == codCategComl).Usernames[0].Usernames
          .splice(this.dealerGovernanceList.find(d => d.Headquarter == branch.CliCodigo).Branches.find(b => b.PartnerId  == branch.PartnerId).BusinessManagerList.find(bm => bm.CodCategComl == codCategComl).Usernames[0].Usernames.findIndex(u => u == request.Username), 1);
          this.shared.useToast('Success!', 'Manager removed sucessfully!', 'success');
        }
      }
    })
  }
}
