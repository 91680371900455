import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DeliveryAddress } from 'src/app/models/deliveryaddress.model'
import { DeliveryAddressService } from 'src/app/service/delivery-address.service'
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-delivery-address',
  templateUrl: './delivery-address.component.html',
  styleUrls: ['./delivery-address.component.css']
})


export class DeliveryAddressComponent { //atributos

  partnerId: number = 0;
  country: string = '';
  code: string = '';
  name: string = '';
  isDefault: boolean = false;
  deliveryAddressList = [];
  deliveryAddressCountries = [];
  selectedCountry: string = '';
  isTableVisible: boolean = false;
  isLoading: boolean = false;
  form: FormGroup;
  requestType: string;
  modalTitle: string;

  get f() {
    return this.form.controls;
  }

  constructor( //serviços
    public service: DeliveryAddressService,
    public shared: SharedService,
    private fb: FormBuilder,

  ) { }

  ngOnInit() {
    this.createFormGroup();
    this.getDeliveryAddressAll();
  }

  setHeaderNames() {
    document.getElementById('country').setAttribute('column-title', this.shared.translate('Country'));
    document.getElementById('code').setAttribute('column-title', this.shared.translate('Code'));
    document.getElementById('name').setAttribute('column-title', this.shared.translate('Name'));
    document.getElementById('default').setAttribute('column-title', this.shared.translate('Default'));
  }

  setTableValues(index: number) {
    document.getElementById(`rowCountry${index}`).setAttribute('cell-value', this.deliveryAddressList[index].Country);
    document.getElementById(`rowCode${index}`).setAttribute('cell-value', this.deliveryAddressList[index].Code);
    document.getElementById(`rowName${index}`).setAttribute('cell-value', this.deliveryAddressList[index].Name);
    document.getElementById(`rowDefault${index}`).setAttribute('cell-value', this.deliveryAddressList[index].IsDefault);

  }

  createFormGroup() {
    this.form = this.fb.group({
      Country: ['', Validators.required],
      PartnerId: [0],
      Code: ['', Validators.required],
      Name: ['', Validators.required],
      IsDefault: ['', Validators.required],
    })
  }

  setDropdown(event: any) {
    var control = event.currentTarget.attributes.formcontrolname.value;
    this.form.get(control).patchValue(event.target.value);
  }

  onChangeSelectedCountry(event: any) {
    this.selectedCountry = event.target.value
  }

  open() {
    this.isLoading = true;
    this.isTableVisible = true;

    if (this.selectedCountry == 'all') {
      this.getDeliveryAddressAll()
    } else {
      this.getDeliveryAddressCountry(this.selectedCountry, 'EXTYD')
    }
  }

  openModal(modal: any, requestType?: string, deliveryAddress?: DeliveryAddress) {
    this.changeTitle(requestType)
    modal.showModal();

    if (requestType) {
      this.requestType = requestType

      if (requestType == 'PUT') {
        this.form.patchValue({ Country: deliveryAddress.Country || null });
        this.form.patchValue({ PartnerId: deliveryAddress.PartnerId || null });
        this.form.patchValue({ Code: deliveryAddress.Code || null });
        this.form.patchValue({ Name: deliveryAddress.Name || null });
        this.form.patchValue({ IsDefault: deliveryAddress.IsDefault || null });
        document.getElementById('isDefault')['setValue'](deliveryAddress.IsDefault ? 'Y' : 'N', deliveryAddress.IsDefault ? this.shared.translate('Yes') : this.shared.translate('No'))
      }
    }
  }

  clear() {
    document.getElementById("isDefault ")["reset"]();
  }

  setSelectedAddress(country: string, code: string) {
    this.country = country;
    this.code = code;
  }

  closeModal(modal: any) {
    modal.closeModal();
    this.form.reset();
  }

  getDeliveryAddressAll(): void {
    this.service.getDeliveryAddressAll().subscribe({
      next: (result) => {
        this.isLoading = false;
        if (!result.Success) {
          this.shared.successAlert(this.shared.translate('Success getting Delivery Address.'), result.Errors[0])
          return;
        }
        this.deliveryAddressList = result.Data;
        this.getDeliveryAddressCountriesList(this.deliveryAddressList);
      },
      error: (error) =>
        this.shared.errorAlert(this.shared.translate('Error getting Delivery Address.'), error.message),
    });
  }

  getDeliveryAddressCountry(country: string, code: string): void {
    this.service.getDeliveryAddress(country).subscribe({
      next: (result) => {
        this.isLoading = false;
        if (!result.Success) {
          this.shared.errorAlert(this.shared.translate('Error getting Delivery Address.'), result.Errors[0]);
          return
        }
        this.deliveryAddressList = result.Data;
      },
      error: (error) =>
        this.shared.errorAlert(this.shared.translate('Error getting Delivery Address.'), error.message),
    })
  }

  getDeliveryAddressCountriesList(daList) {
    var countriesList = daList.map(function (item) {
      return item.Country;
    });
    var uniqueCountriesList = countriesList.filter(function (value, index, self) {
      return self.indexOf(value) === index;
    });
    this.deliveryAddressCountries = uniqueCountriesList;
  };

  changeTitle(requestType: string) {
    if (requestType == 'PUT') {
      this.modalTitle = 'Edit Delivery Address';
    } else {
      this.modalTitle = 'Create Delivery Address';
    }
  }

  saveDeliveryAddress(country: string, code: string, modal: any): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched()
      this.shared.warningAlert(this.shared.translate('Please, fill all required fields!'))
      return
    }

    const request = {
      Country: this.form.get('Country').value.toUpperCase(),
      PartnerId: this.form.get('PartnerId').value,
      Code: this.form.get('Code').value.toUpperCase(),
      Name: this.form.get('Name').value,
      IsDefault: this.form.get('IsDefault').value
    }

    var requestName = ''

    if (this.requestType == 'POST') {
      requestName = 'postDeliveryAddress'
    } else {
      requestName = 'putDeliveryAddress'
    }

    this.service[requestName](request).subscribe({
      next: (result) => {

        if (!result.Success) {
          this.shared.errorAlert(this.shared.translate('Unable to save item. Please, try again.'), result.Errors.join("|"));
          return;
        }

        if (this.requestType == 'PUT') {
          var deliveryAddressIndex = this.deliveryAddressList.findIndex(d => d.Code == request.Code)
          this.deliveryAddressList.splice(deliveryAddressIndex, 1);
          var deliveryAddressCountriesIndex = this.deliveryAddressCountries.findIndex(d => d.Code == request.Country)
          this.deliveryAddressCountries.splice(deliveryAddressCountriesIndex, 1);
        }

        this.shared.successAlert(this.shared.translate('Item successfully saved!'));
        this.deliveryAddressList.push(request);
        this.deliveryAddressCountries.push(request.Country);
        this.closeModal(modal);

      },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate('Unable to save item. Please, try again.'), error.message)
      }
    });
  }

  getDeliveryAddress(countryCode: string): void {
    this.service.getDeliveryAddress(countryCode).subscribe({
      next: (result) => {
        if (!result.Success) {
          this.shared.successAlert(this.shared.translate('Success getting Delivery Address.'), result.Errors[0])
          return;
        }
        localStorage.setItem('Musys.Ui.DeliveryAddress.List', JSON.stringify(result.Data));
      },
      error: (error) =>
        this.shared.errorAlert(this.shared.translate('Error getting Delivery Address.'), error.message),
    });
  }

  deleteDeliveryAddress(country: string, code: string): void {
    this.shared.warningOptionAlert('Are you sure you want to remove this item?')
      .then((response) => {
        if (response.isConfirmed) {
          this.service.deleteDeliveryAddress(country, code).subscribe({
            next: (result) => {
              if (!result.Success) {
                this.shared.errorAlert(this.shared.translate('Remove failed. Please, try again.'))
                return
              }
              this.shared.successAlert(this.shared.translate('Item has been successfully removed.'))
              var deliveryAddressIndex = this.deliveryAddressList.findIndex(d => d.Code == code)
              this.deliveryAddressList.splice(deliveryAddressIndex, 1);
              var deliveryAddressCountriesIndex = this.deliveryAddressCountries.findIndex(d => d == country)
              this.deliveryAddressCountries.splice(deliveryAddressCountriesIndex, 1)

            },
            error: (error) =>
              this.shared.errorAlert(this.shared.translate('Remove failed. Please, try again.'), error.message),
          });
        }
      })
  }
}