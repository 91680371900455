import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ExchangeCurrencyResponse } from "src/app/models/exchangecurrencyresponse.model";
import { ExchangeRateResponse } from "src/app/models/exchangerateresponde.model";
import { ExchangeRateTypeResponse } from "src/app/models/exchangeratetyperesponse.model";
import { Result } from "src/app/models/result.model";
import { musys } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class ExchangeRateService {
  constructor(private http: HttpClient) {}

  getExchangeCurrencies(): Observable<Result<ExchangeCurrencyResponse[]>> {
    return this.http.get<Result<ExchangeCurrencyResponse[]>>(
      musys.api + `/exchange-rate/currencies`
    );
  }

  getRateTypes(): Observable<Result<ExchangeRateTypeResponse[]>> {
    return this.http.get<Result<ExchangeRateTypeResponse[]>>(
      musys.api + `/exchange-rate/rate-type`
    );
  }

  Index(rateType: string): Observable<Result<ExchangeRateResponse[]>> {
    return this.http.get<Result<ExchangeRateResponse[]>>(
      musys.api + `/exchange-rate/rates/${rateType}`
    );
  }

  addExchangeRate(request): Observable<Result<any>> {
    return this.http.post<Result<any>>(
      musys.api + `/exchange-rate/rate`,
      request
    );
  }

  updateExchangeRate(request): Observable<Result<any>> {
    return this.http.put<Result<any>> (
      musys.api + `/exchange-rate/rate`,
      request
    );
  }

  deleteExchangeRate(request): Observable<Result<any>> {
    return this.http.delete<Result<any>> (
      musys.api +
        `/exchange-rate/rate/${
          request.RateType
        }/${request.ExchangeDate.substring(0, 10)}/${request.CurrencyIdFrom}/${
          request.CurrencyIdTo
        }`
    );
  }
}
