<div class="tds-container">
  <div>
    <p>Click on the items below to access basic data configurations:</p>

    <a [routerLink]="['/admin/partner-information']">Partner Information</a>
    <a [routerLink]="['/admin/market-unit-information']"
      >Market Unit Information</a
    >
    <a [routerLink]="['/admin/delivery-address']">Delivery Address</a>
    <a [routerLink]="['/admin/financial-parameters']">Financial Parameters</a>
    <a [routerLink]="['/admin/region-information']">Region Information</a>
    <a [routerLink]="['/admin/ffu-and-sorder']">FFU and SOrder</a>
    <a [routerLink]="['/admin/discount-rate']">Discount Rate</a>
    <a [routerLink]="['/admin/exchange-rate']">Exchange Rate</a>
    <a [routerLink]="['/admin/adm-mkt-days']">Adm Market Days</a>
  </div>
</div>
