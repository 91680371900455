import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DiscountRateGroup } from 'src/app/models/discountrategroup.model';
import { DiscountRateHeaderResponse } from 'src/app/models/discountrateheaderresponse.model';
import { DiscountRateResponse } from 'src/app/models/discountrateresponse.model';
import { Result } from 'src/app/models/result.model';
import { musys } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DiscountRateService {

  constructor(private http: HttpClient) { }

  getDiscountRateHeader():Observable<Result<DiscountRateHeaderResponse>> {
    return this.http.get<Result<DiscountRateHeaderResponse>>(musys.api + `/discount-rate/header`);
  }

  getDiscountRateGroup():Observable<Result<DiscountRateGroup[]>> {
    return this.http.get<Result<DiscountRateGroup[]>>(musys.api + `/discount-rate/group`);
  }

  getDiscountRate(request):Observable<Result<DiscountRateResponse[]>> {
    return this.http.get<Result<DiscountRateResponse[]>>(musys.api + `/discount-rate/${request.discountTypeId}/${request.discountPriceType}`);
  }

  saveDiscountRate(discountTypeId, discountPriceType, request) {
    return this.http.post<Result<DiscountRateResponse[]>>(musys.api + `/discount-rate/${discountTypeId}/${discountPriceType}`, request);  
  }
}
