import { DatePipe } from "@angular/common";
import { Component } from "@angular/core";
import { ProductService } from "src/app/service/api/product.service";
import {
  CdkDragDrop,
  moveItemInArray,
  transferArrayItem,
} from "@angular/cdk/drag-drop";
import { AccessService } from "src/app/service/api/access.service";
import { SharedService } from "src/app/service/shared.service";
import { ProductSeries } from "src/app/models/models/productseries.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SOrderFFU } from "src/app/models/sorderffu.model";

@Component({
  selector: "app-ffu-and-sorder",
  templateUrl: "./ffu-and-sorder.component.html",
  styleUrls: ["./ffu-and-sorder.component.css"],
})
export class FfuAndSorderComponent {
  ffuSOrderList: SOrderFFU[] = [];
  productSeriesList: ProductSeries[];
  partnerId: number = parseInt(this.shared.getPartnerId());

  requestType: string = "POST";
  variantSearch: string;

  filter: string;

  allVariantsList: any[];
  addedVariants: any[] = [];

  searchForm: FormGroup;
  get search() {
    return this.searchForm.controls;
  }

  saveForm: FormGroup;
  get save() {
    return this.saveForm.controls;
  }

  constructor(
    public shared: SharedService,
    private access: AccessService,
    private product: ProductService,
    private datepipe: DatePipe,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.getUserProductSeries(this.partnerId);
    this.createSaveForm();
    this.createSearchForm();
  }

  clear() {
    document.getElementById("searchVehicleTypeId")["reset"]();
    document.getElementById("searchType")["reset"]();
    this.createSearchForm();
    this.ffuSOrderList = [];
  }

  createSearchForm() {
    this.searchForm = this.fb.group({
      Number: [""],
      Type: ["", Validators.required],
      VehicleTypeId: ["", Validators.required],
    });
  }

  createSaveForm() {
    this.saveForm = this.fb.group({
      Id: [],
      Number: ["", Validators.required],
      InitialDate: ['', Validators.required],
      FinalDate: ['', Validators.required],
      Type: ["", Validators.required],
      Variants: [],
      VehicleTypeId: ["", Validators.required],
    });
  }

  searchSOF(type?, VehicleTypeId?) {
    var request
    if (type && VehicleTypeId) {
      request = {
        Type: type,
        VehicleTypeId: VehicleTypeId,
      }
    } else if (!this.searchForm.valid) {
      this.searchForm.markAllAsTouched();
      this.shared.warningAlert(
        this.shared.translate("Please, fill all required fields!")
      );
      return;
    } else {
      request = this.searchForm.value
    }
    this.product.GetSOFList(request).subscribe({
      next: (result) => {
        if (result.Data.SOFList.length == 0) {
          this.shared.warningAlert(
            this.shared.translate("No results found!"),
            "sm"
          );
        }
        this.ffuSOrderList = result.Data.SOFList;
      },
    });
  }

  getUserProductSeries(partnerId: number) {
    this.access.getUserProductSeries(partnerId).subscribe({
      next: (result) => {
        this.productSeriesList = result.Data;
      },
    });
  }

  onDrop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      if (event.previousContainer.id == 'cdk-drop-list-0') {
        this.addedVariants.splice(event.currentIndex, 0, this.allVariantsList.find(v => `${v?.Code} - ${v?.Description}` == event.item.element.nativeElement.innerText || `${v?.Code} -` == event.item.element.nativeElement.innerText))
        this.addedVariants = this.addedVariants.filter(v => v)
        this.allVariantsList = this.allVariantsList.filter(v => v != this.allVariantsList.find(v => `${v?.Code} - ${v?.Description}` == event.item.element.nativeElement.innerText || `${v?.Code} -` == event.item.element.nativeElement.innerText), 1)
      } else {
        this.allVariantsList.splice(event.currentIndex, 0, this.addedVariants.find(v => `${v?.Code} - ${v?.Description}` == event.item.element.nativeElement.innerText || `${v?.Code} -` == event.item.element.nativeElement.innerText))
        this.allVariantsList = this.allVariantsList.filter(v => v);
        this.addedVariants = this.addedVariants.filter(v => v != this.addedVariants.find(v => `${v?.Code} - ${v?.Description}` == event.item.element.nativeElement.innerText || `${v?.Code} -` == event.item.element.nativeElement.innerText), 1)
      }
    }
  }

  setHeaderNames() {
    document
      .getElementById("number")
      .setAttribute("column-title", this.shared.translate("Number"));
    document
      .getElementById("vehicleType")
      .setAttribute("column-title", this.shared.translate("Vehicle Type"));
    document
      .getElementById("initialDate")
      .setAttribute("column-title", this.shared.translate("Initial Date"));
    document
      .getElementById("finalDate")
      .setAttribute("column-title", this.shared.translate("Final Date"));
  }

  setTableValues(index: number) {
    document
      .getElementById(`rowNumber${index}`)
      .setAttribute("cell-value", this.ffuSOrderList[index].Number.toString());
    document
      .getElementById(`rowVehicleType${index}`)
      .setAttribute(
        "cell-value",
        this.ffuSOrderList[index].VehicleTypeId.toString()
      );
    document
      .getElementById(`rowInitialDate${index}`)
      .setAttribute(
        "cell-value",
        this.ffuSOrderList[index].InitialDate
          ? this.datepipe.transform(
            this.ffuSOrderList[index].InitialDate,
            "dd/MM/yyyy"
          )
          : ""
      );
    document
      .getElementById(`rowFinalDate${index}`)
      .setAttribute(
        "cell-value",
        this.ffuSOrderList[index].FinalDate
          ? this.datepipe.transform(
            this.ffuSOrderList[index].FinalDate,
            "dd/MM/yyyy"
          )
          : ""
      );
    document.getElementById(`rowEdit${index}`).setAttribute("cell-value", "");
    document.getElementById(`rowDelete${index}`).setAttribute("cell-value", "");
  }

  getAllVariants(variant: string) {
    if (variant == "" || !this.saveForm.get("VehicleTypeId").valid) {
      this.saveForm.get("VehicleTypeId").markAsTouched();
      this.shared.warningAlert(
        this.shared.translate("Please, fill all required fields!")
      );
      return;
    }
    var request = {
      Variant: variant,
      VehicleTypeId: this.saveForm.get("VehicleTypeId").value,
    };
    this.product.GetAllVariants(request).subscribe({
      next: (result) => {
        this.allVariantsList = result.Data.Variants;
        this.allVariantsList = this.allVariantsList.filter(
          (v) =>
            this.addedVariants.findIndex((variant) => variant.Code == v.Code) ==
            -1
        );
      },
    });
  }

  resetModal() {
    this.createSaveForm();
    document.getElementById("saveType")["reset"]();
    document.getElementById("saveVehicleTypeId")["reset"]();
    document.getElementById("searchVariant")["value"] = "";
    document.getElementById("filter")["value"] = "";
    this.addedVariants = [];
    this.allVariantsList = [];
  }

  editFFUSorder(index: number, modal: any) {
    this.save["Id"].patchValue(this.ffuSOrderList[index].SOFId);
    this.save["Number"].patchValue(this.ffuSOrderList[index].Number);
    this.save["InitialDate"].patchValue(
      new Date(this.ffuSOrderList[index]?.InitialDate)
        .toISOString()
        .substring(0, 10)
    );
    this.save["InitialDate"].disable();
    this.save["FinalDate"].patchValue(
      new Date(this.ffuSOrderList[index]?.FinalDate)
        .toISOString()
        .substring(0, 10)
    );
    this.save["Type"].patchValue(this.ffuSOrderList[index].Type);
    document
      .getElementById("saveType")
    ["setValue"](
      this.ffuSOrderList[index].Type,
      this.ffuSOrderList[index].Type == "F" ? "FFU" : "S.Order"
    );
    this.save["VehicleTypeId"].patchValue(
      this.ffuSOrderList[index].VehicleTypeId
    );
    document
      .getElementById("saveVehicleTypeId")
    ["setValue"](
      this.ffuSOrderList[index].VehicleTypeId,
      this.productSeriesList.find(
        (p) => p.Id == this.ffuSOrderList[index].VehicleTypeId
      ).Description
    );

    this.ffuSOrderList[index].Variants.forEach((variant) => {
      this.addedVariants.push(variant);
    });

    this.requestType = "PUT";
    modal.showModal();
  }

  removeFFUSorder(id: number) {
    this.shared
      .warningOptionAlert(
        this.shared.translate("Please, confirm rule removal!")
      )
      .then((response) => {
        this.product.RemoveSOF(id).subscribe({
          next: () => {
            this.ffuSOrderList.splice(
              this.ffuSOrderList.findIndex((ffu) => ffu.SOFId == id),
              1
            );
            this.shared.successAlert(
              this.shared.translate("Rule has been removed successfully!")
            );
          },
          error: () => {
            this.shared.warningAlert(
              this.shared.translate("Removed failed. Please, try again")
            );
          },
        });
      });
  }

  saveFFUSorder() {
    if (!this.saveForm.valid) {
      this.saveForm.markAllAsTouched();
      this.shared.warningAlert(
        this.shared.translate("Please, fill all required fields!")
      );
      return;
    }

    var sameFamily = false;
    this.addedVariants.forEach((variant) => {
      var family = variant.Code.substring(0, 5);
      if (
        this.addedVariants.filter((v) => v.Code.substring(0, 5) == family)
          .length > 1
      ) {
        this.shared.warningAlert(
          this.shared.translate("Cannot add variants from the same family!")
        );
        sameFamily = true;
        return;
      }
    });

    if (sameFamily) {
      return;
    }

    var variantCodes: string[] = [];

    this.addedVariants.forEach((variant) => {
      variantCodes.push(variant.Code);
    });
    this.save["Variants"].patchValue(variantCodes);

    var request =
      this.requestType == "POST" ? "CreateSOrderFFU" : "UpdateSOrderFFU";
    this.saveForm
      .get("Number")
      .patchValue(parseInt(this.saveForm.get("Number").value));

    this.product[request](this.saveForm.value).subscribe({
      next: (result) => {

        if (result.Success) {
          this.searchSOF(this.saveForm.get("Type").value, this.saveForm.get("VehicleTypeId").value);
          document.getElementById("searchVehicleTypeId")["setValue"](this.saveForm.get("VehicleTypeId").value, this.productSeriesList.find(p => p.Id == this.saveForm.get("VehicleTypeId").value).Description);
          document.getElementById("searchType")["setValue"](this.saveForm.get("Type").value, this.saveForm.get("Type").value == 'F' ? 'FFU' : 'S.Order');
          document.getElementById("ruleCreation")["closeModal"]();
          this.resetModal();
          this.shared.successAlert(
            `Rule ${request == "UpdateSOrderFFU" ? "updated" : "created"
            } Successfully!`
          );
        }else {
          this.shared.errorAlert(this.shared.translate('`Error creating rule!`'), result.Errors[0]);
        }
      },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate('`Error creating rule!`'));
      },
    });
  }

  setTextField(event: any) {
    switch (event.currentTarget.id) {
      case "searchVariant":
        this.variantSearch = event.target.value;
        break;
      case "filter":
        this.filter = event.target.value;
        break;
    }
  }

  setDropdown(event: any) {
    switch (event.currentTarget.id) {
      case "searchVehicleTypeId":
        this.search["VehicleTypeId"].setValue(parseInt(event.target.value));
        break;
      case "saveVehicleTypeId":
        this.save["VehicleTypeId"].setValue(parseInt(event.target.value));
        break;
      case "searchType":
        this.search["Type"].setValue(event.target.value);
        break;
      case "saveType":
        this.save["Type"].setValue(event.target.value);
        break;
    }
  }
}
