<tds-header>
  <!-- TODO setting aria-expanded="true" on the hamburger button does not work, as it is not
        copied to the button element -->
  <tds-header-hamburger id="demo-hamburger"
    onclick="demoSideMenu.open = true;demoHamburger.setAttribute('aria-expanded', true);"
    aria-label="Open application drawer" aria-haspopup="true" aria-expanded="false"></tds-header-hamburger>

  <tds-header-title>
    <a id="title" [routerLink]="['/']">
      <div _ngcontent-vds-c14="" class="sdds-nav__app-name">
        MUSYS&nbsp;<span style="font-family: sans-serif"> (beta)</span>
      </div>
    </a>
  </tds-header-title>

  <tds-header-dropdown>
    <span slot="label">{{ shared.translate("Admin") }}</span>
    <tds-header-dropdown-list>
      <ng-container *ngFor="let menu of menuAdmin">
        <tds-header-dropdown-list-item *ngIf="shared.hasAuthorization(menu.Code)" routerLinkActive="selected">
          <a routerLink="admin/{{ menu.Name.toLowerCase().replace(' ', '-') }}">{{
            shared.translate(menu.Name)
            }}</a>
        </tds-header-dropdown-list-item>
      </ng-container>
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <tds-header-item>
    <a href="">{{ shared.translate("Quotation") }}</a>
  </tds-header-item>

  <tds-header-dropdown>
    <span slot="label">{{ shared.translate("Sales") }}</span>
    <tds-header-dropdown-list>
      <ng-container *ngFor="let menu of menuSales">
        <tds-header-dropdown-list-item *ngIf="shared.hasAuthorization(menu.Code)">
          <a routerLinkActive="sdds-nav-sdds-nav__dropdown-item--active"
            routerLink="admin/{{ menu.Name.toLowerCase().replace(' ', '-') }}">{{ shared.translate(menu.Name) }}</a>
        </tds-header-dropdown-list-item>
      </ng-container>
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <tds-header-dropdown>
    <span slot="label">{{ shared.translate("Purchase") }}</span>
    <tds-header-dropdown-list>
      <ng-container *ngFor="let menu of menuPurchase">
        <tds-header-dropdown-list-item *ngIf="shared.hasAuthorization(menu.Code)">
          <a routerLinkActive="sdds-nav-sdds-nav__dropdown-item--active"
            routerLink="admin/{{ menu.Name.toLowerCase().replace(' ', '-') }}">{{ shared.translate(menu.Name) }}</a>
        </tds-header-dropdown-list-item>
      </ng-container>
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <input
    style="width: 280px; height: 64px; border: none; border-bottom: 1px solid black;"
    slot="end"
    type="text"
    placeholder="{{shared.translate('Popid, chassi, engine, order, invoice')}}"
    [disabled]="searchStatus"
    ngDefaultControl
    [(ngModel)]="inputValue"
    (keydown.enter)="search(inputValue)"
  >
  
  <tds-header-item slot="end" >
    <button (click)="toggleMenus('Search')">
      <tds-icon name="search" size="25px"></tds-icon>
    </button>
  </tds-header-item>

  <tds-header-dropdown slot="end" class="demo-hide demo-xs-show" no-dropdown-icon>
    <img slot="icon" src="../../../assets/images/profile.svg" style="width: 30px;" alt="User menu." />

    <tds-header-dropdown-list size="lg">
      <tds-header-dropdown-list-user header="{{ shared.CurrentUsername }}" subheader="{{ shared.CurrentUserFullName }}"></tds-header-dropdown-list-user>
      
      <tds-side-menu-dropdown>
        <span slot="button-label">{{shared.translate("Language")}}</span>
        <tds-side-menu-dropdown-list>
          <tds-side-menu-dropdown-list-item selected="{{language.LCID == currentLanguage}}" *ngFor="let language of languages">
            <a
              (click)="setLanguage(language.LCID)"
              >{{this.language.Term}}</a
            >
          </tds-side-menu-dropdown-list-item>
        </tds-side-menu-dropdown-list>
      </tds-side-menu-dropdown>

      <tds-header-dropdown-list-item>
        <a (click)="service.logout()" >{{shared.translate("Log Out")}}</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <tds-header-dropdown slot="end" class="demo-hide demo-xs-show" no-dropdown-icon>
    <div>
      <img slot="icon" src="../../../assets/images/world.svg" style="width: 30px;" alt="" />
    </div>
    <p slot="label">{{market.Code}}</p>
    
    <tds-header-launcher-list-title>{{
      shared.translate("Current market")
      }}</tds-header-launcher-list-title>
    <tds-header-dropdown-list size="lg">
      <tds-header-dropdown-list-item>
        <a seleted href="">{{ market.Code }} - {{ market.Name }} </a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
    <tds-header-launcher-list-title>{{
      shared.translate("Markets")
      }}</tds-header-launcher-list-title>
    <tds-header-dropdown-list size="lg">
      <tds-header-dropdown-list-item *ngFor="let m of markets" selected="{{m.Code == market.Code}}">
        <a (click)="setMarket(m.Id)">{{ m.Code }} - {{ m.Name }}</a>
      </tds-header-dropdown-list-item>
    </tds-header-dropdown-list>
  </tds-header-dropdown>

  <tds-header-brand-symbol slot="end">
    <a aria-label="Scania - red gryphon on blue shield" href="https://scania.com"></a>
  </tds-header-brand-symbol>
</tds-header>

<!-- <nav
  class="sdds-nav sdds-u-fixed sdds-u-top0"
  [ngClass]="[
    showMobileMenu
      ? 'sdds-nav sdds-u-fixed sdds-u-top0 sdds-nav__mob-menu--opened'
      : 'sdds-nav sdds-u-fixed sdds-u-top0',
    searchBar ? 'sdds-nav__searchbar--opened' : 'sdds-nav__searchbar',
    avatarMenu ? 'sdds-nav__avatar--opened' : 'sdds-nav__avatar-btn',
    marketList ? 'sdds-nav__app-launcher--opened' : 'sdds-nav__app-launcher'
  ]"
>
  <div class="sdds-nav__left">
    <div
      class="sdds-nav__overlay"
      click="closeDropdownsFromOverlay(event)"
    ></div>
    <button class="sdds-nav__mob-menu-btn" (click)="openMobileMenu()">
      <div id="sdds-nav__mob-menu-icon">
        <span
          class="sdds-nav__mob-menu-icon-line"
          id="sdds-nav__mob-menu-icon-line-1"
        ></span>
        <span
          class="sdds-nav__mob-menu-icon-line"
          id="sdds-nav__mob-menu-icon-line-2"
        ></span>
        <span
          class="sdds-nav__mob-menu-icon-line"
          id="sdds-nav__mob-menu-icon-line-3"
        ></span>
      </div>
    </button>

    <a [routerLink]="['/']">
      <div _ngcontent-vds-c14="" class="sdds-nav__app-name">MUSYS&nbsp;<span style="font-family:sans-serif"> 
        (beta)</span></div>    </a>
  </div>

  <div class="sdds-nav__center">
    <ul class="sdds-nav__inline-menu">
      <li
        id="Admin"
        class="sdds-nav__item sdds-nav__dropdown"
        style="z-index: 4"
        (click)="toggleMenus('Admin')"
        (mouseenter)="toggleMenus('Admin')"
        (mouseleave)="toggleMenus('')"
      >
        <button class="sdds-nav__item-core" *ngIf="menuAdmin">
          <p class="sdds-nav__item-core-text">{{shared.translate("Admin")}}</p>
          <span class="sdds-nav__dropdown-icon">
            <svg
              class="sdds-nav__dropdown-icon-svg"
              viewBox="0 0 14 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                fill="currentColor"
                d="M1.13668 0.891564C1.33194 0.696302 1.64853 0.696302 1.84379 0.891564L6.78786 5.83563C6.90501 5.95278 7.09496 5.95278 7.21212 5.83563L12.1562 0.891564C12.3515 0.696302 12.668 0.696303 12.8633 0.891565C13.0586 1.08683 13.0586 1.40341 12.8633 1.59867L7.91923 6.54273C7.41155 7.05041 6.58843 7.05041 6.08075 6.54273L1.13668 1.59867C0.941419 1.40341 0.941419 1.08683 1.13668 0.891564Z"
              />
            </svg>
          </span>
        </button>
        <ul
          [ngClass]="
            adminDropdown
              ? 'sdds-nav__dropdown-menu--opened'
              : 'sdds-nav__dropdown-menu'"
        >
          <ng-container *ngFor="let menu of menuAdmin">
            <li
              class="sdds-nav__dropdown-item"
              *ngIf="shared.hasAuthorization(menu.Code)"
            >
              <a
                class="sdds-nav__dropdown-item-core"
                routerLinkActive="sdds-nav-sdds-nav__dropdown-item--active"
                routerLink="admin/{{ menu.Name.toLowerCase().replace(' ', '-') }}"
                >{{ shared.translate(menu.Name) }}</a
              >
            </li>
          </ng-container>
        </ul>
      </li>

      <li class="sdds-nav__item sdds-nav__item--selected">
        <a class="sdds-nav__item-core" href="#">
          <span class="sdds-nav__item-core-text">{{shared.translate("Quotation")}}</span>
        </a>
      </li>

      <li
        id="Sales"
        class="sdds-nav__item sdds-nav__dropdown"
        style="z-index: 3"
        (click)="toggleMenus('Sales')"
        (mouseenter)="toggleMenus('Sales')"
        (mouseleave)="toggleMenus('')"
      >
        <button class="sdds-nav__item-core" *ngIf="menuSales">
          <p class="sdds-nav__item-core-text">{{shared.translate("Sales")}}</p>
          <span class="sdds-nav__dropdown-icon">
            <svg
              class="sdds-nav__dropdown-icon-svg"
              viewBox="0 0 14 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                fill="currentColor"
                d="M1.13668 0.891564C1.33194 0.696302 1.64853 0.696302 1.84379 0.891564L6.78786 5.83563C6.90501 5.95278 7.09496 5.95278 7.21212 5.83563L12.1562 0.891564C12.3515 0.696302 12.668 0.696303 12.8633 0.891565C13.0586 1.08683 13.0586 1.40341 12.8633 1.59867L7.91923 6.54273C7.41155 7.05041 6.58843 7.05041 6.08075 6.54273L1.13668 1.59867C0.941419 1.40341 0.941419 1.08683 1.13668 0.891564Z"
              />
            </svg>
          </span>
        </button>

        <ul
          [ngClass]="
            salesDropdown
              ? 'sdds-nav__dropdown-menu--opened'
              : 'sdds-nav__dropdown-menu'
          "
        >
          <ng-container *ngFor="let menu of menuSales">
            <li
              class="sdds-nav__dropdown-item"
              *ngIf="shared.hasAuthorization(menu.Code)"
            >
              <a
                class="sdds-nav__dropdown-item-core"
                routerLinkActive="sdds-nav-sdds-nav__dropdown-item--active"
                routerLink="Sales/{{ menu.Name.toLowerCase().replace(' ', '-') }}"
                >{{ shared.translate(menu.Name) }}</a
              >
            </li>
          </ng-container>
        </ul>
      </li>

      <li
        id="Purchase"
        class="sdds-nav__item sdds-nav__dropdown"
        style="z-index: 2"
        (click)="toggleMenus('Purchase')"
        (mouseenter)="toggleMenus('Purchase')"
        (mouseleave)="toggleMenus('')"
      >
        <button class="sdds-nav__item-core" *ngIf="menuPurchase">
          <p class="sdds-nav__item-core-text">{{shared.translate("Purchase")}}</p>
          <span class="sdds-nav__dropdown-icon">
            <svg
              class="sdds-nav__dropdown-icon-svg"
              viewBox="0 0 14 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                fill="currentColor"
                d="M1.13668 0.891564C1.33194 0.696302 1.64853 0.696302 1.84379 0.891564L6.78786 5.83563C6.90501 5.95278 7.09496 5.95278 7.21212 5.83563L12.1562 0.891564C12.3515 0.696302 12.668 0.696303 12.8633 0.891565C13.0586 1.08683 13.0586 1.40341 12.8633 1.59867L7.91923 6.54273C7.41155 7.05041 6.58843 7.05041 6.08075 6.54273L1.13668 1.59867C0.941419 1.40341 0.941419 1.08683 1.13668 0.891564Z"
              />
            </svg>
          </span>
        </button>

        <ul
          [ngClass]="
            purchaseDropdown
              ? 'sdds-nav__dropdown-menu--opened'
              : 'sdds-nav__dropdown-menu'
          "
        >
          <ng-container *ngFor="let menu of menuPurchase">
            <li
              class="sdds-nav__dropdown-item"
              *ngIf="shared.hasAuthorization(menu.Code)"
            >
              <a
                class="sdds-nav__dropdown-item-core"
                routerLinkActive="sdds-nav-sdds-nav__dropdown-item--active"
                routerLink="Purchase/{{ menu.Name.toLowerCase().replace(' ', '-') }}"
                >{{ shared.translate(menu.Name) }}</a
              >
            </li>
          </ng-container>
        </ul>
      </li>
    -->
<!-- SearchBar -->
<!--     <li class="sdds-nav__item" *ngIf="showMobileMenu">
        <div
          class="sdds-nav__app-searchbar-container sdds-nav__right sdds-nav__dropdown"
        >
          <div class="sdds-nav__app-searchbar">
            <div class="sdds-nav__searchbar-input-expanded">

              <ul *ngIf ="searchResult.length > 0" class="sdds-nav__searchbar-menu">
                <ul *ngIf="searchContains ('SALES_ORDER')" class="sdds-nav__searchbar-results--category">
                  <p class="sdds-nav__searchbar-results-category-title">{{shared.translate("SALES")}}</p>
                  <ng-container *ngFor="let result of searchResult">
                    <li
                      *ngIf="result.Type == 'SALES_ORDER'"
                      class="sdds-nav__searchbar-results-item"
                    >
                      <img
                        style="width: 30px; margin-left: 10px"
                        src="../../../assets/images/document.svg"
                      />
                      <a href="" class="sdds-nav__searchbar-results-item-core">
                        {{ result.Name }}
                      </a>
                    </li>
                  </ng-container>
                </ul>
                <ul *ngIf="searchContains ('POP_ID')" class="sdds-nav__searchbar-results--category">
                  <p class="sdds-nav__searchbar-results-category-title">{{shared.translate("PURCHASE")}}</p>
                  <ng-container *ngFor="let result of searchResult">
                    <li
                      *ngIf="result.Type == 'POP_ID'"
                      class="sdds-nav__searchbar-results-item"
                    >
                      <img
                        style="width: 30px; margin-left: 10px"
                        src="../../../assets/images/{{ result.Icon }}.svg"
                      />
                      <a href="" class="sdds-nav__searchbar-results-item-core">
                        {{ result.Name }}
                      </a>
                    </li>
                  </ng-container>
                </ul>
              </ul>

              <div>
                <input
                  id="mobileSearchInput"
                  [disabled]="searchStatus"
                  [(ngModel)]="inputValue"
                  class="sdds-nav__searchbar-input"
                  type="text"
                  placeholder="Popid, chassi, engine, order, invoice"
                  (keydown.enter)="search(inputValue)"
                />
              </div>

              <button
                class="sdds-nav__app-searchbar-btn sdds-nav__app-searchbar-x-btn"
                (click)="search(inputValue)"
              >
                <svg
                  class="sdds-nav__app-searchbar-btn-svg"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M6.47098 0.99231C3.44526 0.99231 0.992432 3.44514 0.992432 6.47086C0.992432 9.49657 3.44526 11.9494 6.47098 11.9494C7.80373 11.9494 9.02533 11.4735 9.97512 10.6824L14.1407 14.848C14.336 15.0433 14.6526 15.0433 14.8479 14.848C15.0431 14.6528 15.0431 14.3362 14.8479 14.1409L10.6823 9.97531C11.4735 9.02547 11.9495 7.80375 11.9495 6.47086C11.9495 3.44514 9.4967 0.99231 6.47098 0.99231ZM1.99243 6.47086C1.99243 3.99742 3.99755 1.99231 6.47098 1.99231C8.94441 1.99231 10.9495 3.99742 10.9495 6.47086C10.9495 8.94429 8.94441 10.9494 6.47098 10.9494C3.99755 10.9494 1.99243 8.94429 1.99243 6.47086Z"
                    fill="currentColor"
                    fill-opacity="1"
                  ></path>
                </svg>
              </button>
              <button
                id="mobileCloseSearch"
                class="sdds-nav__app-searchbar-btn sdds-nav__app-searchbar-x-btn"
                (click)="toggleMenus('Search')"
              >
                <svg
                  class="sdds-nav__app-searchbar-btn-svg"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.2499 3.75004C12.4452 3.9453 12.4452 4.26188 12.2499 4.45714L4.4571 12.25C4.26184 12.4452 3.94526 12.4452 3.75 12.25C3.55474 12.0547 3.55474 11.7381 3.75 11.5429L11.5428 3.75004C11.7381 3.55478 12.0547 3.55478 12.2499 3.75004Z"
                    fill="currentColor"
                    fill-opacity="1"
                  />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.75004 3.74996C3.9453 3.5547 4.26188 3.5547 4.45714 3.74996L12.25 11.5428C12.4452 11.738 12.4452 12.0546 12.25 12.2499C12.0547 12.4451 11.7381 12.4451 11.5428 12.2499L3.75004 4.45707C3.55478 4.26181 3.55478 3.94522 3.75004 3.74996Z"
                    fill="currentColor"
                    fill-opacity="1"
                  />
                </svg>
              </button>
              <div id="mobile-search-spinner" *ngIf="searchStatus">
                <sdds-spinner size="sm" variant="standard"></sdds-spinner>
              </div>
            </div>
            <button
              id="mobileSearch"
              class="sdds-nav__app-searchbar-btn sdds-nav__app-searchbar-mgl-btn"
              (click)="toggleMenus('Search')"
            >
              <svg
                class="sdds-nav__app-searchbar-btn-svg"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M6.47098 0.99231C3.44526 0.99231 0.992432 3.44514 0.992432 6.47086C0.992432 9.49657 3.44526 11.9494 6.47098 11.9494C7.80373 11.9494 9.02533 11.4735 9.97512 10.6824L14.1407 14.848C14.336 15.0433 14.6526 15.0433 14.8479 14.848C15.0431 14.6528 15.0431 14.3362 14.8479 14.1409L10.6823 9.97531C11.4735 9.02547 11.9495 7.80375 11.9495 6.47086C11.9495 3.44514 9.4967 0.99231 6.47098 0.99231ZM1.99243 6.47086C1.99243 3.99742 3.99755 1.99231 6.47098 1.99231C8.94441 1.99231 10.9495 3.99742 10.9495 6.47086C10.9495 8.94429 8.94441 10.9494 6.47098 10.9494C3.99755 10.9494 1.99243 8.94429 1.99243 6.47086Z"
                  fill="currentColor"
                  fill-opacity="1"
                ></path>
              </svg>
            </button>
          </div>
        </div>
      </li>
    </ul>

    <div
      class="sdds-nav__app-searchbar-container sdds-nav__right sdds-nav__dropdown"
      *ngIf="!showMobileMenu"
    >
      <div class="sdds-nav__app-searchbar">
        <div class="sdds-nav__searchbar-input-expanded">

          <ul *ngIf ="searchResult.length > 0" class="sdds-nav__searchbar-menu">
            <ul *ngIf="searchContains ('SALES_ORDER')" class="sdds-nav__searchbar-results--category">
              <p class="sdds-nav__searchbar-results-category-title">{{shared.translate("SALES")}}</p>
              <ng-container *ngFor="let result of searchResult">
                <li
                  *ngIf="result.Type == 'SALES_ORDER'"
                  class="sdds-nav__searchbar-results-item"
                >
                  <img
                    style="width: 30px; margin-left: 10px"
                    src="../../../assets/images/document.svg"
                  />
                  <a href="" class="sdds-nav__searchbar-results-item-core">
                    {{ result.Name }}
                  </a>
                </li>
              </ng-container>
            </ul>
            <ul *ngIf="searchContains ('POP_ID')" class="sdds-nav__searchbar-results--category">
              <p class="sdds-nav__searchbar-results-category-title">{{shared.translate("PURCHASE")}}</p>
              <ng-container *ngFor="let result of searchResult">
                <li
                  *ngIf="result.Type == 'POP_ID'"
                  class="sdds-nav__searchbar-results-item"
                >
                  <img
                    style="width: 30px; margin-left: 10px"
                    src="../../../assets/images/{{ result.Icon }}.svg"
                  />
                  <a href="" class="sdds-nav__searchbar-results-item-core">
                    {{ result.Name }}
                  </a>
                </li>
              </ng-container>
            </ul>
          </ul>

          <div>
            <input
              id="searchInput"
              [disabled]="searchStatus"
              [(ngModel)]="inputValue"
              class="sdds-nav__searchbar-input"
              type="text"
              placeholder="Popid, chassi, engine, order, invoice"
              (keydown.enter)="search(inputValue)"
            />
          </div>

          <button
            class="sdds-nav__app-searchbar-btn sdds-nav__app-searchbar-x-btn"
            (click)="search(inputValue)"
          >
            <svg
              class="sdds-nav__app-searchbar-btn-svg"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M6.47098 0.99231C3.44526 0.99231 0.992432 3.44514 0.992432 6.47086C0.992432 9.49657 3.44526 11.9494 6.47098 11.9494C7.80373 11.9494 9.02533 11.4735 9.97512 10.6824L14.1407 14.848C14.336 15.0433 14.6526 15.0433 14.8479 14.848C15.0431 14.6528 15.0431 14.3362 14.8479 14.1409L10.6823 9.97531C11.4735 9.02547 11.9495 7.80375 11.9495 6.47086C11.9495 3.44514 9.4967 0.99231 6.47098 0.99231ZM1.99243 6.47086C1.99243 3.99742 3.99755 1.99231 6.47098 1.99231C8.94441 1.99231 10.9495 3.99742 10.9495 6.47086C10.9495 8.94429 8.94441 10.9494 6.47098 10.9494C3.99755 10.9494 1.99243 8.94429 1.99243 6.47086Z"
                fill="currentColor"
                fill-opacity="1"
              ></path>
            </svg>
          </button>
          <button
            id="closeSearch"
            class="sdds-nav__app-searchbar-btn sdds-nav__app-searchbar-x-btn"
            (click)="toggleMenus('Search')"
          >
            <svg
              class="sdds-nav__app-searchbar-btn-svg"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M12.2499 3.75004C12.4452 3.9453 12.4452 4.26188 12.2499 4.45714L4.4571 12.25C4.26184 12.4452 3.94526 12.4452 3.75 12.25C3.55474 12.0547 3.55474 11.7381 3.75 11.5429L11.5428 3.75004C11.7381 3.55478 12.0547 3.55478 12.2499 3.75004Z"
                fill="currentColor"
                fill-opacity="1"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M3.75004 3.74996C3.9453 3.5547 4.26188 3.5547 4.45714 3.74996L12.25 11.5428C12.4452 11.738 12.4452 12.0546 12.25 12.2499C12.0547 12.4451 11.7381 12.4451 11.5428 12.2499L3.75004 4.45707C3.55478 4.26181 3.55478 3.94522 3.75004 3.74996Z"
                fill="currentColor"
                fill-opacity="1"
              />
            </svg>
          </button>
          <div id="search-spinner" *ngIf="searchStatus">
            <sdds-spinner size="sm" variant="standard"></sdds-spinner>
          </div>
        </div>
        <button
          id="Search"
          class="sdds-nav__app-searchbar-btn sdds-nav__app-searchbar-mgl-btn"
          (click)="toggleMenus('Search')"
        >
          <svg
            class="sdds-nav__app-searchbar-btn-svg"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.47098 0.99231C3.44526 0.99231 0.992432 3.44514 0.992432 6.47086C0.992432 9.49657 3.44526 11.9494 6.47098 11.9494C7.80373 11.9494 9.02533 11.4735 9.97512 10.6824L14.1407 14.848C14.336 15.0433 14.6526 15.0433 14.8479 14.848C15.0431 14.6528 15.0431 14.3362 14.8479 14.1409L10.6823 9.97531C11.4735 9.02547 11.9495 7.80375 11.9495 6.47086C11.9495 3.44514 9.4967 0.99231 6.47098 0.99231ZM1.99243 6.47086C1.99243 3.99742 3.99755 1.99231 6.47098 1.99231C8.94441 1.99231 10.9495 3.99742 10.9495 6.47086C10.9495 8.94429 8.94441 10.9494 6.47098 10.9494C3.99755 10.9494 1.99243 8.94429 1.99243 6.47086Z"
              fill="currentColor"
              fill-opacity="1"
            ></path>
          </svg>
        </button>
      </div>
    </div>
  -->
<!-- Começo da introdução do ícone de avatar do header-->
<!--  <ul class="sdds-nav__toolbar-menu">
      <li
        class="sdds-nav__item sdds-nav__avatar"
        id="Avatar"
        (mouseenter)="cancelCloseMenu('Avatar')"
        (mouseleave)="closeMenus('Avatar')"
      >
        <button
          class="sdds-nav__avatar-btn"
          (click)="toggleMenus('Avatar')"
        >
          <img
            class="sdds-nav__avatar-img"
            src="https://www.svgrepo.com/show/170303/avatar.svg"
            alt="profile photo"
          />
          <div class="sdds-nav__avatar-info sdds-nav__avatar-info--mobile">
            <p class="sdds-nav__avatar-title">
              {{ shared.getCurrentUsername() }}
            </p>
            <p class="sdds-nav__avatar-subtitle">{{ shared.getCurrentUserFullName() }}</p>
          </div>
        </button>

        <ul class="sdds-nav__avatar-menu">
          <li class="sdds-nav__avatar-item sdds-nav__avatar-item--large">
            <div class="sdds-nav__avatar-info">
              <p class="sdds-nav__avatar-title">
                {{ shared.getCurrentUsername() }}
              </p>
              <p class="sdds-nav__avatar-subtitle">
                {{ shared.getCurrentUserFullName() }}
              </p>
            </div>
          </li>-->

<!-- Chamando a lista de idiomas-->
<!--    <li
            id="Languages"
            class="sdds-sidebar-nav__item sdds-sidebar-nav__extended"
            [ngClass]="
              languageList
                ? 'sdds-sidebar-nav__item sdds-sidebar-nav__extended subnav-open'
                : 'sdds-sidebar-nav__item sdds-sidebar-nav__extended'
            "
          >
            <a class="sdds-sidebar-nav__item-link" (click)="toggleMenus('Languages')">
              <span class="sdds-sidebar-nav__item-text">{{shared.translate("Language")}}</span>
              <svg
                class="sdds-sidebar-nav__chevron"
                width="12"
                height="7"
                viewBox="0 0 12 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1 1L6 6L11 1"
                  stroke="currentColor"
                  stroke-width="1.25"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></path>
              </svg>
            </a>
            <ul class="sdds-sidebar-nav-subnav">-->
<!-- "sdds-sidebar-nav-subnav__item sdds-item--active" para quando estiver ativo-->
<!--              <li class="sdds-sidebar-nav-subnav__item">
                <span class="sdds-sidebar-nav__item-title">Sub-menu</span>
              </li>
              <li class="sdds-sidebar-nav-subnav__item"
                [ngClass]="language.LCID == currentLanguage ? 'sdds-item--active' : '' "
                *ngFor="let language of languages">
                <a
                  class="sdds-sidebar-nav__item-link"
                  (click)="setLanguage(language.LCID)"
                  >{{this.language.Term}}</a
                >
              </li>
            </ul>
          </li>

          <li class="sdds-nav__avatar-item">
            <a (click)="service.logout()" class="sdds-nav__avatar-item-core">{{shared.translate("Log Out")}}</a>
          </li>
        </ul>
      </li>
    </ul>
  </div>-->
<!-- Término da introdução do ícone de avatar do header-->

<!-- Parte dos menus -->
<!--  <div
    class="sdds-nav__right"
    *ngIf="!showMobileMenu"
  >
    <div class="sdds-nav__item sdds-nav__app-launcher"
    id="Markets"
    (mouseenter)="cancelCloseMenu('Markets')"
    (mouseleave)="closeMenus('Markets')">
      <button
      (click)="toggleMenus('Markets')"
       class="sdds-nav__app-launcher-btn">
        <svg
          class="sdds-nav__app-launcher-btn-svg"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M 16 2 C 8.26801 2 2 8.26802 2 16 C 2 23.732 8.26801 30 16 30 C 23.732 30 30 23.732 30 16 C 30 8.26801 23.732 2 16 2 Z M 28 16 C 28 17.4025 27.7594 18.7489 27.3172 20 H 23.6767 C 23.8877 18.729 24 17.3855 24 16 C 24 14.6145 23.8877 13.271 23.6767 12 H 27.3172 C 27.7594 13.2511 28 14.5975 28 16 Z M 23.2481 22 H 26.3946 C 25.1703 24.1165 23.3181 25.8243 21.0928 26.8688 C 21.3574 26.4902 21.6035 26.0875 21.8302 25.6665 C 22.4092 24.5913 22.8885 23.3539 23.2481 22 Z M 22 16 C 22 17.4076 21.8746 18.7527 21.6466 20 H 17 V 12 H 21.6466 C 21.8746 13.2473 22 14.5924 22 16 Z M 15 12 V 20 H 10.3534 C 10.1254 18.7527 10 17.4076 10 16 C 10 14.5924 10.1254 13.2473 10.3534 12 H 15 Z M 17 10 H 21.1705 C 20.8698 8.99114 20.4973 8.07658 20.0693 7.28171 C 19.1572 5.58793 18.0773 4.56893 17 4.18062 V 10 Z M 15 4.18062 V 10 H 10.8295 C 11.1302 8.99114 11.5027 8.07658 11.9307 7.28171 C 12.8428 5.58793 13.9227 4.56893 15 4.18062 Z M 15 22 H 10.8295 C 11.1302 23.0089 11.5027 23.9234 11.9307 24.7183 C 12.8428 26.4121 13.9227 27.4311 15 27.8194 V 22 Z M 17 27.8194 C 18.0773 27.4311 19.1572 26.4121 20.0693 24.7183 C 20.4973 23.9234 20.8698 23.0089 21.1705 22 H 17 V 27.8194 Z M 8 16 C 8 17.3855 8.11225 18.729 8.3233 20 H 4.68282 C 4.24062 18.7489 4 17.4025 4 16 C 4 14.5975 4.24062 13.2511 4.68282 12 H 8.3233 C 8.11225 13.271 8 14.6145 8 16 Z M 8.75193 22 H 5.60539 C 6.8297 24.1165 8.68185 25.8243 10.9072 26.8688 C 10.6426 26.4902 10.3965 26.0875 10.1698 25.6665 C 9.59084 24.5913 9.1115 23.3539 8.75193 22 Z M 26.3946 10 C 25.1703 7.88354 23.3181 6.1757 21.0928 5.13117 C 21.3574 5.50978 21.6035 5.91254 21.8302 6.33351 C 22.4092 7.40869 22.8885 8.64609 23.2481 10 H 26.3946 Z M 8.75193 10 C 9.1115 8.64609 9.59084 7.40869 10.1698 6.33351 C 10.3965 5.91253 10.6426 5.50978 10.9072 5.13117 C 8.68185 6.1757 6.82969 7.88353 5.60538 10 H 8.75193 Z"
            fill="currentColor"
            fill-opacity="1"
          />
        </svg> -->
<!-- Nome do mercado-->
<!--      <span class="musys-markets">{{market.Code}}</span>
      </button>
      
      <ul class="sdds-nav__app-launcher-menu">
        <li
        class="sdds-nav__app-launcher-item sdds-nav__app-launcher-item--category"
      >
        <p class="sdds-nav__app-launcher-category-title">Current market</p>
      </li>
        <li class="sdds-nav__app-launcher-item">
          <a href="" class="sdds-nav__app-launcher-item-core"
          >{{market.Code}} - {{market.Name}}
        </a>
      </li>
      <li
        class="sdds-nav__app-launcher-item sdds-nav__app-launcher-item--category"
      >
        <p class="sdds-nav__app-launcher-category-title">Markets</p>
      </li>
    
        <li *ngFor="let market of markets" class="sdds-nav__app-launcher-item">
          <a class="sdds-nav__app-launcher-item-core" (click)="setMarket(market.Id)"
            >{{ market.Code }} - {{ market.Name }}</a
          >
        </li>
      </ul>
    </div>
  </div>
  <a [routerLink]="['/']" class="sdds-nav__item sdds-nav__app-logo"></a>
</nav> -->