import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Language } from 'src/app/models/language.model';
import { Result } from 'src/app/models/result.model';
import { Term } from 'src/app/models/term.model';
import { musys } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private http: HttpClient) { }

  createOrUpdateTerms(term):Observable<Result<any>> {
    return this.http.post<Result<any>>(musys.api + `/language/terms`, term);
  }
  getTermsFromTo(lcid):Observable<Result<Term[]>> {
    return this.http.get<Result<Term[]>>(musys.api + `/language/terms-from-to?lcid=${lcid}`);
  }
  getTerms(lcid, term):Observable<Result<Language[]>> {
    return this.http.get<Result<Language[]>>(musys.api + `/language/terms?lcid=${lcid}&term=${term}`);
  }
}
