import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { musys } from 'src/environments/environment';
import { CookieService } from 'ngx-cookie-service';
import { LoginGuard } from './login.guard';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  
  constructor(public auth: AuthService, private cookieService:CookieService, private login: LoginGuard) {}
  
  intercept(localRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    var currentMusysToken = this.cookieService.get('Musys.Ui.User.UserToken.Current');
    
    if (!currentMusysToken) this.login.canActivate();

    if(localRequest.url.startsWith(musys.api) && currentMusysToken != '') {
        localRequest = localRequest.clone({
            setHeaders: {
              'content-type': 'application/json',
              Authorization: `Bearer ${currentMusysToken}`
            }
          });
    }
    return next.handle(localRequest);
    }

}