<div class="tds-container">
    <tds-block>
        <div class="tds-row wrap-adjust">
            <div class="tds-col-md-8 sdds-col-sm-8 sdds-col-xs-8">
                <h2 class="tds-headline-02">
                    {{ this.shared.translate("Discount Rate") }}
                </h2>
            </div>
        </div>

        <tds-block>
            <form [formGroup]="discountHeaderForm">
                <div class="tds-row">
                    <div class="tds-col-md tds-col-xs-12">
                        <tds-dropdown label="{{shared.translate('Discount Type')}}" label-position="outside"
                            placeholder="Select" size="sm" ngDefaultControl formControlName="discountTypeId"
                            (tdsSelect)="setHeaderDropdown($event)" id="discountTypeDropdown" name="dropdown"
                            open-direction="down" style="z-index: 101">
                            <tds-dropdown-option value="{{ type.Id }}" *ngFor="let type of discountTypeList"
                                #typeList>{{ type.Description }}</tds-dropdown-option>
                        </tds-dropdown>
                    </div>
                    <div class="tds-col-md tds-col-xs-12">
                        <tds-dropdown label="{{shared.translate('Price Type')}}" label-position="outside"
                            placeholder="Select" size="sm" ngDefaultControl formControlName="discountPriceType"
                            (tdsSelect)="setHeaderDropdown($event)" id="priceTypeDropdown" name="dropdown"
                            open-direction="down" style="z-index: 101">
                            <tds-dropdown-option value="{{ price.Id }}" *ngFor="let price of priceTypeList" #typeList>{{
                                price.Description }}</tds-dropdown-option>
                        </tds-dropdown>
                    </div>
                    <div class="tds-col-md-1 tds-col-xs-12 align-end justify-end">
                        <tds-button (click)="getDiscountRateDetail()" type="button" variant="secondary" size="sm"
                            text="{{ shared.translate('Search') }}"></tds-button>
                    </div>
                </div>
            </form>
        </tds-block>

        <tds-block *ngIf="searched">
            <tds-block>
                <div class="tds-row tds-u-m2">
                    <div class="tds-col-md-10 tds-col-xs-12">
                        <h3>{{shared.translate('Discount Rate Detail') }}</h3>
                    </div>
                    <div class="tds-col-md-2  tds-col-xs-12 align-center justify-end">
                        <tds-button (click)="saveDiscountRate()" style="margin: 0px;" id="newDiscountRate" type="button"
                            variant="primary" size="sm" class="tds-u-mr2"
                            text="{{shared.translate('Save')}}"></tds-button>
                        <tds-button style="margin: 0px;" id="newDiscountRate" type="button" variant="secondary"
                            size="sm" (click)="addDiscountRate()" text="{{shared.translate('Add')}}"></tds-button>
                    </div>
                </div>
                <form [formGroup]="discountRateList">
                    <tds-table table-id="pagination-table" no-min-width vertical-dividers="false" compact-design="true"
                        responsive="true">
                        <tds-table-header>
                            <tds-header-cell id="role" column-title="{{setHeaderNames()}}"></tds-header-cell>
                            <tds-header-cell style="width: 150px" id="value" column-title=""></tds-header-cell>
                            <tds-header-cell style="width: 200px" id="validDate" column-title=""></tds-header-cell>
                            <tds-header-cell style="width: 50px" id="delete" column-title=""></tds-header-cell>
                        </tds-table-header>
                        <ng-container formArrayName="DiscountRateRequest">
                            <tds-table-body-row [formGroupName]="i"
                                *ngFor="let discountRate of disc.controls; let i = index">
                                <tds-body-cell id="rowRole{{i}}">
                                    <tds-dropdown ngDefaultControl formControlName="ReferenceNumber" class="reset"
                                        [ngStyle]="{'z-index': (100+disc.controls.length-i).toString()}"
                                        placeholder="{{discountRate.get('ReferenceName').value}}" size="sm"
                                        (tdsSelect)="setDetailDropdown($event, i)" id="ReferenceNumber{{i}}"
                                        name="dropdown" open-direction="down">
                                        <tds-dropdown-option value="{{ group.Id }}"
                                            *ngFor="let group of discountRateGroup">{{ group.Name
                                            }}</tds-dropdown-option>
                                    </tds-dropdown>
                                </tds-body-cell>
                                <tds-body-cell id="rowValue{{i}}">
                                    <tds-text-field (focusout)="setValueToNumber(i)" ngDefaultControl
                                        formControlName="Value" class="reset" type="number" size="sm"
                                        no-min-width></tds-text-field>
                                </tds-body-cell>
                                <tds-body-cell id="rowValidDate{{i}}">
                                    <tds-datetime class="reset" formControlName="ValidDate" ngDefaultControl
                                        no-min-width type="date" size="sm" id="validDate">
                                    </tds-datetime>
                                </tds-body-cell>
                                <tds-body-cell id="rowDelete{{i}}" cell-value="">
                                    <img class="icon-align" (click)="deleteDiscountRate(i)"
                                        style="width: 25px; margin-left: 10px;margin-top: 10px; cursor: pointer"
                                        src="../../../../../assets/images/trash.svg" id="deleteDiscountRate{{i}}">

                                    <tds-tooltip placement="bottom" selector="#deleteDiscountRate{{i}}"
                                        text="{{shared.translate('Delete')}}" mouse-over-tooltip="true"> </tds-tooltip>
                                </tds-body-cell>
                            </tds-table-body-row>
                        </ng-container>
                        <tds-table-footer></tds-table-footer>
                    </tds-table>
                    <div *ngIf="disc.controls.length == 0" class="justify-center align-center">{{shared.translate('There is no data for chosen Discount Rate.')}}</div>
                </form>
            </tds-block>
        </tds-block>
    </tds-block>