import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { musys } from 'src/environments/environment';
import { SharedService } from '../shared.service';
import { GetSOFListResponse } from 'src/app/models/getsoflistresponse.model';
import { GetAllVariantsResponse } from 'src/app/models/getallvariantsresponse.model';
import { Observable } from 'rxjs';
import { Result } from 'src/app/models/result.model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient, private shared: SharedService) { }

  GetSOFList(request): Observable<Result<GetSOFListResponse>> {
    var params = new HttpParams({fromObject: request})
    return this.http.get<Result<GetSOFListResponse>>(musys.api + '/product/GetSOFList', {params: params});
  }

  GetAllVariants(request): Observable<Result<GetAllVariantsResponse>> {
    var params = new HttpParams({fromObject: request});
    return this.http.get<Result<GetAllVariantsResponse>>(musys.api + '/product/GetAllVariants', {params: params});
  }

  CreateSOrderFFU(request) {
    return this.http.post(musys.api + `/product`, request);
  }

  UpdateSOrderFFU(request) {
    return this.http.put(musys.api + `/product`, request);
  }

  RemoveSOF(id: number) {
    return this.http.delete(musys.api + `/product/${id}`);
  }
}
