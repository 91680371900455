<div class="tds-container-fluid">
    <tds-block>
        <!-- Título da tela // Botões New e Clear -->
        <div class="tds-row tds-u-pb2">
            <div class="tds-col-md-10">
                <h3>{{shared.translate("Languages")}}</h3>
            </div>
            <div class="tds-col-md-2 tds-col-xs-12 align-center justify-end tds-u-pb2">
            </div>
        </div>

        <tds-block>
            <div class="tds-row">
                <tds-table table-id="pagination-table" vertical-dividers="false" compact-design="true" responsive="true">
                    <tds-table-header>
                        <tds-header-cell id="lcid" column-title="{{setLanguagesHeaderNames()}}"></tds-header-cell>
                        <tds-header-cell id="name" column-title=""></tds-header-cell>
                        <tds-header-cell custom-width="20px" id="edit" column-title=""></tds-header-cell>
                    </tds-table-header>
                    <tds-table-body-row *ngFor="let language of languages; let i = index">
                        <tds-body-cell id="rowLCID{{i}}"></tds-body-cell>
                        <tds-body-cell id="rowName{{i}}"></tds-body-cell>
                        <tds-body-cell id="rowEdit{{i}}" cell-value="{{setLanguagesTableValues(i, language)}}">
                            <span (click)="getTermsFromTo(language)" class="editTerms" >{{shared.translate('Edit Terms')}}</span>
                        </tds-body-cell>
                    </tds-table-body-row>
                </tds-table>
            </div>
        </tds-block>

        <!-- Terms -->
        <tds-block id="terms" style="display: none;">
                <div>
                    <div class="tds-row tds-u-mt3">
                        <div class="tds-col-md-10 sdds-col-sm-8 sdds-col-xs-8">
                            <h4 class="tds-headline-04">
                                {{ this.shared.translate("Terms") }} - {{selectedLanguage?.Term}}
                            </h4>
                        </div>
                        <div class="tds-col-md-2 tds-col-sm-12 tds-col-xs-12 tds-u-pb2 align-center justify-end">
                            <tds-button type="button" variant="primary" size="sm" class="tds-u-pr2"
                                text="{{ shared.translate('Export') }}" (click)="exportCSV()">
                            </tds-button>
                            <tds-button id="termModal" type="button" variant="secondary" size="sm"
                                text="{{ shared.translate('Add Term') }}" (click)="createTermForm()">
                            </tds-button>
                        </div>
                    </div>
                    <div class="tds-row align-end justify-end">
                        <tds-checkbox #checkbox (tdsChange)="showEmptyTranslation(checkbox)" class="tds-u-mr2 tds-u-mb1">
                            <div slot="label">{{shared.translate('Empty Translation')}}</div>
                        </tds-checkbox>
                        <tds-text-field mode-variant="secondary" placeholder="{{shared.translate('Search')}}" ngDefaultControl [(ngModel)]="termFilter" type="text" size="sm"></tds-text-field>
                    </div>
                    <div class="tds-row">
                        <tds-table table-id="pagination-table" vertical-dividers="false" compact-design="true" responsive="true">
                            <tds-table-header>
                                <tds-header-cell custom-width="33%" id="term" column-title="{{setTermsHeaderNames()}}"></tds-header-cell>
                                <tds-header-cell custom-width="34%" id="original" column-title=""></tds-header-cell>
                                <tds-header-cell custom-width="33%" id="translation" column-title=""></tds-header-cell>
                            </tds-table-header>
                            <tds-table-body-row *ngFor="let term of terms | filterTerms: termFilter; let i = index">
                                <tds-body-cell id="rowTerm{{i}}"></tds-body-cell>
                                <tds-body-cell id="rowOriginal{{i}}"></tds-body-cell>
                                <tds-body-cell id="rowTranslation{{i}}" cell-value="{{setTermsTableValues(i, term)}}">
                                    <tds-text-field mode-variant="secondary" (keydown.enter)="UpdateTerm(term, $event)" ngDefaultControl value="{{term.DescTermTo}}"
                                    class="reset" type="text" size="sm"></tds-text-field>
                                </tds-body-cell>
                            </tds-table-body-row>
                        </tds-table>
                    </div>
                </div>
        </tds-block>
    </tds-block>
</div>

<tds-modal selector="#termModal" id="termModal" #modal size="md" actions-position="sticky"
    prevent (tdsClose)="'Função para limpar campos'">
    <span slot="body">
        <form [formGroup]="termForm">
            <tds-block>
                <tds-block>
                    <h3>
                        {{ shared.translate("Add Term") }}
                    </h3>
                    <div class="tds-row">
                        <div class="tds-col-md-12 tds-col-sm-12 tds-col-xs-12">
                            <tds-text-field ngDefaultControl formControlName="Term" 
                            no-min-width type="text" size="md" id="Term" label="{{ shared.translate('Term') }}"
                            label-position="outside" placeholder="{{shared.translate('Term')}}"
                            helper="{{ f['Term'].touched && f['Term'].errors ? 'Required Field' : '' }}" 
                            (focusout)="f['Term'].markAsTouched()" 
                            state="{{ f['Term'].touched && f['Term'].errors ? 'error' : '' }}"></tds-text-field>
                        </div>
                    </div>
                    <div class="tds-row">
                        <div class="tds-col-md-11 sdds-col-sm-8 sdds-col-xs-8">
                            <h3>
                                {{ shared.translate("Translations") }}
                            </h3>
                        </div>
                        <div class="tds-col-md-1 tds-col-sm-12 tds-col-xs-12 tds-u-pb2 align-center justify-end">
                            <img class="imgButtons" (click)="addTranslation()"
                                        src="../../../../../assets/images/plus.svg">
                        </div>
                    </div>
                    <div  formArrayName="request">
                        <div class="tds-row" [formGroupName]="i" *ngFor="let translation of translations.controls; let i = index">
                            <div class="tds-col-md-6 tds-col-sm-12 tds-col-xs-12">
                                <tds-text-field ngDefaultControl formControlName="Description" 
                                no-min-width type="text" size="md" id="Description{{i}}" label="{{ shared.translate('Translation') }}"
                                label-position="outside" placeholder="{{shared.translate('Translation')}}"
                                helper="{{ translations.controls[i]['controls']['Description'].touched && translations.controls[i]['controls']['Description'].errors ? 'Required Field' : '' }}" 
                                (focusout)="translations.controls[i]['controls']['Description'].markAsTouched()" 
                                state="{{ translations.controls[i]['controls']['Description'].touched && translations.controls[i]['controls']['Description'].errors ? 'error' : '' }}"></tds-text-field>
                            </div>
                            <div class="tds-col-md-5 tds-col-sm-5 tds-col-xs-5">
                                <tds-dropdown [ngStyle]="{'z-index': (100+translations.controls.length-i).toString()}" ngDefaultControl formControlName="Lcid" id="Lcid{{i}}"
                                    size="md" label="{{ this.shared.translate('Language') }}" label-position="outside"
                                    placeholder="{{shared.translate('Select')}}" size="md" open-direction="down" style="z-index: 3"
                                    (tdsSelect)="setLanguageTerm(i, $event)" defaultValue="{{translations.controls[i]['controls']['Lcid'].value}}"
                                    [error]=" translations.controls[i]['controls']['Lcid'].touched && translations.controls[i]['controls']['Lcid'].errors" 
                                    helper="{{ translations.controls[i]['controls']['Lcid'].touched && translations.controls[i]['controls']['Lcid'].errors? 'Required field': '' }}">
                                    <tds-dropdown-option *ngFor="let language of languages"
                                        value="{{ language.LCID }}">{{ language.Term }}</tds-dropdown-option>
                                </tds-dropdown>
                            </div>
                            <div class="tds-col-md-1 tds-col-sm-1 tds-col-xs-1 align-center">
                                <img *ngIf="i > 0" class="imgButtons" style="margin-top: 16px;" (click)="removeTranslation(i)"
                                        src="../../../../../assets/images/minus.svg">
                            </div>
                        </div>
                    </div>
                </tds-block>
            </tds-block>
        </form>
    </span>
    <span slot="actions">
        <div class="tds-row">
            <div class="tds-col-xs-6">
                <tds-button type="button" variant="danger" size="sm" text="{{ shared.translate('Cancel') }}"
                    data-dismiss-modal></tds-button>
            </div>
            <div class="tds-col-xs-6">
                <tds-button type="button" variant="primary" size="sm" text="{{ this.shared.translate('Save') }}"
                    (click)="CreateTerm(modal)"></tds-button>
            </div>
        </div>
    </span>
</tds-modal>