import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http'
import { musys } from 'src/environments/environment';
import { Language } from '../models/language.model';
import { Observable } from 'rxjs';
import { Result } from '../models/result.model';
import { Market } from '../models/market.model';
import { SearchResponse } from '../models/searchresponse.model';
import { Menu } from '../models/menu.model';
import { AuthService } from '../auth/auth.service';
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {

  logoutProcess: boolean = false;

  constructor(private httpClient: HttpClient, private auth: AuthService, private cookie: CookieService) { }

  search(parameter: string): Observable<Result<SearchResponse>> {
    return this.httpClient.get<Result<SearchResponse>>(
      musys.api + `/search?parameter=${parameter}`
    );
  }

  getLanguages(): Observable<Result<Language[]>> {
    return this.httpClient.get<Result<Language[]>>(
      musys.api + '/access/languages'
    );
  }

  getMarkets(): Observable<Result<Market[]>> {
    return this.httpClient.get<Result<Market[]>>(musys.api + '/access/markets');
  }

  getMenus(menuCode: string): Observable<Result<Menu[]>> {
    return this.httpClient.get<Result<Menu[]>>(
      musys.api + `/access/menus?menuCode=${menuCode}`
    );
  }

  logout() {
    this.logoutProcess = true;
    this.cookie.deleteAll('/');
    let logoutUrl: string | null = null;
    let redir_url: string = `${window.location.protocol}//${window.location.host}${window.location.pathname}`;
    let env: string | null = null;
    let env_prefix: string = 'pre';

    if (window.location.host.indexOf('localhost') > -1) {
      env = '_dev';
    } else if (window.location.host.indexOf('acp-') > -1) {
      env = '_acp';
    } else if (window.location.host.indexOf('dev-') > -1) {
      env = '_dev';
    } else if (window.location.host.indexOf('musys.scania.com') > -1) {
      redir_url = `https://musys.scania.com/pkmslogout?baseurl=${window.location.href}`;
      env_prefix = '';
      env = '';
    } else {
      env_prefix = '';
      env = '';
    }

    logoutUrl = `https://fg.ciam.${env_prefix}prod.aws.scania.com/auth/realms/scania/protocol/openid-connect/logout?client_id=musys_api${env}&redirect_uri=${redir_url}`;

    window.location.href = logoutUrl;
  }

  checkLogout() {
    return this.logoutProcess;
  }
}
