import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { MarketParameters } from "src/app/models/marketparameters.model";
import { Result } from "src/app/models/result.model";
import { musys } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MarketParameterService {
  constructor(private http: HttpClient) {}

  get(): Observable<Result<MarketParameters[]>> {
    return this.http.get<Result<MarketParameters[]>>(
      musys.api + `/market/parameters`
    );
  }

  getCurrentMarketParam(
    parameterId: number
  ): Observable<Result<MarketParameters[]>> {
    return this.http.get<Result<MarketParameters[]>>(
      musys.api + `/market/parameters/${parameterId}`
    );
  }

  create(request): Observable<Result<MarketParameters[]>> {
    return this.http.post<Result<MarketParameters[]>>(
      musys.api + `/market/parameters`,
      request
    );
  }

  update(parameterId, request): Observable<Result<MarketParameters[]>> {
    return this.http.put<Result<MarketParameters[]>>(
      musys.api + `/market/parameters/${parameterId}`,
      request
    );
  }

  delete(parameterId): Observable<Result<MarketParameters[]>> {
    return this.http.delete<Result<MarketParameters[]>>(
      musys.api + `/market/parameters/${parameterId}`
    );
  }
}
