import { Component, ElementRef, ViewChild } from "@angular/core";
import {
  Form,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { FinancialDataType } from "src/app/models/financialdatatype.model";
import { FinancialItemRequest } from "src/app/models/financialitemrequest.model";
import { FinancialTypeRequest } from "src/app/models/financialtyperequest.model";
import { FinancialParametersService } from "src/app/service/api/financial-parameters.service";

import { SharedService } from "src/app/service/shared.service";

@Component({
  selector: "app-financial-parameters",
  templateUrl: "./financial-parameters.component.html",
  styleUrls: ["./financial-parameters.component.css"],
})
export class FinancialParametersComponent {
  @ViewChild("itemModal") itemModal!: ElementRef;
  @ViewChild("addFinType") addFinType!: ElementRef;
  @ViewChild("status") status!: ElementRef;

  financialTypeList: FinancialTypeRequest[] = [];
  financialItemList: FinancialItemRequest[] = [];
  financialIDatatypeList: FinancialDataType[] = [];

  finType: number;
  financialType: string = "";
  formFinancialItem: FormGroup;
  formAddFinancialType: FormGroup;
  formListItemList: FormGroup;
  modalType: string = "";
  requestType: string = "";
  searchStatus: boolean = false;
  findResult: boolean = false;

  selectedFinancialType: any = {
    descriptionType: "",
    statusType: "",
  };

  get items() {
    return this.formFinancialItem
      .get("FinancialItemList")
      ["controls"].get("Items") as FormArray;
  }

  get fb() {
    return this.formAddFinancialType.controls;
  }
  get fc() {
    return this.formFinancialItem.get("FinancialItem")["controls"];
  }

  constructor(
    private financialParameter: FinancialParametersService,
    public shared: SharedService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.getFinancialType();
    this.createFormItem();
    this.createFormType();
    this.getFinancialDataType();
  }

  openModal(modal: any, requestType?: string) {
    modal.showModal();
    if (requestType == "Post") {
      // this.formFinancialItem.reset(new FinancialItemRequest());
      if (
        this.formFinancialItem.get("FinancialItem").get("DataType").value ==
        "List"
      ) {
      }
      if (requestType) {
        this.requestType = requestType;
      }
    }
    this.createFormType();
  }

  clear() {
    document.getElementById("fintypeDropdown")["reset"]();
    document.getElementById("financialTypeStatus")["reset"]();
    this.selectedFinancialType.descriptionType = "";
    this.selectedFinancialType.statusType = "";
    this.financialItemList = [];
    document.getElementById("resultItems").style.visibility = "hidden";
    document.getElementById("editTypeInformation").style.display = "none";
  }

  closeModal(modal: any, datatypeDropdown?: any, typeDropdown?: any) {
    this.formAddFinancialType.reset();
    this.formFinancialItem.reset();
    if (datatypeDropdown) datatypeDropdown.reset();
    if (typeDropdown) typeDropdown.reset();

    if (this.Items.controls.length > 0) {
      this.Items.clear();
    }
    this.createFormItem();
    this.createFormType();
    modal.closeModal();
  }

  createFormType() {
    this.formAddFinancialType = this.formBuilder.group({
      FinancialTypeId: [3],
      Description: ["", Validators.required],
      Status: ["I", Validators.required],
    });
  }

  createFormItem() {
    this.formFinancialItem = this.formBuilder.group({
      FinancialItem: this.formBuilder.group({
        PartnerId: [0],
        Id: [0],
        Description: ["", Validators.required],
        DataType: ["", Validators.required],
        MaxSize: [0],
        Required: ["N"],
        Order: [0],
        Type: [""],
        RegEx: [""],
      }),
      FinancialItemList: this.formBuilder.group({
        FinancItem: [0],
        Items: this.formBuilder.array([]),
      }),
    });
  }

  setToggleValue(toggleType: string, index?: number) {
    switch (toggleType) {
      case "Required":
        this.formFinancialItem.patchValue({
          FinancialItem: {
            Required:
              this.formFinancialItem.get("FinancialItem").get("Required")
                .value == "N"
                ? "Y"
                : "N",
          },
        });
        break;
      case "Default":
        this.Items.at(index).patchValue({
          ListDefault:
            this.Items.controls[0]["controls"].ListDefault.value == "N"
              ? "Y"
              : "N",
        });
    }
  }

  get Items() {
    return this.formFinancialItem.controls["FinancialItemList"]["controls"][
      "Items"
    ] as FormArray;
  }

  addItemList() {
    this.Items.push(
      this.formBuilder.group({
        ListValue: ["", Validators.required],
        Description: ["", Validators.required],
        ListOrder: [
          this.Items.controls.length == 0 ? 1 : this.Items.controls.length + 1,
          Validators.required,
        ],
        ListDefault: ["N", Validators.required],
      })
    );
  }

  getFinancialType() {
    this.financialParameter.getFinancialType().subscribe({
      next: (result) => {
        this.financialTypeList = result.Data;
      },
    });
  }

  getFinancialItem() {
    if (this.finType == null) {
      this.shared.warningAlert(
        this.shared.translate("Please, select a financial type!")
      );
      return;
    }
    this.financialParameter.getFinancialItem(this.finType).subscribe({
      next: (result) => {
        document.getElementById("resultItems").style.visibility = "visible";
        document.getElementById("editTypeInformation").style.display =
          "block";
        this.financialItemList = result.Data;
        var financialType = this.financialTypeList.find(
          (f) => f.FinancialTypeId == this.finType
        );

        // this.formAddFinancialType
        //   .get("FinancialTypeId")
        //   .patchValue(financialType.FinancialTypeId);
        // this.formAddFinancialType
        //   .get("Description")
        //   .patchValue(financialType.Description);
        // this.formAddFinancialType
        //   .get("Status")
        //   .patchValue(financialType.Status);
        this.selectedFinancialType.descriptionType = financialType.Description;
        this.selectedFinancialType.statusType = financialType.Status;
        document
          .getElementById("financialTypeStatus")
          ["setValue"](
            financialType.Status,
            financialType.Status == "A" ? "Active" : "Inactive"
          );
        this.getFinancialType();
        if (this.financialItemList.length == 0) {
          this.shared.warningAlert(
            this.shared.translate("No results found!"),
            "sm"
          );
          return;
        }
      },
      error: (error) => {
        this.shared.errorAlert(
          this.shared.translate("Error getting financial items!"),
          error.message
        );
        return;
      },
    });
  }

  getFinancialDataType() {
    this.financialParameter.getFinancialDataType().subscribe({
      next: (result) => {
        this.financialIDatatypeList = result.Data;
      },
    });
  }

  saveFinancialType(modal: any) {
    if (!this.formAddFinancialType.valid) {
      this.shared.errorAlert(
        this.shared.translate("Error saving new financial type!")
      );
      return;
    }
    this.financialParameter
      .saveFinancialType(this.formAddFinancialType.value)
      .subscribe({
        next: () => {
          this.getFinancialType();
          this.shared.successAlert(
            this.shared.translate("Financial type has been saved!")
          );
          modal.closeModal();
        },
        error: (error) => {},
      });
  }

  updateType() {
    this.financialParameter
      .updateType(this.finType, this.selectedFinancialType)
      .subscribe({
        next: () => {
          this.getFinancialType();
          this.shared.successAlert(
            this.shared.translate("Financial type has been updated!")
          );          
        },
        error: (error) => {
          this.shared.errorAlert(
            this.shared.translate("Error editing financial type!")
          );
        },
      });
  }

  setHeaderNames() {
    document.getElementById("id").setAttribute("column-title", "Id");

    document
      .getElementById("desc")
      .setAttribute("column-title", this.shared.translate("Description"));

    document
      .getElementById("dtype")
      .setAttribute("column-title", this.shared.translate("DataType"));

    document
      .getElementById("maxsize")
      .setAttribute("column-title", this.shared.translate("Max. Length"));
    document
      .getElementById("required")
      .setAttribute("column-title", this.shared.translate("Required"));

    document
      .getElementById("edit")
      .setAttribute("column-title", this.shared.translate(""));

    document
      .getElementById("delete")
      .setAttribute("column-title", this.shared.translate(""));
  }

  setTableValues(index: number) {
    document
      .getElementById(`rowId${index}`)
      .setAttribute("cell-value", this.financialItemList[index].Id.toString());

    document
      .getElementById(`rowDescription${index}`)
      .setAttribute("cell-value", this.financialItemList[index].Description);
    document
      .getElementById(`rowDataType${index}`)
      .setAttribute("cell-value", this.financialItemList[index].DataType);
    document
      .getElementById(`rowMaxSize${index}`)
      .setAttribute(
        "cell-value",
        this.financialItemList[index].MaxSize.toString()
      );
    document
      .getElementById(`rowRequired${index}`)
      .setAttribute("cell-value", this.financialItemList[index].Required);

    document.getElementById(`rowEdit${index}`).setAttribute("cell-value", "");

    document.getElementById(`rowDelete${index}`).setAttribute("cell-value", "");
  }

  changeValue(dropdownInfo: string, event: any) {
    switch (dropdownInfo) {
      case "finType":
        this.finType = event.target.value;
        break;
      case "Status":
        this.selectedFinancialType.statusType = event.target.value;
        break;
      case "datatype":
        this.formFinancialItem
          .get("FinancialItem")
          .get("DataType")
          .patchValue(event.target.value);
        if (
          this.formFinancialItem.get("FinancialItem").get("DataType").value ==
            "LIST" &&
          this.Items.controls.length == 0
        ) {
          debugger;
          this.addItemList();
        } else {
          this.Items.clear();
        }
        break;
      case "type":
        this.formFinancialItem
          .get("FinancialItem")
          .get("Type")
          .patchValue(event.target.value);
        break;
    }
  }

  modalEdit(item: any, modal: any) {
    this.requestType = "Put";
    if (item.DataType == "LIST") {
      this.financialParameter
        .listItemList(item.FinancialTypeId, item.Id)
        .subscribe({
          next: (result) => {
            result.Data.forEach((element) => {
              this.Items.push(
                this.formBuilder.group({
                  ListValue: [element.ListValue, Validators.required],
                  Description: [element.Description, Validators.required],
                  ListOrder: [element.Order],
                  ListDefault: [element.Default, Validators.required],
                })
              );
            });
          },
        });
    }
    this.formFinancialItem.patchValue({
      FinancialItem: { Id: item.Id },
    });
    this.formFinancialItem.patchValue({
      FinancialItem: { Description: item.Description },
    });
    this.formFinancialItem.patchValue({
      FinancialItem: { DataType: item.DataType },
    });
    document
      .getElementById("DataType")
      ["setValue"](item.DataType, item.DataType);
    this.formFinancialItem.patchValue({
      FinancialItem: { MaxSize: parseInt(item.MaxSize) },
    });
    this.formFinancialItem.patchValue({
      FinancialItem: { Required: item.Required },
    });
    this.formFinancialItem.patchValue({
      FinancialItem: { Order: parseInt(item.Order) },
    });
    this.formFinancialItem.patchValue({
      FinancialItem: { Type: item.Type },
    });
    document.getElementById("Type")["setValue"](item?.Type, item.Type);
    this.formFinancialItem.patchValue({
      FinancialItem: { RegEx: item.RegEx },
    });

    modal.showModal(this.itemModal);
  }

  saveFinancialItem(modal: any) {
    if (!this.formFinancialItem.valid) {
      this.formFinancialItem.markAllAsTouched();
      this.shared.errorAlert("Please, fill all required fields!");
      return;
    }

    var request;
    if (this.requestType == "Post") {
      request = "saveItem";
    } else if (this.requestType == "Put") {
      request = "updateItem";
    }
    this.formFinancialItem.patchValue({
      FinancialItem: {
        MaxSize: parseInt(
          this.formFinancialItem.get("FinancialItem").get("MaxSize").value
        ),
      },
    });
    this.formFinancialItem.patchValue({
      FinancialItem: {
        Order: parseInt(
          this.formFinancialItem.get("FinancialItem").get("Order").value
        ),
      },
    });
    this.financialParameter[request](
      this.finType,
      this.formFinancialItem.value
    ).subscribe({
      next: (result) => {
        if (this.requestType == "Post") {
          this.getFinancialItem();
          /*this.financialItemList.push(
            this.formFinancialItem.controls["FinancialItem"].value
          );*/
        }
        this.shared.successAlert("Item has been saved!");
        modal.closeModal();
      },
      error: (error) => {
        this.shared.errorAlert(
          "Error saving new financial item!",
          error.message
        );
        return;
      },
    });
  }

  removeItemList(index: number) {
    this.Items.removeAt(index);
  }

  removeItem(finanId: number, itemId: number) {
    this.shared
      .warningOptionAlert("Are you sure you want to remove this item?")
      .then((response) => {
        if (response.isConfirmed) {
          this.financialParameter.removeItem(finanId, itemId).subscribe({
            next: () => {
              this.financialItemList.splice(
                this.financialItemList.findIndex((it) => it.Id == itemId),
                1
              );
              this.shared.successAlert(
                this.shared.translate("Item has been removed sucessfully!")
              );
            },
          });
        }
      });
  }
}
