import { Component, ElementRef, Renderer2 } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { City } from 'src/app/models/city.model';
import { Country } from 'src/app/models/country.model';
import { Partner } from 'src/app/models/partner.model';
import { PartnerAddressType } from 'src/app/models/partneraddresstypes.model';
import { PartnerDocumentType } from 'src/app/models/partnerdocumenttype.model';
import { PartnerListResponse } from 'src/app/models/partnerlistresponse.model';
import { PartnerType } from 'src/app/models/partnertype.model';
import { PersonIdentity } from 'src/app/models/personidentity.model';
import { State } from 'src/app/models/state.model';
import { GeoService } from 'src/app/service/api/geo.service';
import { PartnerService } from 'src/app/service/api/partner.service';
import { SharedService } from 'src/app/service/shared.service';
import { ExchangeRateService } from 'src/app/service/api/exchange-rate.service';
import { ExchangeCurrencyResponse } from 'src/app/models/exchangecurrencyresponse.model';
import { Title } from "@angular/platform-browser";
import { share } from 'rxjs';

@Component({
  selector: 'app-partner-information',
  templateUrl: './partner-information.component.html',
  styleUrls: ['./partner-information.component.css']
})
export class PartnerInformationComponent {

  form: FormGroup;
  partnerInformation: Partner;
  searchStatus: boolean = false;
  isMusysBr: boolean = this.shared.isMusysBR();
  pagination: number = 1;
  Math = Math;

  get f() {
    return this.form.controls;
  }

  get documents() {
    return this.form.get('Documents') as FormArray
  }

  get address() {
    return this.form.controls['Address']['controls']
  }

  requestType: string;

  partnerName: string = '';
  documentType: string = '';
  documentNumber: string = '';
  mainType: string = '';

  documentList: PartnerDocumentType[] = [];
  typeList: PartnerType[] = [];

  partnerList: PartnerListResponse[] = [];
  personIdentityList: PersonIdentity[] = [];
  addressTypeList: PartnerAddressType[] = [];
  currencyList: ExchangeCurrencyResponse[] = [];

  countryList: Country[] = [];
  stateList: State[] = [];
  cityList: City[] = [];


  get marketUnit() {
    return this.cookie.get('Musys.Ui.User.MarketUnitCode.Current')
  }
  constructor(private titleService: Title, public shared: SharedService, private partner: PartnerService, private cookie: CookieService, private geo: GeoService, private element: ElementRef, private renderer: Renderer2, private fb: FormBuilder, private exchange: ExchangeRateService) {
    this.titleService.setTitle("MUSYS - Partner Information");
  }

  ngOnInit() {
    this.createFormGroup();
    this.getDocTypes()
    this.getTypes()
    this.getAddressTypes();
    this.getCountries();
    if (!this.isMusysBr) {
      this.getCurrencies();
    } else {
      this.getPersonIdentities();
    }
  }

  setHeaderNames() {
    document.getElementById('name').setAttribute('column-title', this.shared.translate('Name'));
    document.getElementById('type').setAttribute('column-title', this.shared.translate('Type'));
    document.getElementById('creationDate').setAttribute('column-title', this.shared.translate('Creation Date'));
    document.getElementById('inactiveDate').setAttribute('column-title', this.shared.translate('InactiveDate'));
    document.getElementById('edit').setAttribute('column-title', this.shared.translate(''));
    document.getElementById('delete').setAttribute('column-title', this.shared.translate(''));
  }
  setTableValues(index: number) {
    document.getElementById(`rowName${index}`).setAttribute('cell-value', this.partnerList[index].Name);
    document.getElementById(`rowType${index}`).setAttribute('cell-value', this.partnerList[index].Type);
    document.getElementById(`rowCreationDate${index}`).setAttribute('cell-value', this.partnerList[index].CreateDate ? this.partnerList[index].CreateDate.toString()?.substring(0, 10) : '');
    document.getElementById(`rowInactiveDate${index}`).setAttribute('cell-value', this.partnerList[index].InactiveDate ? this.partnerList[index].InactiveDate.toString()?.substring(0, 10) : '');
    document.getElementById(`rowEdit${index}`).setAttribute('cell-value', '');
    document.getElementById(`rowDelete${index}`).setAttribute('cell-value', '');
  }

  sim(event: any){
    var x =event;
    debugger
  }

  closeModal(modal: any) {
    modal.closeModal();
    this.createFormGroup();
    this.documents.clear();
    if (!this.shared.isMusysBR) {
      this.stateList = [];
      this.address.StateCode.disable()
    } else {
      this.address.CountryCode.patchValue('BR');
      this.address.CountryName.patchValue('BRASIL');
    }
    this.cityList = [];
    this.address.CityCode.disable();

    var dropdowns = document.getElementsByTagName("tds-dropdown");
    for (let i = 0; i < dropdowns.length; i++) {
      dropdowns[i].reset();
    }

   document.getElementById('city').setAttribute('placeholder', this.shared.translate('Select'));
   document.getElementById('state').setAttribute('placeholder', this.shared.translate('Select'));
  }

  createFormGroup() {
    this.form = this.fb.group({
      Id: [0],
      Name: ['', Validators.required],
      Type: ['', Validators.required],
      CreatedAt: [{ value: new Date().toISOString().substring(0, 10), disabled: true }, Validators.required],
      InactiveDate: [new Date()],
      Address: this.fb.group({
        Description: ['', Validators.required],
        Complement: [''],
        PostalCode: [''],
        CityCode: [{ value: '', disabled: true }, Validators.required],
        CityName: ['', Validators.required],
        StateCode: [{ value: '', disabled: true }, Validators.required],
        StateName: ['', Validators.required],
        CountryCode: ['', Validators.required],
        CountryName: ['', Validators.required],
        Neighborhood: [''],
        Phone: [''],
        EmailAddress: [''],
        Type: [null, Validators.required],
      }),
      Documents: this.fb.array([]),
      Zone: [''],

      CaptiveDealer: [false, Validators.required],
      IsCustomerBr: [false, Validators.required],
    })
    if (!this.isMusysBr) {
      this.form.addControl('Currency', this.fb.group({
        Id: [null, Validators.required],
        Name: [null, Validators.required],
        IsoCode: [null],
        Symbol: [null, Validators.required],
        IsDefault: [false],
      }),);
      this.form.addControl('LocalCode', new FormControl('', Validators.required));
    } else {
      this.form.addControl('PersonIdentity', new FormControl('', Validators.required));
    }
  }

  setDropdown(event: any, index?: number) {
    var control = event.currentTarget.attributes.formcontrolname.value;
    this.f[control].patchValue(event.target.value);
  }

  setCurrency(event: any) {
    var currency = this.currencyList.find(curr => curr.Id == event.target.value);
    this.form.patchValue({ Currency: { Id: currency.Id } });
    this.form.patchValue({ Currency: { Name: currency.Description } });
    this.form.patchValue({ Currency: { Symbol: currency.IsoSymbol } });
  }

  addDocument() {
    this.documents.push(this.fb.group({
      Type: ['', Validators.required],
      Number: ['', Validators.required],
      ExpirationDate: [null],
    }));
  }

  setDocument(event: any, index: number) {
    var control = event.currentTarget.attributes.formcontrolname.value;
    switch (control) {
      case "Type":
        this.documents.at(index).patchValue({ Type: event.target.value });
        break
      case "Number":
        this.documents.at(index).patchValue({ Number: event.target.value });
        this.documents.controls[index]['controls'].control.markAsTouched();
        break
    }
  }

  removeDocument(index: number) {
    this.documents.removeAt(index);
  }

  setAddressType(event: any) {
    this.f['Address'].patchValue({ Type: parseInt(event.target.value) })
  }

  getDocTypes() {
    this.partner.listPartnerDocumentTypes().subscribe({
      next: (result) => {
        this.documentList = result.Data;
      }
    })
  }

  getTypes() {
    this.partner.ListPartnerTypes().subscribe({
      next: (result) => {
        this.typeList = result.Data;
      }
    })
  }

  getCurrencies() {
    this.exchange.getExchangeCurrencies().subscribe({
      next: (result) => {
        this.currencyList = Object.assign(result.Data)
      }
    })
  }

  getPersonIdentities() {
    this.partner.listPersonIdentites().subscribe({
      next: (result) => {
        this.personIdentityList = result.Data;
      }
    })
  }

  getAddressTypes() {
    this.partner.listPartnerAddressTypes().subscribe({
      next: (result) => {
        this.addressTypeList = result.Data;
      }
    })
  }

  getCountries() {
    this.stateList = []
    this.geo.listCountries().subscribe({
      next: (result) => {
        this.countryList = result.Data;
        document.getElementById('state').setAttribute('placeholder', this.shared.translate('Select'));
        if (this.isMusysBr) {
          const drop = this.element.nativeElement.querySelector('#country');

          this.renderer.setAttribute(drop, 'placeholder', 'BRASIL');
          this.renderer.setAttribute(drop, 'disabled', 'true');

          this.form.patchValue({ Address: { CountryCode: 'BR' } });
          this.form.patchValue({ Address: { CountryName: 'BRASIL' } });

          this.getStates();
        }
      }
    })
  }

  getStates(event?: any,) {
    this.cityList = []
    if (event) {
      this.form.patchValue({ Address: { CountryCode: event.target.value } });
      this.form.patchValue({ Address: { CountryName: event.target.innerText } });
      document.getElementById('state').setAttribute('placeholder', this.shared.translate('Select'));
    }
    this.geo.listStates(this.f['Address'].get('CountryCode').value).subscribe({
      next: (result) => {
        this.stateList = result.Data;
        this.address['StateCode'].enable();
      }
    })
  }

  getCities(event: any, city: any) {
    this.form.patchValue({ Address: { StateCode: event.target.value } });
    this.form.patchValue({ Address: { StateName: event.target.innerText } });

    this.geo.listCities(this.f['Address'].get('CountryCode').value, event.target.value).subscribe({
      next: (result) => {
        this.cityList = result.Data;
        this.address['CityCode'].enable();
        document.getElementById('city').setAttribute('placeholder', this.shared.translate('Select'));
        city.reset();
      }
    })
  }

  setCity(event: any) {
    this.form.patchValue({ Address: { CityCode: parseInt(event.target.value) } });
    this.form.patchValue({ Address: { CityName: event.target.innerText } });
  }

  changeValue(variable: string, event: any) {
    switch (variable) {
      case 'documentType':
        this.documentType = event.target.value;
        break
      case 'mainType':
        this.mainType = event.target.value;
        break
      case 'documentNumber':
        this.documentNumber = event.target.value;
        break
      case 'partnerName':
        this.partnerName = event.target.value;
        break
    }
  }

  search() {

    if (this.partnerName == '' && this.documentNumber == '') {
      this.shared.warningAlert('Please, fill partner name or document type and number!')
      return;
    }

    var request = {
      PartnerName: this.partnerName,
      DocumentType: this.documentType,
      documentNumber: this.documentNumber,
      PartnerType: this.mainType
    }
    this.searchStatus = true;
    this.shared.loading(this.searchStatus);

    this.partner.list(request).subscribe({
      next: (result) => {
        if (result.Data.length == 0) {
          this.shared.warningAlert('No results found!');
        }else {
          this.partnerList = result.Data;
        }
        this.partnerList = result.Data;
      },
      error: (error) => {
        this.shared.errorAlert(error.message)
      },
      complete: () => {
        this.searchStatus = false;
        this.shared.loading(this.searchStatus);
      }
    })
  }

  clearInputs(partnerName: any, docType: any, docNumber: any, mainType: any) {
    this.partnerName = '';
    this.documentType = '';
    this.documentNumber = '';
    this.mainType = '';

    partnerName.value = '';
    docType.reset();
    docNumber.value = '';
    mainType.reset();

    this.partnerList = [];
  }

  editPartner(id: number, modal: any) {
    this.partner.getPartnerInformation(id).subscribe({
      next: (result) => {
        this.partnerInformation = result.Data;
        if (result.Data.Address) {
          this.geo.listStates(result.Data.Address.CountryCode).subscribe({
            next: (stateResult) => {
              this.stateList = stateResult.Data;
              document.getElementById('state')['setValue'](result.Data.Address?.StateCode, result.Data.Address?.StateName);

              this.geo.listCities(result.Data.Address.CountryCode, result.Data.Address?.StateCode).subscribe({
                next: (cityResult) => {
                  this.cityList = cityResult.Data;
                  document.getElementById('city')['setValue'](result.Data.Address?.CityCode.toString(), result.Data.Address?.CityName);
                }
              })
            }
          })
        }



        this.requestType = 'PUT';
        this.form.patchValue({ Id: id || null });
        this.form.patchValue({ Code: result.Data.Code || null });
        this.form.patchValue({ LocalCode: result.Data.LocalCode || null });
        this.form.patchValue({ Name: result.Data.Name || null });
        this.form.patchValue({ Type: result.Data.Type || null });
        var type = this.typeList.find(t => t.Type == result.Data.Type);
        document.getElementById('mainType')['setValue'](type.Type, type.Description);
        if (this.isMusysBr) {
          this.form.patchValue({ PersonIdentity: result.Data.PersonIdentity || null });
          document.getElementById('personIdentity')['setValue'](result.Data.PersonIdentity, result.Data.PersonIdentity == 'J' ? 'JURIDICA' : 'FISICA');
        }
        if (result.Data.CreatedAt) var createdAt = new Date(result.Data.CreatedAt).toISOString().substring(0, 10);
        this.form.patchValue({ CreatedAt: createdAt || null });
        if (result.Data.InactiveDate) var inactiveDate = new Date(result.Data.InactiveDate).toISOString().substring(0, 10);
        this.form.patchValue({ InactiveDate: inactiveDate || null });

        this.form.patchValue({ Address: { Description: result.Data.Address?.Description || null } });
        this.form.patchValue({ Address: { Complement: result.Data.Address?.Complement || null } });
        this.form.patchValue({ Address: { PostalCode: result.Data.Address?.PostalCode || null } });
        this.form.patchValue({ Address: { CityCode: result.Data.Address?.CityCode || null } });
        this.form.patchValue({ Address: { CityName: result.Data.Address?.CityName || null } });
        if (result.Data.Address) {
          this.address.CityCode.enable();
          this.address.StateCode.enable();
        }

        this.form.patchValue({ Address: { StateCode: result.Data.Address?.StateCode || null } });
        this.form.patchValue({ Address: { StateName: result.Data.Address?.StateName || null } });

        this.form.patchValue({ Address: { CountryCode: result.Data.Address?.CountryCode || null } });
        document.getElementById('country')['setValue'](result.Data.Address?.CountryCode, result.Data.Address?.CountryName);
        this.form.patchValue({ Address: { CountryName: result.Data.Address?.CountryName || null } });
        this.form.patchValue({ Address: { Neighborhood: result.Data.Address?.Neighborhood || null } });
        this.form.patchValue({ Address: { Phone: result.Data.Address?.Phone || null } });
        this.form.patchValue({ Address: { EmailAddress: result.Data.Address?.EmailAddress || null } });
        this.form.patchValue({ Address: { Type: result.Data.Address?.Type || null } });

        document.getElementById('addressType')['setValue'](result.Data.Address?.Type.toString(), 'Commercial');

        result.Data.Documents.forEach(doc => {
          this.documents.push(this.fb.group({
            Type: [doc.Type, Validators.required],
            Number: [doc.Number, Validators.required],
            ExpirationDate: [doc.ExpirationDate],
          }));
        })
        this.form.patchValue({ Zone: result.Data.Zone || null });
        this.form.patchValue({ Currency: { Id: result.Data.Currency?.Id || null } });
        this.form.patchValue({ Currency: { Name: result.Data.Currency?.Name || null } });
        this.form.patchValue({ Currency: { IsoCode: result.Data.Currency?.IsoCode || null } });
        this.form.patchValue({ Currency: { Symbol: result.Data.Currency?.Symbol || null } });
        this.form.patchValue({ Currency: { IsDefault: result.Data.Currency?.IsDefault || false } });
        if (!this.isMusysBr) {
          document.getElementById('currency')['setValue'](result.Data.Currency?.Id, result.Data.Currency?.Name);
        }
        this.form.patchValue({ CaptiveDealer: result.Data.CaptiveDealer || false });
        this.form.patchValue({ IsCustomerBr: result.Data.IsCustomerBr || false });

        modal.showModal();console.log(this.form)
      }
    })
  }

  removePartner(id: number, IsCustomerBr: boolean) {
    this.shared.warningOptionAlert(this.shared.translate('Please, confirm partner removal!')).then(
      (response) => {
        if (response.isConfirmed) {
          this.partner.remove(id, IsCustomerBr).subscribe({
            next: () => {
              this.partnerList.splice(this.partnerList.findIndex(p => p.Id == id), 1);
              this.shared.successAlert(this.shared.translate("Partner has been removed successfully"))
            }
          })
        }
      });
  }

  savePartner(modal: any) {
    if (this.f['IsCustomerBr'].value != true) this.f['IsCustomerBr'].patchValue(false)
    if (this.f['CaptiveDealer'].value != true) this.f['CaptiveDealer'].patchValue(false)

    if (!this.form.valid) {
      this.form.markAllAsTouched()
      console.log(this.form.controls)
      this.shared.errorAlert(this.shared.translate("Please, fill all required fields!"))
      return
    }

    var request = this.requestType == 'PUT' ? 'update' : 'create';

    if (this.requestType == 'POST') this.f['Id'].disable();
    this.f['CreatedAt'].enable()
    this.f['CreatedAt'].patchValue(new Date());

    var inactiveDate = this.f['InactiveDate']?.value ? new Date(this.f['InactiveDate'].value).toLocaleDateString() : null;
    var today = new Date().toLocaleDateString();

    if (inactiveDate == today) {
      this.f['InactiveDate'].patchValue(null)
    }

    this.partner[request](this.form.value).subscribe({
      next: () => {
        this.closeModal(modal);
        this.shared.successAlert(`Partner ${request == 'update' ? 'updated' : 'created'} Successfully!`);
      }
    })
  }
}
