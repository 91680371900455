import { Pipe, PipeTransform } from '@angular/core';
import { Variants } from '../models/variants.model';

@Pipe({
  name: 'filterVariants'
})
export class FilterVariantsPipe implements PipeTransform {

  transform(items: Variants[], filter: string): any[] {
    if (!items || !filter) {
      return items;
    }
    
    return items.filter( item => item.Code.toUpperCase().includes(filter.toUpperCase()) || (item.Description && item.Description.toUpperCase().includes(filter.toUpperCase())));
  }

}
