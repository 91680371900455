<div class="tds-container">
    <tds-block>
        <div class="tds-row">
            <div class="tds-col-md-11">
                <h3>Market Unit Information</h3>
            </div>
            <div class="tds-col-md-1 tds-col-xs-12 align-center justify-end">
                <tds-button text="{{shared.translate('New')}}" size="sm" (click)="openModal('POST', marketUnitModal)"
                    class=""></tds-button>
            </div>
        </div>
        <tds-block>
            <div class="tds-row tds-u-mt2" style="position: relative;">
                <tds-table table-id="pagination-table" no-min-width vertical-dividers="false" compact-design="true"
                    responsive="true">
                    <tds-table-header>
                        <tds-header-cell id="partnerId" column-title="{{setHeaderNames()}}"></tds-header-cell>
                        <tds-header-cell id="partnerName" column-title=""></tds-header-cell>
                        <tds-header-cell id="localCode" column-title=""></tds-header-cell>
                        <tds-header-cell id="edit" column-title=""></tds-header-cell>
                    </tds-table-header>

                    <tds-table-body-row>
                        <tds-body-cell id="rowPartnerId"></tds-body-cell>
                        <tds-body-cell id="rowPartnerName"></tds-body-cell>
                        <tds-body-cell id="rowLocalCode"></tds-body-cell>
                        <tds-body-cell id="rowEdit" cell-value="{{setTableValues()}}">
                            <img (click)="editMarket(marketUnitModal)" class="marketUnitModal"
                                style="width: 25px; margin-left: 10px; cursor: pointer"
                                src="../../../../../assets/images/edit.svg" id="EditPartner">

                            <tds-tooltip placement="bottom" selector="#EditPartner" text="Edit"
                                mouse-over-tooltip="true"></tds-tooltip>
                        </tds-body-cell>
                    </tds-table-body-row>
                </tds-table>
            </div>
        </tds-block>
    </tds-block>
</div>


<tds-modal #marketUnitModal id="marketUnitModal" size="md" selector=".marketUnitModal" actions-position="sticky">
    <span slot="body">
        <form [formGroup]="form">
            <tds-block>
                <tds-block>
                    <div>
                        <div>
                            <div class="tds-row">
                                <div class="tds-col-md-10 tds-u-flex">
                                    <img style="width: 30px;" src="../../../../../assets/images/user.svg">
                                    <h6>{{shared.translate("General Information")}}</h6>
                                </div>
                            </div>
                        </div>
                        <div class="tds-card-body">
                            <div class="tds-row">
                                <div class="tds-col-lg-4 tds-col-md-6">
                                    <div>
                                        <tds-text-field read-only ngDefaultControl formControlName="Id" size="sm"
                                            no-min-width placeholder="{{shared.translate('Id')}}"
                                            label="{{shared.translate('Id')}}" label-position="outside">
                                        </tds-text-field>
                                    </div>
                                </div>

                                <div class="tds-col-lg-8 tds-col-xs-12">
                                    <div>
                                        <tds-text-field ngDefaultControl formControlName="Name" size="sm" no-min-width
                                            placeholder="{{shared.translate('Name')}}"
                                            (focusout)="f['Name'].markAsTouched()" label="{{shared.translate('Name')}}"
                                            label-position="outside"
                                            state="{{f['Name'].touched && f['Name'].errors ? 'error' : ''}}"
                                            helper="{{f['Name'].touched && f['Name'].errors ? shared.translate('Required Field.') : ''}}">
                                        </tds-text-field>
                                    </div>
                                </div>
                            </div>
                            <div class="tds-row">
                                <div class="tds-col-lg-6 tds-col-md-6 tds-col-xs-12">
                                    <tds-datetime ngDefaultControl formControlName="CreatedAt" no-min-width type="date"
                                        size="sm" id="createdAt" label="{{shared.translate('Creation Date')}}">
                                    </tds-datetime>
                                </div>
                                <div class="tds-col-lg-6 tds-col-xxlg-6 tds-col-xs-12">
                                    <tds-datetime no-min-width type="date" size="sm" id="disabledAt"
                                        label="{{shared.translate('Inactive Date')}}">
                                    </tds-datetime>
                                </div>
                            </div>

                            <div class="tds-row">
                                <div class="tds-col-md-6 tds-col-xxlg-6 tds-col-xs-12">
                                    <div>
                                        <tds-dropdown open-direction="down" style="z-index: 102;" ngDefaultControl
                                            formControlName="Type" (tdsSelect)="setDropdown($event)" id="mainType"
                                            ngDefaultControl size="sm" placeholder="{{shared.translate('Main Type')}}"
                                            label="{{shared.translate('Main Type')}}" label-position="outside"
                                            [error]="f['Type'].touched && f['Type'].errors"
                                            helper="{{f['Type'].touched && f['Type'].errors ? shared.translate('Selection is required.') : ''}}">
                                            <tds-dropdown-option *ngFor="let type of typeList"
                                                value="{{type.PartnerType}}">{{type.PartnerType}}</tds-dropdown-option>
                                        </tds-dropdown>
                                    </div>
                                </div>
                                <div class="tds-col-md-6 tds-col-xxlg-6 tds-col-xs-12  ">
                                    <tds-dropdown open-direction="down" style="z-index: 101;" ngDefaultControl
                                        formControlName="CurrencyCode" id="currencyCode" size="sm"
                                        (tdsSelect)="setDropdown($event)" placeholder="{{shared.translate('Currency')}}"
                                        label="{{shared.translate('Currency')}}" label-position="outside"
                                        [error]="f['CurrencyCode'].touched && f['CurrencyCode'].errors"
                                        helper="{{f['CurrencyCode'].touched && f['CurrencyCode'].errors ? shared.translate('Selection is required.') : ''}}">
                                        <tds-dropdown-option *ngFor="let currency of currencyList"
                                            value="{{currency.Id}}">{{currency.Description}}</tds-dropdown-option>
                                    </tds-dropdown>
                                </div>
                                <div class="tds-col-md-6 tds-col-xxlg-6 tds-col-xs-12  ">
                                    <tds-dropdown open-direction="down" style="z-index: 100;" ngDefaultControl
                                        formControlName="ParentId" id="parentId" size="sm"
                                        (tdsSelect)="setDropdown($event)" placeholder="{{shared.translate('Parent')}}"
                                        label="{{shared.translate('Parent')}}" label-position="outside"
                                        [error]="f['ParentId'].touched && f['ParentId'].errors"
                                        helper="{{f['ParentId'].touched && f['ParentId'].errors ? shared.translate('Selection is required.') : ''}}">
                                        <tds-dropdown-option *ngFor="let marketParent of marketParentList"
                                            value="{{marketParent.Id}}">{{marketParent.Name}}</tds-dropdown-option>
                                    </tds-dropdown>
                                </div>
                            </div>
                            <div class="tds-row">
                                <div class="tds-col-md-6 tds-col-xxlg-6 tds-col-xs-12">
                                    <div>
                                        <tds-text-field ngDefaultControl formControlName="LocalCode" size="sm"
                                            no-min-width placeholder="{{shared.translate('Local Code')}}"
                                            (focusout)="f['LocalCode'].markAsTouched()"
                                            label="{{shared.translate('Local Code')}}" label-position="outside"
                                            state="{{f['LocalCode'].touched && f['LocalCode'].errors ? 'error' : ''}}"
                                            helper="{{f['LocalCode'].touched && f['LocalCode'].errors ? shared.translate('Required Field.') : ''}}">
                                        </tds-text-field>
                                    </div>
                                </div>
                                <div class="tds-col-md-6 tds-col-xxlg-6 tds-col-xs-12">
                                    <div>
                                        <tds-text-field ngDefaultControl formControlName="CalendarSpec" size="sm"
                                            type="number" no-min-width
                                            placeholder="{{shared.translate('Calendar Spec')}}"
                                            (focusout)="f['CalendarSpec'].markAsTouched()"
                                            label="{{shared.translate('Calendar Spec')}}" label-position="outside"
                                            state="{{f['CalendarSpec'].touched && f['CalendarSpec'].errors ? 'error' : ''}}"
                                            helper="{{f['CalendarSpec'].touched && f['CalendarSpec'].errors ? shared.translate('Required Field') : ''}}">
                                        </tds-text-field>
                                    </div>
                                </div>
                            </div>
                            <div class="tds-row">
                                <div class="tds-col-md-6 tds-col-xxlg-6 tds-col-xs-12">
                                    <div>
                                        <tds-text-field ngDefaultControl formControlName="DefaultDealerCode" size="sm"
                                            no-min-width placeholder="{{shared.translate('Default Dealer Code')}}"
                                            (focusout)="f['DefaultDealerCode'].markAsTouched()"
                                            label="{{shared.translate('Default Dealer Code')}}" label-position="outside"
                                            state="{{f['DefaultDealerCode'].touched && f['DefaultDealerCode'].errors ? 'error' : ''}}"
                                            helper="{{f['DefaultDealerCode'].touched && f['DefaultDealerCode'].errors ? shared.translate('Required Field') : ''}}">
                                        </tds-text-field>
                                    </div>
                                </div>
                                <div class="tds-col-md-6 tds-col-xxlg-6 tds-col-xs-12">
                                    <div>
                                        <tds-text-field ngDefaultControl formControlName="DefaultCustomerCode" size="sm"
                                            no-min-width placeholder="{{shared.translate('Default Customer Code')}}"
                                            (focusout)="f['DefaultCustomerCode'].markAsTouched()"
                                            label="{{shared.translate('Default Customer Code')}}"
                                            label-position="outside"
                                            state="{{f['DefaultCustomerCode'].touched && f['DefaultCustomerCode'].errors ? 'error' : ''}}"
                                            helper="{{f['DefaultCustomerCode'].touched && f['DefaultCustomerCode'].errors ? shared.translate('Required Field') : ''}}">
                                        </tds-text-field>
                                    </div>
                                </div>
                                <div class="tds-col-md-6 tds-col-xxlg-6 tds-col-xs-12 check-align">
                                    <div class="tds-checkbox-item">
                                        <input ngDefaultControl formControlName="CaptiveDealer" class="tds-form-input"
                                            type="checkbox" name="" id="captiveDealer">
                                        <label class="tds-form-label" for="">
                                            {{shared.translate('Captive Dealer')}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tds-block>
            </tds-block>
        </form>
    </span>

    <span slot="actions">
        <tds-button text="{{shared.translate('Save')}}" (click)="saveMarketUnit()"></tds-button>
        <tds-button class="tds-u-ml1" text="{{shared.translate('Cancel')}}" (click)="closeModal()" type="danger"
            variant="danger"></tds-button>
    </span>
</tds-modal>