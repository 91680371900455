import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { musys } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FinancialTypeRequest } from 'src/app/models/financialtyperequest.model';
import { Result } from 'src/app/models/result.model';
import { FinancialItemRequest } from 'src/app/models/financialitemrequest.model';
import { FinancialDataType } from 'src/app/models/financialdatatype.model';

@Injectable({
  providedIn: 'root',
})
export class FinancialParametersService {
  constructor(private http: HttpClient) {}

  getFinancialType(): Observable<Result<FinancialTypeRequest[]>> {
    return this.http.get<Result<FinancialTypeRequest[]>>(
      musys.api + '/financial-parameter/financial-types'
    );
  }

  getFinancialItem(id: number): Observable<Result<FinancialItemRequest[]>> {
    return this.http.get<Result<FinancialItemRequest[]>>(
      musys.api + `/financial-parameter/${id}/items`
    );
  }

  getFinancialDataType(): Observable<Result<FinancialDataType[]>> {
    return this.http.get<Result<FinancialDataType[]>>(
      musys.api + '/financial-parameter/datatypes'
    );
  }

  saveFinancialType(financialType: FinancialTypeRequest) {
    return this.http.post(
      musys.api + '/financial-parameter/financial-type',
      financialType,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }

  updateType(id: number, financialType: FinancialTypeRequest) {
    return this.http.put(
      musys.api + `/financial-parameter/${id}/financial-type`,
      financialType,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
    /* o coringa coringou por muito menos*/
  }

  saveItem(id: number, item: FinancialItemRequest) {
    return this.http.post(
      musys.api + `/financial-parameter/${id}/financial-item`,
      item
    );
  }

  updateItem(finType: number, item: any) {
    return this.http.put(
      musys.api +
        `/financial-parameter/${finType}/financial-item/${item.FinancialItem.Id}`,
      item
    );
  }

  removeItem(financialTypeId: number, financialItemId: number) {
    return this.http.delete(
      musys.api +
        `/financial-parameter/${financialTypeId}/financial-item/${financialItemId}`
    );
  }

  listItemList(financialTypeId: number, Id: number): Observable<Result<any[]>> {
    return this.http.get<Result<any[]>>(
      musys.api +
        `/financial-parameter/${financialTypeId}/financial-item/${Id}/items`
    );
  }
}
