<div class="tds-container">
  <tds-block>
    <div class="tds-row wrap-adjust">
      <div class="tds-col-md-8 sdds-col-sm-8 sdds-col-xs-8">
        <h2 class="tds-headline-02">
          {{ this.shared.translate("Financial Parameters") }}
        </h2>
      </div>
      <div class="tds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 align-start">
        <tds-button
          type="button"
          variant="secondary"
          size="sm"
          class="tds-u-mr1"
          text="{{ shared.translate('Clear') }}"
          (click)="clear()"
        ></tds-button>
        <tds-button
          type="button"
          variant="primary"
          size="sm"
          id="addFinType"
          text="{{ shared.translate('New') }}"
          (click)="openModal(addFinType, 'Post')"
        ></tds-button>
      </div>
    </div>

    <tds-block>
      <div class="tds-row">
        <div class="tds-col-max-10 tds-col-md-10 tds-col-sm-10 tds-col-xs-10">
          <tds-dropdown
            label="Financial Type"
            label-position="outside"
            placeholder="Select"
            size="sm"
            id="fintypeDropdown"
            name="dropdown"
            (tdsSelect)="changeValue('finType', $event)"
            open-direction="down"
            style="z-index: 101"
          >
            <tds-dropdown-option
              value="{{ typeList.FinancialTypeId }}"
              *ngFor="let typeList of financialTypeList"
              #typeList
              >{{ typeList.Description }}</tds-dropdown-option
            >
          </tds-dropdown>
        </div>
        <div
          class="tds-col-max-2 tds-col-md-2 tds-col-sm-2 tds-col-xs-2 align-items"
        >
          <tds-button
            type="button"
            variant="secondary"
            size="sm"
            text="{{ shared.translate('Open') }}"
            (click)="getFinancialItem()"
          ></tds-button>
        </div>
      </div>

      <div class="edit-container tds-u-mt2" id="editTypeInformation">
        <h4 class="tds-headline-04">
          {{ shared.translate("Edit financial type information") }}
        </h4>

        <div class="tds-row">
          <div class="tds-col-md-4 tds-col-max-4 tds-col-sm-4 tds-col-xs-4">
            <div class="align-box">
              <tds-text-field
                [(ngModel)]="selectedFinancialType.descriptionType"
                type="Text"
                size="sm"
                state="default"
                label="{{ shared.translate('Name') }}"
                label-position="outside"
                no-min-width
                placeholder="Placeholder"
                ngDefaultControl
              ></tds-text-field>
            </div>
          </div>

          <div class="tds-col-md-4 tds-col-sm-4 tds-col-xs-4">
            <tds-dropdown
              style="z-index: 100"
              id="financialTypeStatus"
              name="dropdown"
              label="{{ shared.translate('Status') }}"
              label-position="outside"
              placeholder="Status"
              size="sm"
              #statusEdit
              open-direction="down"
              ngDefaultControl
              (tdsSelect)="changeValue('Status', $event)"
            >
              <tds-dropdown-option value="A">{{
                shared.translate("Active")
              }}</tds-dropdown-option>
              <tds-dropdown-option value="I">{{
                shared.translate("Inactive")
              }}</tds-dropdown-option>
            </tds-dropdown>
          </div>

          <div class="tds-col-md-2 tds-col-sm-2 tds-col-xs-2 align-items">
            <tds-button
              type="button"
              variant="primary"
              size="sm"
              text="{{ shared.translate('Save') }}"
              (click)="updateType()"
              style="align-items: center"
            ></tds-button>
          </div>
        </div>
      </div>
    </tds-block>
  </tds-block>

  <!-- Resultados -->

  <div id="resultItems">
    <tds-block class="tds-u-mt2">
      <div class="tds-row wrap-adjust">
        <div class="tds-col-md-8 sdds-col-sm-8 sdds-col-xs-8">
          <h4 class="tds-headline-04">
            {{ this.shared.translate("Financial items") }}
          </h4>
        </div>
        <div class="tds-col-md-4 sdds-col-sm-4 sdds-col-xs-4 align-start">
          <tds-button
            type="button"
            variant="secondary"
            size="sm"
            class="itemModal"
            text="{{ shared.translate('New') }}"
            (click)="openModal(itemModal, 'Post')"
          >
          </tds-button>
        </div>
      </div>
      <tds-block>
        <div class="tds-row" *ngIf="financialItemList.length > 0">
          <div class="tds-col-max-10 tds-col-md-10 tds-col-sm-10 tds-col-xs-10">
            <tds-table
              class="overflow"
              vertical-dividers="false"
              compact-design="true"
              responsive="true"
              no-min-width
            >
              <tds-table-header>
                <tds-header-cell
                  id="id"
                  column-title="{{ setHeaderNames() }}"
                ></tds-header-cell>
                <tds-header-cell id="desc" column-title=""></tds-header-cell>
                <tds-header-cell id="dtype" column-title=""></tds-header-cell>
                <tds-header-cell
                  id="required"
                  column-title=""
                ></tds-header-cell>
                <tds-header-cell id="maxsize" column-title=""></tds-header-cell>
                <tds-header-cell id="edit" column-title=""></tds-header-cell>
                <tds-header-cell id="delete" column-title=""></tds-header-cell>
              </tds-table-header>
              <tds-table-body>
                <tds-table-body-row
                  *ngFor="let item of financialItemList; let i = index"
                >
                  <tds-body-cell id="rowId{{ i }}"></tds-body-cell>
                  <tds-body-cell id="rowDescription{{ i }}"></tds-body-cell>
                  <tds-body-cell id="rowDataType{{ i }}"></tds-body-cell>
                  <tds-body-cell id="rowRequired{{ i }}"></tds-body-cell>
                  <tds-body-cell id="rowMaxSize{{ i }}"></tds-body-cell>
                  <tds-body-cell id="rowEdit{{ i }}">
                    <img
                      class="tableButtons"
                      (click)="modalEdit(item, itemModal)"
                      id="editFinancialItem{{ i }}"
                      src="../../../../../assets/images/edit.svg"
                    />
                    <tds-tooltip
                      placement="bottom"
                      selector="#editFinancialItem{{ i }}"
                      text="{{ shared.translate('Edit') }}"
                      mouse-over-tooltip="true"
                    />
                  </tds-body-cell>
                  <tds-body-cell
                    id="rowDelete{{ i }}"
                    cell-value="{{ setTableValues(i) }}"
                  >
                    <img
                      class="tableButtons"
                      (click)="removeItem(finType, item.Id)"
                      src="../../../../../assets/images/trash.svg"
                      id="removeFinancialItem{{ i }}"
                    />
                    <tds-tooltip
                      placement="bottom"
                      selector="#removeFinancialItem{{ i }}"
                      text="{{ shared.translate('Delete') }}"
                      mouse-over-tooltip="true"
                    />
                  </tds-body-cell>
                </tds-table-body-row>
              </tds-table-body>
            </tds-table>
          </div>
        </div>
      </tds-block>
    </tds-block>
  </div>
  <!-- Partes dos modais agora -->

  <!-- Modal de novo financial type -->

  <tds-modal
    (tdsClose)="closeModal(addFinType, '', '')"
    #addFinType
    header="{{ shared.translate('Create financial type') }}"
    selector=".addFinType"
    id="addFinType"
    size="sm"
    actions-position="static"
  >
    <span slot="body">
      <tds-block>
        <tds-block>
          <h3 class="tds-headline-05">
            {{ shared.translate("Financial type information") }}
          </h3>
          <form [formGroup]="formAddFinancialType">
            <div class="tds-row">
              <div class="tds-col-md-6 td-col-max-6 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="Description"
                  type="Text"
                  size="md"
                  state="{{
                    this.formAddFinancialType.controls['Description'].touched &&
                    this.formAddFinancialType.controls['Description'].errors
                      ? 'error'
                      : ''
                  }}"
                  label="{{ shared.translate('Description') }}"
                  label-position="outside"
                  helper="{{
                    this.formAddFinancialType.controls['Description'].touched &&
                    this.formAddFinancialType.controls['Description'].errors
                      ? 'Required field'
                      : ''
                  }}"
                  no-min-width
                ></tds-text-field>
              </div>
              <div class="tds-col-md-6 tds-col-sm-6 tds-col-xs-6">
                <tds-dropdown
                  #Status
                  name="dropdown"
                  label="{{ shared.translate('Status') }}"
                  label-position="outside"
                  placeholder="{{ shared.translate('Select') }}"
                  size="md"
                  open-direction="down"
                  ngDefaultControl
                  formControlName="Status"
                  (tdsSelect)="changeValue('Status', $event)"
                  slot="body"
                >
                  <tds-dropdown-option value="A">{{
                    shared.translate("Active")
                  }}</tds-dropdown-option>
                  <tds-dropdown-option value="I">{{
                    shared.translate("Inactive")
                  }}</tds-dropdown-option>
                </tds-dropdown>
              </div>
            </div>
          </form>
        </tds-block>
      </tds-block>
    </span>
    <span slot="actions">
      <div class="tds-row">
        <div class="tds-col-sm-6 tds-col-lg-6 tds-col-md-6 tds-col-xs-6">
          <tds-button
            type="button"
            variant="danger"
            size="sm"
            data-dismiss-modal
            text="{{ shared.translate('Cancel') }}"
            (click)="closeModal(addFinType, '', '')"
          ></tds-button>
        </div>
        <div class="tds-col-sm-6">
          <tds-button
            type="button"
            variant="primary"
            size="sm"
            text="{{ shared.translate('Save') }}"
            (click)="saveFinancialType(addFinType)"
          ></tds-button>
        </div>
      </div>
    </span>
  </tds-modal>

  <!-- Modal financial item -->

  <tds-modal
    (tdsClose)="closeModal(itemModal, datatype, type)"
    id="itemModal"
    #itemModal
    size="md"
    header="{{
      this.requestType == 'Put'
        ? 'Edit financial item'
        : 'Create financial item'
    }}"
    actions-position="sticky"
  >
    <span slot="body">
      <form [formGroup]="formFinancialItem">
        <tds-block>
          <tds-block formGroupName="FinancialItem">
            <h3 class="tds-headline-05">
              {{ shared.translate("Financial item") }}
            </h3>
            <div class="tds-row">
              <div
                class="tds-col-lg-12 tds-col-md-12 tds-col-sm-12 tds-col-xs-12"
              >
                <tds-text-field
                  no-min-width
                  type="Text"
                  size="md"
                  state="{{
                    formFinancialItem.get('FinancialItem')['controls'][
                      'Description'
                    ].touched &&
                    formFinancialItem.get('FinancialItem')['controls'][
                      'Description'
                    ].errors
                      ? 'error'
                      : ''
                  }}"
                  id="Description"
                  ngDefaultControl
                  formControlName="Description"
                  label="{{ shared.translate('Description') }}"
                  helper="{{
                    fc['Description'].touched && fc['Description'].errors
                      ? 'Required field'
                      : ''
                  }}"
                  label-position="outside"
                  (focusout)="
                    formFinancialItem
                      .get('FinancialItem')
                      ['controls']['Description'].markAsTouched()
                  "
                ></tds-text-field>
              </div>
            </div>
            <div class="tds-row">
              <div class="tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
                <tds-dropdown
                  #datatype
                  id="DataType"
                  size="md"
                  name="dropdown"
                  label="Datatype"
                  label-position="outside"
                  placeholder="Select"
                  size="md"
                  open-direction="down"
                  ngDefaultControl
                  formControlName="DataType"
                  (tdsSelect)="changeValue('datatype', $event)"
                  [error]="fc['DataType'].touched && fc['DataType'].errors"
                  helper="{{
                    fc['DataType'].touched && fc['DataType'].errors
                      ? 'Required field'
                      : ''
                  }}"
                  style="z-index: 2000"
                >
                  <tds-dropdown-option
                    value="{{ datatype.DataType }}"
                    *ngFor="let datatype of financialIDatatypeList"
                    >{{ datatype.DataType }}</tds-dropdown-option
                  >
                </tds-dropdown>
              </div>
              <div class="tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
                <tds-text-field
                  no-min-width
                  type="number"
                  size="md"
                  id="MaxSize"
                  ngDefaultControl
                  formControlName="MaxSize"
                  label="Max. Length"
                  label-position="outside"
                ></tds-text-field>
              </div>
              <div class="tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
                <span class="letter-adjust">{{
                  shared.translate("Required")
                }}</span>
                <tds-toggle size="md">
                  <div slot="label"></div>
                </tds-toggle>
              </div>
            </div>
            <div class="tds-row">
              <div class="tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
                <tds-text-field
                  no-min-width
                  type="number"
                  size="md"
                  ngDefaultControl
                  formControlName="Order"
                  id="ListOrder"
                  label="{{ shared.translate('List Order') }}"
                  label-position="outside"
                  no-min-width
                ></tds-text-field>
              </div>
              <div class="tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
                <tds-dropdown
                  #type
                  name="dropdown"
                  label="{{ shared.translate('Type') }}"
                  label-position="outside"
                  placeholder="Select"
                  size="md"
                  open-direction="down"
                  ngDefaultControl
                  formControlName="Type"
                  id="Type"
                  (tdsSelect)="changeValue('type', $event)"
                >
                  <tds-dropdown-option value="CNS_GRUPO"
                    >CNS_GRUPO</tds-dropdown-option
                  >
                  <tds-dropdown-option value="VO">VO</tds-dropdown-option>
                  <tds-dropdown-option value="VA_DT">VA_DT</tds-dropdown-option>
                  <tds-dropdown-option value="CNS_COTA"
                    >CNS_COTA</tds-dropdown-option
                  >
                  <tds-dropdown-option value="VA">VA</tds-dropdown-option>
                  <tds-dropdown-option value="CFI_TIPO"
                    >CFI_TIPO</tds-dropdown-option
                  >
                  <tds-dropdown-option value="ASA">ASA</tds-dropdown-option>
                  <tds-dropdown-option value="VL">VL</tds-dropdown-option>
                  <tds-dropdown-option value="FIN_ALIEN"
                    >FIN_ALIEN</tds-dropdown-option
                  >
                  <tds-dropdown-option value="CNS_CONTEMPL"
                    >CNS_CONTEMPL</tds-dropdown-option
                  >
                </tds-dropdown>
              </div>
              <div class="tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
                <tds-text-field
                  no-min-width
                  type="text"
                  size="md"
                  ngDefaultControl
                  formControlName="RegEx"
                  id="RegEx"
                  label="RegEx"
                  label-position="outside"
                  no-min-width
                ></tds-text-field>
              </div>
            </div>
          </tds-block>
        </tds-block>

        <div
          *ngIf="
            formFinancialItem.get('FinancialItem').get('DataType').value ==
            'LIST'
          "
          class="tds-u-mt3"
          formGroupName="FinancialItemList"
        >
          <!-- <div *ngIf="
            formFinancialItem.get('FinancialItem').get('DataType')
            .value == 'LIST' class="tds-u-mt3" > -->
          <tds-block>
            <tds-block formArrayName="Items">
              <div></div>
              <div class="tds-row">
                <h3 class="tds-headline-05">
                  {{ shared.translate("List Items") }}
                </h3>

                <div
                  class="tds-col-max-11 tds-col-md-10 tds-col-sm-10 tds-col-xs-10 align-items"
                >
                  <img
                    class="imgButtons"
                    (click)="addItemList()"
                    src="../../../../../assets/images/plus.svg"
                  />
                </div>
              </div>

              <div
                class="tds-row"
                [formGroupName]="i"
                *ngFor="let item of Items.controls; let i = index"
              >
                <!-- <div
                class="tds-row"
                [formGroupName]="i"
                *ngFor="let item of Items.controls; let i = index"
              > -->
                <div class="tds-col-md-2 tds-col-sm-2 tds-col-xs-12">
                  <tds-text-field
                    no-min-width
                    ngDefaultControl
                    label="{{ shared.translate(' List Value') }}"
                    label-position="outside"
                    formControlName="ListValue"
                    id="ListValue"
                    size="sm"
                    nominwidth
                    helper="{{
                      Items.controls[i]['controls']['ListValue'].touched &&
                      Items.controls[i]['controls']['ListValue'].errors
                        ? 'Required Field'
                        : ''
                    }}"
                    placeholder="{{ shared.translate('Value') }}"
                    (focusout)="
                      Items.controls[i]['controls']['ListValue'].markAsTouched()
                    "
                    state="{{
                      Items.controls[i]['controls']['ListValue'].touched &&
                      Items.controls[i]['controls']['ListValue'].errors
                        ? 'error'
                        : ''
                    }}"
                  >
                  </tds-text-field>
                </div>
                <div class="tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
                  <tds-text-field
                    no-min-width
                    ngDefaultControl
                    label="{{ shared.translate('Description') }}"
                    label-position="outside"
                    formControlName="Description"
                    id="Description"
                    size="sm"
                    no-min-width
                    helper="{{
                      Items.controls[i]['controls']['Description'].touched &&
                      Items.controls[i]['controls']['Description'].errors
                        ? 'Required Field'
                        : ''
                    }}"
                    placeholder="{{ shared.translate('Description') }}"
                    (focusout)="
                      Items.controls[i]['controls'][
                        'Description'
                      ].markAsTouched()
                    "
                    state="{{
                      Items.controls[i]['controls']['Description'].touched &&
                      Items.controls[i]['controls']['Description'].errors
                        ? 'error'
                        : ''
                    }}"
                  >
                  </tds-text-field>
                </div>
                <div
                  class="tds-col-md-2 tds-col-lg-2 tds-col-sm-2 tds-col-xs-12"
                >
                  <tds-text-field
                    no-min-width
                    ngDefaultControl
                    type="number"
                    formControlName="ListOrder"
                    label="{{ shared.translate('Order') }}"
                    label-position="outside"
                    id="ListOrder"
                    size="sm"
                    no-min-width
                    helper="{{
                      Items.controls[i]['controls']['ListOrder'].touched &&
                      Items.controls[i]['controls']['ListOrder'].errors
                        ? 'Required Field'
                        : ''
                    }}"
                    placeholder="{{ shared.translate('ListOrder') }}"
                    (focusout)="
                      Items.controls[i]['controls']['ListOrder'].markAsTouched()
                    "
                    state="{{
                      Items.controls[i]['controls']['ListOrder'].touched &&
                      Items.controls[i]['controls']['ListOrder'].errors
                        ? 'error'
                        : ''
                    }}"
                  >
                  </tds-text-field>
                </div>
                <div class="sdds-col-md-2 sdds-col-xs-12">
                  <span class="letter-adjust">{{
                    shared.translate("Default")
                  }}</span>
                  <tds-toggle
                    size="md"
                    type="checkbox"
                    [checked]="
                      Items.controls[i]['controls'].ListDefault.value == 'Y'
                    "
                    (change)="setToggleValue('Default', i)"
                    id="Required"
                  >
                    <div slot="label"></div>
                  </tds-toggle>
                </div>
                <div class="tds-col-md-2 tds-col-xs-12">
                  <img
                    [ngStyle]="{ display: i == 0 ? 'none' : 'block' }"
                    class="imgButtons"
                    style="display: none; margin-top: 16px"
                    (click)="removeItemList(i)"
                    src="../../../../../assets/images/minus.svg"
                  />
                </div>
              </div>
            </tds-block>
          </tds-block>
        </div>
      </form>
    </span>

    <span slot="actions">
      <div class="tds-row">
        <div class="tds-col-xs-6">
          <tds-button
            type="button"
            variant="danger"
            size="sm"
            text="{{ shared.translate('Cancel') }}"
            data-dismiss-modal
            (click)="closeModal(itemModal, datatype, type)"
          ></tds-button>
        </div>
        <div class="tds-col-xs-6">
          <tds-button
            type="button"
            variant="primary"
            size="sm"
            text="Save"
            (click)="saveFinancialItem(itemModal)"
          ></tds-button>
        </div>
      </div>
    </span>
  </tds-modal>
</div>
