import { Component } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Language } from 'src/app/models/language.model';
import { Term } from 'src/app/models/term.model';
import { AccessService } from 'src/app/service/api/access.service';
import { LanguageService } from 'src/app/service/api/language.service';
import { SharedService } from 'src/app/service/shared.service';
import { ngxCsv } from 'ngx-csv/ngx-csv';

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.css']
})
export class LanguageComponent {

  languages: Language[];
  tempTerms: Term[];
  terms: Term[];
  termFilter: string = '';
  selectedLanguage: Language;

  termForm: FormGroup;

  get f() {
    return this.termForm.controls
  }

  get translations() {
    return this.termForm.get('request') as FormArray
  }

  constructor(public shared: SharedService, private access: AccessService, private language: LanguageService, private fb:FormBuilder) { }

  ngOnInit() {
    this.shared.loading(true);
    this.getLanguages();
    this.createTermForm();
  }

  createTermForm() {
    this.termForm = this.fb.group({
      Term: ['', Validators.required],
      request: this.fb.array([])
    })
    this.addTranslation('en')
  }

  addTranslation(lcid?: string, term?: string, description?: string) {
    this.translations.push(this.fb.group({
      Lcid: [lcid || '', Validators.required],
      Term: [term || ''],
      Description: [description || '', Validators.required]
    }));
  }

  removeTranslation(index: number) {
    this.translations.removeAt(index);
  }

  setLanguageTerm(index, event) {
    this.translations.at(index).patchValue({ Lcid: event.target.value });
  }

  setLanguagesHeaderNames() {
    document.getElementById('lcid').setAttribute('column-title', this.shared.translate('LCID'));
    document.getElementById('name').setAttribute('column-title', this.shared.translate('Name'));
  }

  setLanguagesTableValues(index: number, language: Language) {
    document.getElementById(`rowLCID${index}`).setAttribute('cell-value', language.LCID);
    document.getElementById(`rowName${index}`).setAttribute('cell-value', language.Term);
  }

  setTermsHeaderNames() {
    document.getElementById('term').setAttribute('column-title', this.shared.translate('Term'));
    document.getElementById('original').setAttribute('column-title', this.shared.translate('Original'));
    document.getElementById('translation').setAttribute('column-title', this.shared.translate('Translation'));
  }

  setTermsTableValues(index: number, term: Term) {
    document.getElementById(`rowTerm${index}`).setAttribute('cell-value', term.NameTerm);
    document.getElementById(`rowOriginal${index}`).setAttribute('cell-value', term.DescTerm);
  }

  getLanguages() {
    this.access.getLanguages().subscribe({
      next: (result) => {
        this.languages = result.Data;
        this.shared.loading(false);
      },
      error: () => {
        this.shared.errorAlert('Error while getting languages!');
        this.shared.loading(false);
      }
    })
  }

  UpdateTerm(term: Term, event: any) {
    this.shared.loading(true);
    var request = [];
    request.push({
      lcid: this.selectedLanguage.LCID,
      term: term.DescTerm,
      description: event.target.value
    })
    this.language.createOrUpdateTerms(request).subscribe({
      next: (result) => {
        if (result.Success) {
          this.shared.successAlert('Term updated sucessfuly!');
        } else {
          this.shared.errorAlert('Error while updating Term!');
        }
        this.shared.loading(false);
      },
      error: () => {
        this.shared.errorAlert('Error while updating Term!');
        this.shared.loading(false);
      }
    })
  }

  CreateTerm(modal: HTMLTdsModalElement) {
    if (!this.termForm.valid) {
      this.shared.errorAlert(this.shared.translate('Please, fulfill all required fields!'));
      return
    }
    var term = this.termForm.get('Term').value;

    this.translations.controls.forEach(c => {
      c.patchValue({ Term: term })
    })
    
    this.shared.loading(true);
    this.language.createOrUpdateTerms(this.termForm.get('request').value).subscribe({
      next: (result) => {
        if (result.Success) {
          this.shared.successAlert('Term created sucessfuly!');
          this.getTermsFromTo(this.selectedLanguage);
          modal.closeModal();
        } else {
          this.shared.errorAlert('Error while creating Term!');
        }
        this.shared.loading(false);
      },
      error: () => {
        this.shared.errorAlert('Error while creating Term!');
        this.shared.loading(false);
      }
    })
  }


  getTermsFromTo(language) {
    this.shared.loading(true);
    this.selectedLanguage = language;

    this.language.getTermsFromTo(this.selectedLanguage.LCID).subscribe({
      next: (result) => {
        document.getElementById('terms').style.display = 'block';
        this.tempTerms = result.Data;
        this.terms = result.Data;
        this.shared.loading(false);
      },
      error: () => {
        this.shared.errorAlert('Error while getting terms!');
        this.shared.loading(false);
      }
    })
  }

  showEmptyTranslation(checkbox: HTMLTdsCheckboxElement) {
    if (checkbox.checked) {
      this.terms = this.terms.filter(t => !t.DescTermTo);
    }else {
      this.terms = this.tempTerms
    }
  }

  exportCSV() {
    let csv = [];
    this.terms.forEach(term => {
      csv.push({Nameterm: term.NameTerm, DescTerm: term.DescTerm, DescTermTo: term.DescTermTo})
    })
    var options = { 
      fieldSeparator: ',',
      quoteStrings: '',
      decimalseparator: '.',
      showLabels: true,
      useBom: true,
      headers: ["NameTerm", "DescTerm", "DescTermTo"]
    };
    new ngxCsv(csv, 'data', options);
  }
}
