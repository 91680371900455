import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Buffer } from 'buffer';
import { AuthService } from 'src/app/auth/auth.service';
import Swal from 'sweetalert2';
import { musys } from 'src/environments/environment';
import { Result } from '../models/result.model';
import { Language } from '../models/language.model';
import { Market } from '../models/market.model';
import { HeaderService } from 'src/app/service/header.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  site: string = window.location.pathname.startsWith('/site') ? '/site/' : '/';

  private langDb: Language[] = JSON.parse(localStorage.getItem('Language')) ? JSON.parse(localStorage.getItem('Language') || '').Data : null;
  private const_ck_permissions = this.getPermissionsCookieName();
  private permissionsEncoded = this.getPermissionsEncoded();
  private permissionsDecoded;
  public CurrentUsername = this.cookieService.get('Musys.Ui.User.Username');
  public CurrentUserFullName = this.cookieService.get('Musys.Ui.User.UserFullName');
  private blocked = false;

  constructor(
    private cookieService: CookieService,
    private auth: AuthService,
    private headerService: HeaderService,
  ) { }

  public setMarketCookies(
    id: string,
    code: string,
    descr: string,
    system: string
  ) {
    this.cookieService.set('Musys.Ui.User.MarketUnitId.Current', id, null, '/');
    this.cookieService.set('Musys.Ui.User.MarketUnitCode.Current', code, null, '/');
    this.cookieService.set(
      'Musys.Ui.User.MarketUnitDescription.Current',
      descr
      , null, '/');
    this.cookieService.set('Musys.Ui.User.MarketUnitSystem.Current', system, null, '/');
  }

  getMarkets(): void {
    this.headerService.getMarkets().subscribe({
      next: (result) => {

        if (!result.Success) {
          this.errorAlert('Error getting Markets.', result.Errors[0])
          return;
        }
        localStorage.setItem('Musys.Ui.Markets.List', JSON.stringify(result.Data));
      },
      error: (error) =>
        this.errorAlert('Error getting Markets.', error.message),
    });
  }

  getCurrentMarket() {
    var marketId = this.cookieService.get('Musys.Ui.User.MarketUnitId.Current');

    if (!localStorage.getItem('Musys.Ui.Markets.List')) {
      this.getMarkets();
    }
    var markets: Market[] = localStorage.getItem('Musys.Ui.Markets.List') ?
      JSON.parse(localStorage.getItem('Musys.Ui.Markets.List')) :
      null;

    var currentMarket = markets && marketId ? markets.find(market => market.Id == parseInt(marketId)) : null;
    if (!currentMarket) {
      return markets[0];
    } else {
      return currentMarket;
    }
  }

  getCurrentLanguage() {
    return localStorage.getItem('Musys.Ui.User.Language.Current') || 'en';
  }

  public getPermissionsEncoded() {

    return this.cookieService.get(
      Buffer.from(this.const_ck_permissions, 'utf8').toString('base64')
    );
  }

  public getPermissionsCookieName(): string {
    // Get User Permissions from cookie
    var user =
      localStorage.getItem('Musys.Ui.Markets.User') != null
        ? localStorage.getItem('Musys.Ui.Markets.User')
        : '';
    return user?.toUpperCase() + '_' + this.cookieService.get('Musys.Ui.User.MarketUnitId.Current') + '_ACCESS';
  }
  public hasAuthorization(functionCode: string): boolean {
    // If not found, make the request to API
    if (!this.permissionsEncoded) {
      this.UpdatePermissions();
      this.permissionsEncoded = this.cookieService.get(
        Buffer.from(this.const_ck_permissions, 'utf8').toString('base64')
      );
    }

    if (!this.permissionsEncoded) return false;

    if (!this.permissionsDecoded) {
      this.permissionsDecoded = JSON.parse(Buffer.from(this.permissionsEncoded, 'base64').toString('utf8'));;
    }


    return this.permissionsDecoded.Data.indexOf(functionCode) > -1;
  }

  public UpdatePermissions() {
    var date = new Date();
    date = new Date(date.getTime() + 600000);

    var userFunctions = this.auth.getUserFunctions();

    if (userFunctions.Success == false) {
      console.error('Error getting user functions!');
      location.reload();
    }

    // Encoding permissions
    var cookieName = Buffer.from(this.const_ck_permissions, 'utf8').toString(
      'base64'
    );
    var cookieResult = Buffer.from(
      JSON.stringify(userFunctions),
      'utf8'
    ).toString('base64');

    this.cookieService.set(cookieName, cookieResult, date, '/');
  }

  isMusysBR(): boolean {
    return this.cookieService.get('Musys.Ui.User.MarketUnitSystem.Current') == 'MUSYS-BR';
  }

  warningAlert(text: string, width: string = 'md') {
    return Swal.fire({
      icon: 'warning',
      title: this.translate('Attention'),
      text: this.translate(text),
      allowOutsideClick: false,
      allowEnterKey: false,
      allowEscapeKey: true,
      width: width == 'sm' ? '30%' : width == 'md' ? '45%' : '60%',
      confirmButtonColor: '#F1C21B',
    });
  }

  errorAlert(text: string, footer: string = '', width: string = 'md') {
    Swal.fire({
      icon: 'error',
      title: this.translate('Error'),
      text: this.translate(text),
      allowOutsideClick: false,
      allowEscapeKey: true,
      width: width == 'sm' ? '30%' : width == 'md' ? '45%' : '60%',
      footer:
        footer != ''
          ? `<sdds-accordion><sdds-accordion-item header="Technical details" affix="prefix" tabindex="1">${footer}</sdds-accordion-item></sdds-accordion>`
          : '',
      allowEnterKey: false,
      confirmButtonColor: '#FF2340',
      customClass: {
        container: 'my-swal'
      }
    });
  }

  successAlert(text: string, width: string = 'md') {
    Swal.fire({
      icon: 'success',
      title: this.translate('Success'),
      text: this.translate(text),
      width: width == 'sm' ? '30%' : width == 'md' ? '45%' : '60%',
      allowOutsideClick: false,
      allowEnterKey: false,
      confirmButtonColor: '#1DAB8B',
    });
  }

  warningOptionAlert(
    text: string,
    width: string = 'md',
    confirmButtonText: string = 'OK',
    cancelButtonText: string = 'Cancel'
  ) {
    return Swal.fire({
      icon: 'warning',
      title: this.translate('Attention'),
      text: this.translate(text),
      width: width == 'sm' ? '30%' : width == 'md' ? '45%' : '60%',
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: cancelButtonText,
      allowEnterKey: false,
      confirmButtonColor: '#F1C21B',
      cancelButtonColor: '#CDD1DB',
    });
  }

  translate(term: string) {
    if (this.blocked) return term;

    var result = this.langDb ? this.langDb.find(e => e.Term == term) : null;

    if (result) {
      return result.Translation;
    }
    return term;
  }


  setLanguage(id: string, mustReload: boolean) {
    id = (id == null ? 'en' : id);

    localStorage.setItem('Musys.Ui.User.Language.Current', id);

    localStorage.removeItem('Language');

    var result = this.getTerms(id)

    if (!result?.Success) {
      this.errorAlert(
        'Error getting Terms.',
      );
      return
    }

    var date = new Date();
    localStorage.setItem("Language", JSON.stringify(result));
    localStorage.setItem("DateOfTranslation", JSON.stringify(date.getTime()));
    this.langDb = JSON.parse(localStorage.getItem('Language') || '').Data;

    if (mustReload) {
      location.reload();
    }

  }

  getTerms(lcid: string) {
    if (this.blocked) return null;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', musys.api + '/language/terms?lcid=' + lcid, false);
    xhr.setRequestHeader('Authorization', 'Bearer ' + this.auth.getMusysTokenFromCookie());
    xhr.send();

    if (xhr.status == 200) {
      const response: Result<string> = JSON.parse(xhr.responseText)
      return response;
    }
    else {
      throw new Error('Error getting Terms! ' + xhr.status);
    }
  }

  setQueryParams(object: any) {
    return new HttpParams({ fromObject: object })
  }

  getPartnerId() {
    return this.cookieService.get('Musys.Ui.User.MarketUnitId.Current');
  }

  loading(isLoading: boolean) {
    if (isLoading) {
      document.getElementById('loading').style.display = 'flex';
    } else {
      document.getElementById('loading').style.display = 'none';
    }
  }

  useToast(header: string, text: string, type) {
    var toast = document.getElementById('toastComponent') as HTMLTdsToastElement;
    toast.showToast();
    toast.header = header;
    toast.subheader = text;
    toast.variant = type;
    setTimeout(() => {
    toast.hideToast()
        }, 5000);
  }
}
