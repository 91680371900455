import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { FooterComponent } from './component/footer/footer.component';
import { AccessComponent } from './component/menu/admin/access/access.component';
import { AdminComponent } from './component/menu/admin/admin.component';
import { SalesComponent } from './component/menu/sales/sales.component';
import { PurchaseComponent } from './component/menu/purchase/purchase.component';
import { HomeComponent } from './component/home/home.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { MenuComponent } from './component/menu/menu.component';
import { TokenInterceptor } from './auth/auth.token.interceptor';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from './auth/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BasicDataComponent } from './component/menu/admin/basic-data/basic-data.component';
import { BreadcrumbModule } from 'xng-breadcrumb';
import { BreadcrumbService } from 'xng-breadcrumb';
import { BreadcrumbsComponent } from './component/breadcrumbs/breadcrumbs.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { MarketsComponent } from './component/menu/admin/markets/markets.component';
import { PartnerInformationComponent } from './component/menu/admin/partner-information/partner-information.component';
import { MarketUnitInformationComponent } from './component/menu/admin/market-unit-information/market-unit-information.component';
import { DealerAdminComponent } from './component/menu/admin/dealer-admin/dealer-admin.component';
import { DeliveryAddressComponent } from './component/menu/admin/delivery-address/delivery-address.component';
import { FinancialParametersComponent } from './component/menu/admin/financial-parameters/financial-parameters.component';
import { ExchangeRateComponent } from './component/menu/admin/exchange-rate/exchange-rate.component';
import { RegionInformationComponent } from './component/menu/admin/region-information/region-information.component';
import { FfuAndSorderComponent } from './component/menu/admin/ffu-and-sorder/ffu-and-sorder.component';
import { DragDropModule } from "@angular/cdk/drag-drop";
import { DatePipe } from '@angular/common';
import { FilterVariantsPipe } from './pipes/filter-variants.pipe';
import { DiscountRateComponent } from './component/menu/admin/discount-rate/discount-rate.component';
import { MarketParameterComponent } from './component/menu/admin/market-parameter/market-parameter.component';
import { DealerAccessComponent } from './component/menu/admin/dealer-access/dealer-access.component';
import { FilterDealersPipe } from './pipes/filter-dealers.pipe';
import { FilterUserRolesPipe } from './pipes/filter-user-roles.pipe';
import { LanguageComponent } from './component/menu/admin/language/language.component';
import { FilterTermsPipe } from './pipes/filter-terms.pipe';
import { DealerGovernanceComponent } from './component/menu/admin/dealer-governance/dealer-governance.component';
import {MatChipsModule} from '@angular/material/chips';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatFormFieldModule} from '@angular/material/form-field';
import { AdmMktDaysComponent } from './component/menu/admin/adm-mkt-days/adm-mkt-days.component';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    AccessComponent,
    AdminComponent,
    SalesComponent,
    PurchaseComponent,
    HomeComponent,
    MenuComponent,
    BasicDataComponent,
    BreadcrumbsComponent,
    MarketsComponent,
    PartnerInformationComponent,
    MarketUnitInformationComponent,
    DealerAdminComponent,
    DeliveryAddressComponent,
    FinancialParametersComponent,
    ExchangeRateComponent,
    RegionInformationComponent,
    FfuAndSorderComponent,
    FilterVariantsPipe,
    DiscountRateComponent,
    MarketParameterComponent,
    AdmMktDaysComponent,
    DealerAccessComponent,
    FilterDealersPipe,
    FilterUserRolesPipe,
    LanguageComponent,
    FilterTermsPipe,
    DealerGovernanceComponent,
    AdmMktDaysComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    BreadcrumbModule,
    DragDropModule,
    [SweetAlert2Module.forRoot()],
    [SweetAlert2Module],
    [SweetAlert2Module.forChild({/* options */}),
    ],
    ReactiveFormsModule,
    MatChipsModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    CookieService,
    AuthService,
    BreadcrumbService,
    DatePipe
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA], // Add the Schema
})
export class AppModule {}
