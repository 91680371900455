<div class="tds-container">
    <tds-block>
        <div class="tds-row">
            <div class="tds-col-md-11 tds-col-sm-10 tds-col-xs-10">
                <h3>{{shared.translate('Delivery Address')}}</h3>
            </div>
            <div class="tds-col-md-1 tds-col-sm-2 tds-u-flex-end">
                <tds-button class="tds-btn tds-btn-primary" type="button" variant="primary" size="sm"
                    text="{{shared.translate('New')}}" (click)="openModal(deliveryAddressModal, 'POST')"></tds-button>
            </div>
        </div>
        <tds-block>
            <div class="tds-row tds-u-mt2">
                <div class="tds-col-max-11 tds-col-md-11 tds-col-sm-10 tds-col-xs-10">
                    <div class="tds-u-mb2 tds-u-pl2 tds-u-mr1">
                        <tds-dropdown (tdsSelect)="onChangeSelectedCountry($event)" default-value="all"
                            open-direction="down" label="{{shared.translate('Country')}}" label-position="outside"
                            placeholder="{{shared.translate('Country')}}" size="md"> 
                            <tds-dropdown-option value="all">{{shared.translate('All')}}</tds-dropdown-option>
                            <tds-dropdown-option *ngFor="let country of deliveryAddressCountries"
                                value="{{country}}">{{country}}</tds-dropdown-option>
                        </tds-dropdown>
                    </div>
                </div>
                <div id="searchButton" class="tds-col-max-1 tds-col-md-1 tds-col-sm-2 tds-col-xs-2 justify-end">
                    <tds-button type="button" variant="secondary" size="sm" text="{{shared.translate('Open')}}"
                        (click)="open()"></tds-button>
                </div>
            </div>
        </tds-block>

        <tds-block>
            <div class="tds-col-md-1 tds-u-mt3 align-end" *ngIf="isLoading">
                <tds-spinner size="md" variant="standard"></tds-spinner>
            </div>
            <tds-table class="tds-col-md-12 tds-col-sm-12 tds-col-xs-12 tds-col-max-12 tds-u-pb3 tds-u-mt3"
                vertical-dividers="false" compact-design="false" responsive="true" no-min-width
                id="delivey-address-table" *ngIf="isTableVisible">
                <tds-table-header>
                    <tds-header-cell id="country" column-title={{setHeaderNames()}}></tds-header-cell>
                    <tds-header-cell id="code" column-title=""></tds-header-cell>
                    <tds-header-cell id="name" column-title=""></tds-header-cell>
                    <tds-header-cell id="default" column-title=""></tds-header-cell>
                    <tds-header-cell id="edit" column-title=""></tds-header-cell>
                    <tds-header-cell id="delete" column-title=""></tds-header-cell>
                </tds-table-header>
                <tds-table-body class="tds-u-p3">
                    <tds-table-body-row *ngFor="let deliveryAddress of deliveryAddressList; let i = index">
                        <tds-body-cell id="rowCountry{{i}}" disable-padding="false"></tds-body-cell>
                        <tds-body-cell id="rowCode{{i}}" disable-padding="false"></tds-body-cell>
                        <tds-body-cell id="rowName{{i}}" disable-padding="false"></tds-body-cell>
                        <tds-body-cell id="rowDefault{{i}}" disable-padding="false"></tds-body-cell>
                        <tds-body-cell id="rowEdit{{i}}" custom-width="20px" disable-padding="false">
                            <tds-icon name="edit" size="22px" class="tds-u-mr1"
                                (click)="openModal(deliveryAddressModal,'PUT', deliveryAddress)"></tds-icon>
                        </tds-body-cell>
                        <tds-body-cell id="rowDelete{{i}}" cell-value="{{setTableValues(i)}}" disable-padding="false">
                            <tds-icon name="trash_inactive" size="20px" class="tds-u-mr1"
                                (click)="deleteDeliveryAddress(deliveryAddress.Country, deliveryAddress.Code)"></tds-icon>
                        </tds-body-cell>
                    </tds-table-body-row>
                </tds-table-body>
            </tds-table>
        </tds-block>

        <tds-modal selector="#form-modal" id="deliveryAddressModal" size="sm" #deliveryAddressModal
            actions-position="sticky">
            <span slot="body">
                <form [formGroup]="form">
                    <tds-block>
                        <tds-block>
                            <div class="tds-row">
                                <div class="tds-col-md-10 tds-u-flex">
                                    <h3>{{modalTitle}}</h3>
                                </div>
                            </div>
                            <div class="tds-row">
                                <div class="tds-col-xs-12 tds-col-sm-12 tds-col-md-6 tds-col-max-6 tds-u-pb3">
                                    <tds-text-field id="country" ngDefaultControl formControlName="Country" rows="1"
                                        size="sm" *ngIf="requestType=='PUT'" read-only
                                        label="{{shared.translate('Country')}}" label-position="outside"
                                        placeholder="{{shared.translate('Country')}}" min-length="2" max-length="2"
                                        no-min-width (focusout)="f['Country'].markAsTouched()"
                                        state="{{f['Country'].touched && f['Country'].errors ? 'error' : ''}}"
                                        helper="{{f['Country'].touched && f['Country'].errors ? shared.translate('Required Field.') : ''}}">
                                    </tds-text-field>
                                    <tds-text-field id="country" ngDefaultControl formControlName="Country" rows="1"
                                        size="sm" *ngIf="requestType=='POST'" label="{{shared.translate('Country')}}"
                                        label-position="outside" placeholder="{{shared.translate('Country')}}"
                                        min-length="2" max-length="2" no-min-width
                                        (focusout)="f['Country'].markAsTouched()"
                                        state="{{f['Country'].touched && f['Country'].errors ? 'error' : ''}}"
                                        helper="{{f['Country'].touched && f['Country'].errors ? shared.translate('Required Field.') : ''}}">
                                    </tds-text-field>
                                </div>
                                <div class="tds-col-xs-12 tds-col-sm-12 tds-col-md-6 tds-col-max-6 tds-u-pb3">
                                    <tds-text-field class="tds-u-mt3 " ngDefaultControl formControlName="Code" rows="1"
                                        size="sm" *ngIf="requestType=='PUT'" read-only
                                        label="{{shared.translate('Code')}}" label-position="outside"
                                        placeholder="{{shared.translate('Code')}}" no-min-width
                                        (focusout)="f['Code'].markAsTouched()"
                                        state="{{f['Code'].touched && f['Code'].errors ? 'error' : ''}}"
                                        helper="{{f['Code'].touched && f['Code'].errors ? shared.translate('Required Field.') : ''}}">
                                    </tds-text-field>
                                    <tds-text-field class="tds-u-mt3 " ngDefaultControl formControlName="Code" rows="1"
                                        size="sm" *ngIf="requestType=='POST'" label="{{shared.translate('Code')}}"
                                        label-position="outside" placeholder="{{shared.translate('Code')}}" no-min-width
                                        (focusout)="f['Code'].markAsTouched()"
                                        state="{{f['Code'].touched && f['Code'].errors ? 'error' : ''}}"
                                        helper="{{f['Code'].touched && f['Code'].errors ? shared.translate('Required Field.') : ''}}">
                                    </tds-text-field>
                                </div>
                            </div>
                            <div class="tds-row">
                                <div class="tds-col-xs-12 tds-col-sm-12 tds-col-md-6 tds-col-max-6 tds-u-pb3">
                                    <tds-text-field ngDefaultControl formControlName="Name" rows="1" size="sm"
                                        label="{{shared.translate('Name')}}" label-position="outside"
                                        placeholder="{{shared.translate('Name')}}" no-min-width
                                        (focusout)="f['Name'].markAsTouched()"
                                        state="{{f['Name'].touched && f['Name'].errors ? 'error' : ''}}" 
                                        helper="{{f['Name'].touched && f['Name'].errors ? shared.translate('Required Field.') : ''}}">
                                    </tds-text-field>
                                </div>
                                <div class="tds-col-xs-12 tds-col-sm-12 tds-col-md-6 tds-col-max-6 tds-u-pb3">
                                    <tds-dropdown id="isDefault" (tdsSelect)="setDropdown($event)" ngDefaultControl
                                        formControlName="IsDefault" #isDefault id="isDefault" size="sm"
                                        open-direction="down" label="{{shared.translate('Default')}}"
                                        label-position="outside" placeholder="{{shared.translate('Default')}}"
                                        no-min-width [error]="f['IsDefault'].touched && f['IsDefault'].errors"
                                        (focusout)="f['IsDefault'].markAsTouched()"
                                        helper="{{f['IsDefault'].touched && f['IsDefault'].errors ? shared.translate('Selection is required.') : ''}}">
                                        <tds-dropdown-option value="Y">{{shared.translate('Yes')}}</tds-dropdown-option>
                                        <tds-dropdown-option value="N">{{shared.translate('No')}}</tds-dropdown-option>
                                    </tds-dropdown>
                                </div>
                            </div>
                        </tds-block>
                    </tds-block>
                </form>
            </span>
            <span slot="actions">
                <tds-button class="tds-btn tds-btn-secondary tds-btn-sm tds-u-mr1" type="button" variant="primary"
                    size="sm" text="{{shared.translate('Save')}}"
                    (click)="saveDeliveryAddress(country, code, deliveryAddressModal)"></tds-button>
                <tds-button class="tds-btn tds-btn-danger tds-btn-md" type="button" variant="danger" size="sm" tds-u-mr1
                    text="{{shared.translate('Cancel')}}" (click)="closeModal(deliveryAddressModal)"></tds-button>
            </span>
        </tds-modal>
    </tds-block>
</div>