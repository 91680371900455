import { Component } from '@angular/core';
import { ProductSeries } from 'src/app/models/models/productseries.model';
import { ParameterType } from 'src/app/models/parametertype.model';
import { AccessService } from 'src/app/service/api/access.service';
import { MarketService } from 'src/app/service/api/market.service';
import { SharedService } from 'src/app/service/shared.service';
import { CookieService } from 'ngx-cookie-service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AdmDays } from 'src/app/models/admdays.model';


@Component({
  selector: 'app-adm-mkt-days',
  templateUrl: './adm-mkt-days.component.html',
  styleUrls: ['./adm-mkt-days.component.css']
})
export class AdmMktDaysComponent { //atributos

  parameterTypesList: ParameterType[] = [];
  productSeriesList: ProductSeries[] = [];
  admMarketDaysForm: FormGroup;
  admMktDaysList: AdmDays[];
  admMktDaysVehicleType = [];
  parameterType: number;
  modalTitle: string;
  requestType: string;
  isTableVisible: boolean = false;
  requestName = 'POST';



  get marketUnit() {
    return this.cookie.get('Musys.Ui.User.MarketUnitCode.Current')
  }

  get f() {
    return this.admMarketDaysForm.controls;
  }

  constructor( //serviços
    public shared: SharedService,
    private market: MarketService,
    private access: AccessService,
    private cookie: CookieService,
    private fb: FormBuilder,

  ) { }

  ngOnInit(): void {
    this.getParameterTypes();
    this.getUserProductSeries();
    this.createAdmMktDaysForm();

  }

  getParameterTypes() {
    this.market.getParameterTypes().subscribe({
      next: (result) => {
        this.parameterTypesList = result.Data;
      },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate('Error getting Parameter Type.'))
      }
    })
  }

  getUserProductSeries() {
    this.access.getUserProductSeries(parseInt(this.shared.getPartnerId())).subscribe({
      next: (result) => {
        this.productSeriesList = result.Data;
      },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate('Error getting Product Series.'))
      }
    })
  }

  setHeaderNames() {
    document.getElementById('parameter-type').setAttribute('column-title', this.shared.translate('Parameter Type'));
    document.getElementById('vehicle-type').setAttribute('column-title', this.shared.translate('Vehicle Type'));
    document.getElementById('from').setAttribute('column-title', this.shared.translate('From'));
    document.getElementById('to').setAttribute('column-title', this.shared.translate('To'));
    document.getElementById('working-days').setAttribute('column-title', this.shared.translate('Working Days'));
  }

  setTableValues(index: number, admDays) {
    document.getElementById(`rowParameterType${index}`).setAttribute('cell-value', admDays.TypeAdmDaysDescription);
    document.getElementById(`rowVehicleType${index}`).setAttribute('cell-value', admDays.VehicleTypeId.toString());
    document.getElementById(`rowFrom${index}`).setAttribute('cell-value', admDays.From ? admDays?.From.toString()?.substring(0, 10) : "");
    document.getElementById(`rowTo${index}`).setAttribute('cell-value', admDays.To ? admDays?.To.toString()?.substring(0, 10) : "");
    document.getElementById(`rowWorkingDays${index}`).setAttribute('cell-value', admDays.WorkingDays?.toString() || '');
  }

  changeDropdownValue(dropdownName: string, event: any) { // é uma função genérica para alterar o valor dos drodowns de acordo com a opção escolhida
    switch (dropdownName) {
      case "parameter-type":
        this.parameterType = event.target.value;
        break;

      case 'vehicle-type':
        this.admMarketDaysForm.get('VehicleTypeId').patchValue(event.target.value);
        break;

      case 'parameter-type-modal':
        this.admMarketDaysForm.get('TypeAdmDaysId').patchValue(event.target.value);
        break;
    }
  }

  createAdmMktDaysForm() {
    this.admMarketDaysForm = this.fb.group({
      MarketUnitId: [this.shared.getPartnerId()],
      TypeAdmDaysId: ['', Validators.required],
      VehicleTypeId: ['', Validators.required],
      WorkingDays: [''],
      From: ['', Validators.required],
      To: [null]
    });
  }

  open(TypeAdmDaysId: number) {

    this.market.listAdmDays(TypeAdmDaysId).subscribe({
      next: (result) => {
        this.admMktDaysList = result.Data;
        this.isTableVisible = true;
      },
      error: (error) => {
        this.shared.warningAlert(this.shared.translate('Please, fill all required fields!'))
      }
    });
  }

  openModal(modal: any, requestType: string, admMktDays?: AdmDays) {
    this.changeTitle(requestType)
    modal.showModal();

    if (requestType) {
      this.requestType = requestType
      this.clear();

      if (requestType == 'PUT') {
        this.admMarketDaysForm.patchValue({ MarketUnitId: admMktDays.MarketUnitId || null });

        const parameterTypeOption = this.parameterTypesList.find(p => p.Id == admMktDays.TypeAdmDaysId);
        document.getElementById('parameterTypeDropdown')['setValue'](parameterTypeOption.Id, parameterTypeOption.Description);
        this.admMarketDaysForm.patchValue({ TypeAdmDaysId: admMktDays.TypeAdmDaysId || null });

        const vehicleTypeOptions = this.productSeriesList.find(v => v.Id == admMktDays.VehicleTypeId);
        document.getElementById('vehicleType')['setValue'](vehicleTypeOptions.Id, vehicleTypeOptions.Description);
        this.admMarketDaysForm.patchValue({ VehicleTypeId: admMktDays.VehicleTypeId || null });

        const fromDate = new Date(admMktDays.From).toISOString().substring(0, 10);
        this.admMarketDaysForm.patchValue({ From: fromDate || null });

        const toDate = new Date(admMktDays.To).toISOString().substring(0, 10);
        this.admMarketDaysForm.patchValue({ To: toDate || null });

        this.admMarketDaysForm.patchValue({ WorkingDays: admMktDays.WorkingDays || null });
      }
    }
  }

  clear() {
    this.createAdmMktDaysForm();
    document.getElementById("parameterTypeDropdown")["reset"]();
    document.getElementById("vehicleType")["reset"]();

  }

  closeModal(modal: any) {
    modal.closeModal();
  }

  changeTitle(requestType: string) {
    if (requestType == 'PUT') {
      this.modalTitle = 'Edit Adm Mkt Days';
    } else {
      this.modalTitle = 'Create Adm Mkt Days';
    }
  }

  SaveAdmDays() {
    if (this.requestType == 'POST') {
      this.CreateAdmDays();
    } else {
      this.updateMktAdmDays();
    }
  }

  CreateAdmDays() {
    if (!this.admMarketDaysForm.valid) {
      this.admMarketDaysForm.markAllAsTouched()
      this.shared.warningAlert(this.shared.translate('Please, fill all required fields!'));
      return
    }

    const admMktDaysItem = {
      MarketUnitId: parseInt(this.admMarketDaysForm.get('MarketUnitId').value),
      TypeAdmDaysId: parseInt(this.admMarketDaysForm.get('TypeAdmDaysId').value),
      VehicleTypeId: parseInt(this.admMarketDaysForm.get('VehicleTypeId').value),
      WorkingDays: parseInt(this.admMarketDaysForm.get('WorkingDays').value),
      From: this.admMarketDaysForm.get('From').value,
      To: this.admMarketDaysForm.get('To').value,
    };

    this.shared.warningOptionAlert(this.shared.translate('Are you sure to save Adm Mkt Days?'))

      .then((response) => {

        if (response.isConfirmed) {
          this.market.CreateAdmDays(admMktDaysItem).subscribe({
            next: (result) => {
              if (result.Success) {
                this.shared.successAlert(this.shared.translate('Item added successfully!'));

                const parameterTypeOption = this.parameterTypesList.find(p => p.Id == admMktDaysItem.TypeAdmDaysId);

                const isWorkingDaysNotANumber = isNaN(admMktDaysItem.WorkingDays)
                const workingDays = isWorkingDaysNotANumber ? null : admMktDaysItem.WorkingDays

                this.admMktDaysList.push({ ...admMktDaysItem, TypeAdmDaysDescription: parameterTypeOption.Description, WorkingDays: workingDays});

              } else {
                this.shared.errorAlert(this.shared.translate("Error while Adm Mkt Days!"));
              }
            },
            error: (error) => {
              this.shared.errorAlert(this.shared.translate("Adm Mkt Days saving has failed. Please, try again!")); //a ordem das mensagens está correta?
            },
          });
        }
      })
  }

  updateMktAdmDays() {
    if (!this.admMarketDaysForm.valid) {
      this.admMarketDaysForm.markAllAsTouched();

      this.shared.errorAlert(
        this.shared.translate("Please, fill all required fields!")
      );
      return;
    }

    const workingDaysAsNumber = parseInt(this.admMarketDaysForm.get('WorkingDays').value, 10)

    const admMktDaysItem = {
      MarketUnitId: this.admMarketDaysForm.get('MarketUnitId').value,
      TypeAdmDaysId: this.admMarketDaysForm.get('TypeAdmDaysId').value,
      VehicleTypeId: this.admMarketDaysForm.get('VehicleTypeId').value,
      WorkingDays: workingDaysAsNumber,
      From: this.admMarketDaysForm.get('From').value,
      To: this.admMarketDaysForm.get('To').value,
    };

    this.shared.warningOptionAlert(this.shared.translate('Are you sure to save Adm Mkt Days?'))

      .then((response) => {
        if (response.isConfirmed) {
          this.market.UpdateAdmDays(admMktDaysItem).subscribe({
            next: (result) => {
              if (!result.Success) {
                this.shared.successAlert(this.shared.translate('Adm Mkt Days saving has failed. Please, try again!'));
                return
              }
              if(this.requestType == 'PUT') {
                var admMktDaysIndex = this.admMktDaysList.findIndex(a => a.VehicleTypeId == admMktDaysItem.VehicleTypeId);
                this.admMktDaysList.splice(admMktDaysIndex, 1);
              }

              this.shared.successAlert(this.shared.translate('Adm Mkt Days has been saved successfully!'))

              const parameterTypeOption = this.parameterTypesList.find(p => p.Id == admMktDaysItem.TypeAdmDaysId);

              const isWorkingDaysNotANumber = isNaN(admMktDaysItem.WorkingDays)
              const workingDays = isWorkingDaysNotANumber ? null : admMktDaysItem.WorkingDays

              this.admMktDaysList.push({ ...admMktDaysItem, TypeAdmDaysDescription: parameterTypeOption.Description, WorkingDays: workingDays});

            },
            error: (error) =>
              this.shared.errorAlert(this.shared.translate('Adm Mkt Days saving has failed. Please, try again!'), error.message),
          })
        }
      })
  }

  RemoveAdmDays(parameterTypeId: number, VehicleTypeId: number) {
    this.shared.warningOptionAlert('Are you sure you want to remove this item?')
      .then((response) => {
        if (response.isConfirmed) {
          this.market.RemoveAdmDays(parameterTypeId, VehicleTypeId).subscribe({
            next: (result) => {
              if (!result.Success) {
                this.shared.errorAlert(this.shared.translate('Remove failed. Please, try again.'))
                return
              }
              this.shared.successAlert(this.shared.translate('Item has been successfully removed.'))
              var vehicleTypeIndex = this.admMktDaysList.findIndex(admMktDay => admMktDay.VehicleTypeId == VehicleTypeId)
              this.admMktDaysList.splice(vehicleTypeIndex, 1);
            },
            error: (error) =>
              this.shared.errorAlert(this.shared.translate('Remove failed. Please, try again.'), error.message),
          });
        }
      })

  }
}

