import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUserRoles'
})
export class FilterUserRolesPipe implements PipeTransform {

  transform(users: any[], filter: string): any[] {
    if (!users || !filter) {
      return users
    }
    return users.filter(u => u.Username.toUpperCase().includes(filter.toUpperCase()) || u.Name.toUpperCase().includes(filter.toUpperCase()));
  }

}
