<div class="tds-container">
  <tds-block>
    <!-- Título da tela // Botões New e Clear -->
    <div class="tds-row">
      <div class="tds-col-md-10">
        <h3>{{ shared.translate("Market Parameter") }}</h3>
      </div>
    </div>

    <!-- Table Results -->
    <tds-block>
      <tds-block>
        <div>
          <div class="tds-row">
            <div
              class="tds-col-md-12 tds-col-sm-12 tds-col-xs-12 tds-u-pb2 align-center tds-u-flex-end"
            >
              <tds-button
                type="button"
                variant="primary"
                size="sm"
                id="newMarketParam"
                (click)="createMarketParamForm()"
                text="{{ shared.translate('New') }}"
              >
              </tds-button>
            </div>
          </div>
          <div class="tds-row">
            <tds-table
              table-id="pagination-table"
              no-min-width
              vertical-dividers="false"
              compact-design="true"
              responsive="true"
            >
              <tds-table-header>
                <tds-header-cell
                  id="id"
                  column-title="{{ setHeaderNames() }}"
                ></tds-header-cell>
                <tds-header-cell
                  id="partnerId"
                  column-title=""
                ></tds-header-cell>
                <tds-header-cell
                  id="productTypeId"
                  column-title=""
                ></tds-header-cell>
                <tds-header-cell
                  id="productType"
                  column-title=""
                ></tds-header-cell>
                <tds-header-cell id="info" column-title=""></tds-header-cell>
                <tds-header-cell id="edit" column-title=""></tds-header-cell>
                <tds-header-cell id="delete" column-title=""></tds-header-cell>
              </tds-table-header>
              <tds-table-body-row
                *ngFor="let marketParam of marketParameterList; let i = index"
              >
                <tds-body-cell id="rowId{{ i }}"></tds-body-cell>
                <tds-body-cell id="rowPartner{{ i }}"></tds-body-cell>
                <tds-body-cell id="rowProductId{{ i }}"></tds-body-cell>
                <tds-body-cell id="rowProduct{{ i }}"></tds-body-cell>
                <tds-body-cell id="rowInfo{{ i }}">
                  <img
                    (click)="info(marketParam.Id, newMarketParam)"
                    class="infoButton"
                    style="width: 25px; margin-left: 10px; cursor: pointer"
                    src="../../../../../assets/images/info.svg"
                    id="Info{{ i }}"
                  />

                  <tds-tooltip
                    placement="bottom"
                    selector="#Info{{ i }}"
                    text="{{ shared.translate('Info') }}"
                    mouse-over-tooltip="true"
                  >
                  </tds-tooltip>
                </tds-body-cell>
                <tds-body-cell id="rowEdit{{ i }}">
                  <img
                    class="editButton"
                    (click)="
                      getCurrentMarketParam(marketParam.Id, newMarketParam)
                    "
                    style="width: 25px; margin-left: 10px; cursor: pointer"
                    src="../../../../../assets/images/edit.svg"
                    id="Edit{{ i }}"
                  />

                  <tds-tooltip
                    placement="bottom"
                    selector="#Edit{{ i }}"
                    text="{{ shared.translate('Edit') }}"
                    mouse-over-tooltip="true"
                  >
                  </tds-tooltip>
                </tds-body-cell>
                <tds-body-cell
                  id="rowDelete{{ i }}"
                  cell-value="{{ setTableValues(i) }}"
                >
                  <img
                    class="deleteButton"
                    (click)="deleteMarketParameter(marketParam.Id)"
                    style="width: 25px; margin-left: 10px; cursor: pointer"
                    src="../../../../../assets/images/trash.svg"
                    id="Delete{{ i }}"
                  />

                  <tds-tooltip
                    placement="bottom"
                    selector="#Delete{{ i }}"
                    text="{{ shared.translate('Delete') }}"
                    mouse-over-tooltip="true"
                  >
                  </tds-tooltip>
                </tds-body-cell>
              </tds-table-body-row>
            </tds-table>
          </div>
        </div>
      </tds-block>
    </tds-block>
  </tds-block>

  <!-- Modal -->
  <tds-modal
    selector="#newMarketParam"
    id="modal"
    #newMarketParam
    size="md"
    actions-position="sticky"
    prevent
    (tdsClose)="
      close(
        newMarketParam,
        vehicleTypeId,
        currency,
        generalSystem,
        business,
        country     
      )
    "
  >
    <span slot="body">
      <form [formGroup]="formMarketParam">
        <tds-block>
          <h3>
            {{ shared.translate("Market param information") }}
          </h3>

          <tds-block formGroupName="General">
            <div class="tds-row">
              <div class="tds-col-md-10 tds-col-xs-10 tds-u-flex">
                <img
                  style="width: 30px; margin-right: 3px"
                  src="../../../../../assets/images/info.svg"
                />
                <h5>
                  {{ shared.translate("General") }}
                </h5>
              </div>
            </div>

            <div class="tds-row">
              <div class="tds-col-lg-3 tds-col-md-4 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="Id"
                  no-min-width
                  type="number"
                  size="md"
                  read-only
                  id="generalId"
                  label="{{ shared.translate('Id') }}"
                  label-position="outside"
                ></tds-text-field>
              </div>
              <div class="tds-col-lg-3 tds-col-md-4 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  read-only
                  formControlName="MarketId"
                  no-min-width
                  type="number"
                  size="md"
                  id="controlName"
                  label="{{ shared.translate('Partner Id') }}"
                  label-position="outside"
                  placeholder="{{ shared.translate('') }}"
                ></tds-text-field>
              </div>
              <div class="tds-col-lg-3 tds-col-md-4 tds-col-sm-6 tds-col-xs-6">
                <tds-dropdown
                  (tdsSelect)="setDropdown('productTypeId', $event)"
                  ngDefaultControl
                  formControlName="ProductTypeId"
                  id="vehicleTypeId"
                  #vehicleTypeId
                  label="{{ shared.translate('Vehicle type') }}"
                  label-position="outside"
                  placeholder="{{ shared.translate('Select') }}"
                  size="md"
                  open-direction="down"
                  [error]="
                    general['ProductTypeId'].touched &&
                    general['ProductTypeId'].errors
                  "
                  helper="{{
                    general['ProductTypeId'].touched &&
                    general['ProductTypeId'].errors
                      ? 'Required field'
                      : ''
                  }}"
                >
                  <tds-dropdown-option
                    *ngFor="let product of productSeriesList"
                    value="{{ product.Id }}"
                    >{{ product.Id }} -
                    {{ product.Description }}</tds-dropdown-option
                  >
                </tds-dropdown>
              </div>
              <div class="tds-col-lg-3 tds-col-md-4 tds-col-sm-6 tds-col-xs-6">
                <tds-dropdown
                  ngDefaultControl
                  formControlName="DefaultCurrencyCode"
                  #currency
                  id="currency"
                  (tdsSelect)="setDropdown('currency', $event)"
                  label="{{ shared.translate('Currency Code') }}"
                  label-position="outside"
                  placeholder="{{ shared.translate('Select') }}"
                  helper=""
                  size="md"
                  open-direction="down"
                  [error]="
                    general['DefaultCurrencyCode'].touched &&
                    general['DefaultCurrencyCode'].errors
                  "
                  helper="{{
                    general['DefaultCurrencyCode'].touched &&
                    general['DefaultCurrencyCode'].errors
                      ? 'Required field'
                      : ''
                  }}"
                >
                  <tds-dropdown-option
                    *ngFor="let currency of currencyList"
                    value="{{ currency.IsoCode }}"
                    >{{ currency.IsoCode }}</tds-dropdown-option
                  >
                </tds-dropdown>
              </div>
            </div>
            <div class="tds-row">
              <div class="tds-col-lg-3 tds-col-md-4 tds-col-sm-6 tds-col-xs-6">
                <tds-dropdown
                  ngDefaultControl
                  formControlName="System"
                  id="generalSystem"
                  #generalSystem
                  (tdsSelect)="setDropdown('generalSystem', $event)"
                  label="{{ shared.translate('Sales Repository') }}"
                  label-position="outside"
                  placeholder="{{ shared.translate('Select') }}"
                  helper=""
                  size="md"
                  open-direction="down"
                  [error]="
                    general['System'].touched && general['System'].errors
                  "
                  helper="{{
                    general['System'].touched && general['System'].errors
                      ? 'Required field'
                      : ''
                  }}"
                >
                  <tds-dropdown-option
                    *ngFor="let system of systems"
                    value="{{ system.Name }}"
                    >{{ system.Name }}</tds-dropdown-option
                  >
                </tds-dropdown>
              </div>
              <div class="tds-col-lg-3 tds-col-md-4 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="ProductLanguage"
                  no-min-width
                  type="text"
                  size="md"
                  max-length="2"
                  id="productLanguage"
                  label="{{ shared.translate('Product Language') }}"
                  label-position="outside"
                  placeholder="Ex.: PT"
                  state="{{
                    general['ProductLanguage'].touched &&
                    general['ProductLanguage'].errors
                      ? 'error'
                      : ''
                  }}"
                  (focusout)="general['ProductLanguage'].markAsTouched()"
                  helper="{{
                    general['ProductLanguage'].touched &&
                    general['ProductLanguage'].errors
                      ? 'Required field'
                      : ''
                  }}"
                ></tds-text-field>
              </div>
              <div class="tds-col-lg-3 tds-col-md-4 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="CountryId"
                  no-min-width
                  type="number"
                  size="md"
                  max-length="4"
                  id="countryId"
                  label="{{ shared.translate('Country ID') }}"
                  label-position="outside"
                  placeholder=""
                  state="{{
                    general['CountryId'].touched && general['CountryId'].errors
                      ? 'error'
                      : ''
                  }}"
                  (focusout)="general['CountryId'].markAsTouched()"
                  helper="{{
                    general['CountryId'].touched && general['CountryId'].errors
                      ? 'Required field'
                      : ''
                  }}"
                ></tds-text-field>
              </div>
              <div class="tds-col-lg-3 tds-col-md-4 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="ScobProductTypeId"
                  no-min-width
                  type="number"
                  size="md"
                  id="controlName"
                  label="{{ shared.translate('Product Type Id (SCOB)') }}"
                  label-position="outside"
                  state="{{
                    general['ScobProductTypeId'].touched &&
                    general['ScobProductTypeId'].errors
                      ? 'error'
                      : ''
                  }}"
                  (focusout)="general['ScobProductTypeId'].markAsTouched()"
                  helper="{{
                    general['ScobProductTypeId'].touched &&
                    general['ScobProductTypeId'].errors
                      ? 'Required field'
                      : ''
                  }}"
                ></tds-text-field>
              </div>
            </div>
          </tds-block>
        </tds-block>

        <tds-block>
          <tds-block formGroupName="Specification">
            <div class="tds-row">
              <div class="tds-col-md-10 tds-col-xs-10 tds-u-flex">
                <img
                  style="width: 30px; margin-left: 3px"
                  src="../../../../../assets/images/transmission.svg"
                />
                <h5>
                  {{ shared.translate("Specification") }}
                </h5>
              </div>
            </div>

            <div class="tds-row">
              <div class="tds-col-lg-3 tds-col-md-3 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="System"
                  no-min-width
                  type="text"
                  size="md"
                  id="specSystem"
                  label="{{ shared.translate('System') }}"
                  label-position="outside"
                ></tds-text-field>
              </div>
              <div class="tds-col-lg-3 tds-col-md-3 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="Screen"
                  no-min-width
                  type="text"
                  size="md"
                  id="Screen"
                  label="{{ shared.translate('Screen') }}"
                  label-position="outside"
                  placeholder="{{ shared.translate('') }}"
                ></tds-text-field>
              </div>
              <div class="tds-col-lg-3 tds-col-md-3 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="MarketId"
                  no-min-width
                  type="number"
                  size="md"
                  id="MarketId"
                  label="{{ shared.translate('Market Id') }}"
                  label-position="outside"
                ></tds-text-field>
              </div>
              <div class="tds-col-lg-3 tds-col-md-3 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="MarketName"
                  no-min-width
                  type="text"
                  size="md"
                  id="MarketName"
                  label="{{ shared.translate('Market Name') }}"
                  label-position="outside"
                ></tds-text-field>
              </div>
            </div>
            <div class="tds-row">
              <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-6 tds-col-xs-6">
                <tds-dropdown
                  ngDefaultControl
                  formControlName="IsBusinessUnit"
                  id="isBusinessUnit"
                  #business
                  (tdsSelect)="setDropdown('business', $event)"
                  label="{{ shared.translate('Is Business Unit') }}"
                  label-position="outside"
                  placeholder="{{ shared.translate('Select') }}"
                  helper=""
                  size="md"
                  (tdsSelect)="setDropdown('business', $event)"
                  open-direction="down"
                >
                  <tds-dropdown-option
                    *ngFor="let business of isBusinessList"
                    value="{{ business.Value }}"
                    >{{ business.Description }}</tds-dropdown-option
                  >
                </tds-dropdown>
              </div>
              <div class="tds-col-lg-4 tds-col-md-4 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  no-min-width
                  ngDefaultControl
                  formControlName="OrderMarket"
                  type="text"
                  size="md"
                  id="orderMarket"
                  label="{{ shared.translate('Order Market') }}"
                  label-position="outside"
                  placeholder=""
                ></tds-text-field>
              </div>
              <div class="tds-col-md-4 tds-col-sm-4 tds-col-xs-6">
                <tds-dropdown
                  name="dropdown"
                  ngDefaultControl
                  formControlName="IsoCountryCode"
                  id="IsoCountryCode"
                  #country
                  (tdsSelect)="setDropdown('country', $event)"
                  label="{{ shared.translate('Iso Code') }}"
                  label-position="outside"
                  placeholder="{{ shared.translate('Select') }}"
                  helper=""
                  size="md"
                  open-direction="down"
                >
                  <tds-dropdown-option
                    *ngFor="let country of countryList"
                    value="{{ country.Code }}"
                    >{{ country.Code }}</tds-dropdown-option
                  >
                </tds-dropdown>
              </div>
            </div>

            <div class="tds-row">
              <div class="tds-col-md-3 tds-col-sm-3 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="LanguageId"
                  no-min-width
                  type="text"
                  size="md"
                  id="LanguageId"
                  label="{{ shared.translate('Lang Id') }}"
                  label-position="outside"
                ></tds-text-field>
              </div>
              <div class="tds-col-md-3 tds-col-sm-3 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="UserId"
                  no-min-width
                  type="text"
                  size="md"
                  id="UserId"
                  label="{{ shared.translate('User Id') }}"
                  label-position="outside"
                ></tds-text-field>
              </div>
              <div class="tds-col-md-3 tds-col-sm-3 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="DefaultViewId"
                  no-min-width
                  type="text"
                  size="md"
                  id="DefaultViewId"
                  label="{{ shared.translate('View Id') }}"
                  label-position="outside"
                ></tds-text-field>
              </div>
              <div class="tds-col-md-3 tds-col-sm-3 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="ConfigTypeId"
                  no-min-width
                  type="text"
                  size="md"
                  id="ConfigTypeId"
                  label="{{ shared.translate('Config Id') }}"
                  label-position="outside"
                ></tds-text-field>
              </div>
            </div>
          </tds-block>
        </tds-block>

        <tds-block>
          <tds-block formGroupName="Price">
            <div class="tds-row">
              <div class="tds-col-md-10 tds-col-xs-10 tds-u-flex">
                <img
                  style="width: 30px; margin-left: 3px"
                  src="../../../../../assets/images/finance.svg"
                />
                <h5>
                  {{ shared.translate("Price") }}
                </h5>
              </div>
            </div>

            <div class="tds-row">
              <div class="tds-col-lg-3 tds-col-md-3 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="System"
                  no-min-width
                  type="text"
                  size="md"
                  id="priceSystem"
                  label="{{ shared.translate('Price System') }}"
                  label-position="outside"
                ></tds-text-field>
              </div>
              <div class="tds-col-lg-3 tds-col-md-3 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="MarketCode"
                  no-min-width
                  type="text"
                  size="md"
                  id="MarketCode"
                  label="{{ shared.translate('Market Code') }}"
                  label-position="outside"
                  placeholder="{{ shared.translate('') }}"
                ></tds-text-field>
              </div>
              <div class="tds-col-md-6 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="FactoryInvoiceTypeId"
                  no-min-width
                  type="text"
                  size="md"
                  id="FactoryInvoiceTypeId"
                  label="{{
                    shared.translate(
                      'Invoice Type Id (Distributor Net / Factory Price)'
                    )
                  }}"
                  label-position="outside"
                  placeholder="{{ shared.translate('') }}"
                ></tds-text-field>
              </div>
            </div>
            <div class="tds-row">
              <div class="tds-col-md-4 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="FactoryMarketTypeId"
                  no-min-width
                  type="text"
                  size="md"
                  id="FactoryMarketTypeId"
                  label="{{ shared.translate('Market Type Id (Producer)') }}"
                  label-position="outside"
                  placeholder="{{ shared.translate('') }}"
                ></tds-text-field>
              </div>
              <div class="tds-col-md-4 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="DealerTypeId"
                  no-min-width
                  type="number"
                  size="md"
                  id="FactoryMarketTypeId"
                  label="{{ shared.translate('Dealer Price Id (Dealer Net)') }}"
                  label-position="outside"
                  placeholder="{{ shared.translate('') }}"
                ></tds-text-field>
              </div>
              <div class="tds-col-md-4 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="RetailTypeId"
                  id="RetailTypeId"
                  no-min-width
                  type="text"
                  size="md"
                  max-length="2"
                  id="controlName"
                  label="{{
                    shared.translate('Retail Price Id (Dealer List)')
                  }}"
                  label-position="outside"
                  placeholder=""
                ></tds-text-field>
              </div>
            </div>
          </tds-block>
        </tds-block>

        <tds-block>
          <tds-block formGroupName="IacobParameters">
            <div class="tds-row">
              <div class="tds-col-md-10 tds-col-xs-10 tds-u-flex">
                <img
                  style="width: 30px; margin-left: 3px"
                  src="../../../../../assets/images/factory.svg"
                />
                <h5>
                  {{ shared.translate("IACOB") }}
                </h5>
              </div>
            </div>

            <div class="tds-row">
              <div class="tds-col-md-6 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="MarketId"
                  no-min-width
                  type="text"
                  size="md"
                  id="iacobMarketId"
                  label="{{ shared.translate('Market Numeric Id') }}"
                  label-position="outside"
                ></tds-text-field>
              </div>
              <div class="tds-col-md-6 tds-col-sm-6 tds-col-xs-6">
                <tds-text-field
                  ngDefaultControl
                  formControlName="ParameterId"
                  no-min-width
                  type="text"
                  size="md"
                  id="iacobParameterId"
                  label="{{ shared.translate('Market Param Id') }}"
                  label-position="outside"
                  placeholder="{{ shared.translate('') }}"
                ></tds-text-field>
              </div>
            </div>
          </tds-block>
        </tds-block>
      </form>
    </span>
    <span slot="actions">
      <div class="tds-row">
        <div class="tds-col-xs-6">
          <tds-button
            type="button"
            variant="danger"
            size="sm"
            (click)="
              close(
                newMarketParam,
                vehicleTypeId,
                currency,
                generalSystem,
                business,
                country
              )
            "
            text="{{ shared.translate('Cancel') }}"
            data-dismiss-modal
          ></tds-button>
        </div>
        <div class="tds-col-xs-6 save-button" id="save-button">
          <tds-button
            type="button"
            variant="primary"
            size="sm"
            text="{{ this.shared.translate('Save') }}"
            (click)="save(newMarketParam)"
          ></tds-button>
        </div>
      </div>
    </span>
  </tds-modal>
</div>
