<div id="principal">
  <div id="loading">
    <tds-spinner size="md" variant="standard"></tds-spinner>
  </div>
  <tds-toast id="toastComponent" variant="success" header="Success!" subheader="Subheader" hidden></tds-toast>

  <div class="container">
    <div
      *ngIf="musys.api.includes('musys-api-dev') || musys.api.includes('musys-api-acp') || musys.api.includes('localhost')"
      [ngStyle]="{'border-top' : musys.api.includes('musys-api-dev') || musys.api.includes('localhost')  ? '50px solid #418bca' : '53px solid #ffcc00'}"
      class="corner">
      <span
        [ngStyle]="{'top': musys.api.includes('musys-api-acp') ? '-43px' : '-40px'}">{{musys.api.includes('musys-api-dev')
        || musys.api.includes('localhost') ? 'DEV' : 'ACP'}}</span>
    </div>
  </div>
  <app-header></app-header>

  <div class="tds-u-mt2 tds-u-ml2 tds-u-mb2">
    <xng-breadcrumb separator="›" style="margin-bottom: 2em;">
      <ng-container *xngBreadcrumbItem="let breadcrumb">
        <ng-container>{{ breadcrumb }}</ng-container>
      </ng-container>
    </xng-breadcrumb>
  </div>
    <router-outlet></router-outlet>

  <app-footer></app-footer>
</div>

<div id="loadingScreen" class="align-center justify-center" style="height: 100%; width: 100%;">
  <div>
    <div class="tds-row align-center justify-center">
      <tds-spinner variant="standard"></tds-spinner>
    </div>
    <div class="tds-row tds-u-textalign-center">
      <h4>{{shared.translate('Loading Musys, please wait...')}}</h4>
    </div>
  </div>
</div>