import { Component } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DealerUsersResponse } from 'src/app/models/dealerusersresponse.model';
import { AccessService } from 'src/app/service/api/access.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-dealer-access',
  templateUrl: './dealer-access.component.html',
  styleUrls: ['./dealer-access.component.css']
})
export class DealerAccessComponent {

  partnerId: number = parseInt(this.shared.getPartnerId());

  dealerFilter: string = '';
  userRoleFilter: string = '';

  dealerList: DealerUsersResponse[];
  profileAccess: any[] = [];
  users: any[] = [];

  selectedDealer: DealerUsersResponse;

  formulario: FormGroup;
  get form() {
    return this.formulario.controls;
  }

  ngOnInit() {
    this.getDealerUsers();
    this.getProfileAccess();
  }

  constructor(public shared: SharedService, private fb: FormBuilder, private access: AccessService) {

  }

  changeTab(event) {
    switch (event.detail.selectedTabIndex) {
      case 0:
        document.getElementById('dealerAccess').style.display = 'block';
        document.getElementById('userRoles').style.display = 'none';
        break;
      case 1:
        document.getElementById('dealerAccess').style.display = 'none';
        document.getElementById('userRoles').style.display = 'block';
        break;
    }
  }

  setDealerAccessHeaderNames() {
    document.getElementById('dealer').setAttribute('column-title', this.shared.translate('Dealer'));
    document.getElementById('users').setAttribute('column-title', this.shared.translate('Users'));
  }

  setDealerAccessTableValues(dealer: DealerUsersResponse) {
    document.getElementById(`rowDealer${dealer.Code}`).setAttribute('cell-value', dealer.Code + ' - ' + dealer.Name);
    document.getElementById(`rowUsers${dealer.Code}`).setAttribute('cell-value', dealer.UsersList);
  }

  setUserRolesHeaderNames(profileName) {
    document.getElementById(`ur${profileName}`).setAttribute('column-title', this.shared.translate(profileName));
  }

  setUserRolesTableValues(user: any, profileName: string) {
    // Tem usuario vindo sem Username então é bom fazer a verificação pra não dar pau
    if (user.Username) {
      document.getElementById(`rowUr${profileName}${user?.Username}`).setAttribute('cell-value', user.Username + ' - ' + user.Name);
    }
    }

  setEditHeaderNames() {
    document.getElementById('editUsername').setAttribute('column-title', this.shared.translate('Dealer'));
    document.getElementById('editName').setAttribute('column-title', this.shared.translate('Users'));
  }

  setEditTableValues(index: number) {
    document.getElementById(`rowEditUsername${index}`).setAttribute('cell-value', this.selectedDealer.Users[index].Name);
    document.getElementById(`rowEditName${index}`).setAttribute('cell-value', this.selectedDealer.Users[index].Username);
  }

  getDealerUsers(partnerId?: number, username?: any) {
    this.access.getDealerUsers().subscribe({
      next: (result) => {
        this.dealerList = result.Data
        this.dealerList.forEach(dealer => {

          dealer.UsersList = '';
          if (dealer.Users.length > 0) {
            for (let i = 0; i < dealer.Users.length; i++) {
              var x = (i + 5) % 5;
              if (x == 0) {
                dealer.UsersList += '\n'
              }
              dealer.UsersList += dealer.Users[i].Username ? dealer.Users[i].Username + ';' : '';
            }
            dealer.UsersList = dealer.UsersList.slice(0, -1);
          }
        })
        this.dealerList.forEach(dealer => {
          dealer.Users.forEach(user => {
            if (!this.users.find(u => u.Id == user.Id)) {
              this.users.push(user)
            }
          })
        })

        if (partnerId) {
          this.selectedDealer = this.dealerList.find(d => d.Id == partnerId)
          username.value = '';
          this.shared.successAlert(this.shared.translate("User added successfully"))
        }
      },
      error: () => {
        this.shared.errorAlert(this.shared.translate('Error getting dealers!'));
      }
    })
  }

  grantDealerUser(partnerId: number, username) {
    if (!username.value) {
      this.shared.warningAlert(this.shared.translate('User must be informed.'), 'sm');
      return
    }

    var request = {
      partnerId: partnerId,
      user: username.value
    }
    this.access.GrantDealerUser(request).subscribe({
      next: (result) => {
        if (result.Data) {
          this.getDealerUsers(partnerId, username);
        } else if (result.Errors) {
          this.shared.translate(result.Errors[0]);
        }
      },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate('Error while adding user!'));
      }
    })
  }

  revokeDealer(partnerId: number, profileId: number, user: string) {
    this.access.revokeUserToProfile(partnerId, profileId, user).subscribe({
      next: (result) => {
        if (result.Data) {
          this.selectedDealer.Users.splice(this.selectedDealer.Users.findIndex(u => u.Username == user), 1)
          var userNameToRemove = this.selectedDealer.UsersList.includes(`${user};`) ? `${user};` : this.selectedDealer.UsersList.includes(`;${user}`) ? `;${user}` : user
          this.dealerList.find(d => d.Id == this.selectedDealer.Id).UsersList = this.dealerList.find(d => d.Id == this.selectedDealer.Id).UsersList.replace(userNameToRemove, "");
          this.shared.successAlert(this.shared.translate("User removed successfully"), 'sm')
        } else {
          this.shared.errorAlert(this.shared.translate("User not found!"));
        }
      },
      error: () => {
        this.shared.errorAlert(this.shared.translate('Error while revoking user!'));
      }
    })
  }

  getProfileAccess() {
    this.access.getProfileAccess().subscribe({
      next: (result) => {
        this.profileAccess = result.Data.filter(p => p.Type == 'DEALER');
      }
    })
  }

  openModal(modal: any, dealer: DealerUsersResponse) {
    this.selectedDealer = dealer;
    modal.showModal();
  }

  hasProfile(user, profile) {
    if (profile.Usernames.includes(user.Username)) {
      return true;
    }
    return false;
  }

  grantUserProfile(profileId: number, username: any, checkbox) {
    var request = {
      partnerId: this.partnerId,
      profileId: profileId,
      user: username
    }
    
    this.access.GrantUserToProfile(request).subscribe({
      next:(result) => {
        if (result.Data) {
          this.shared.useToast('Success!', this.shared.translate("Profile granted to " + username), 'success');
        }else if (result.Errors) {
          this.shared.useToast('Error!', this.shared.translate(result.Errors[0]), 'error');
        }
      },
      error: (error) => {
        this.shared.useToast('Error!', error.message, 'error');
        checkbox.checked = false;
      }
    })
  }

  revokeUserProfile(profileId, username, checkbox) {
    this.access.revokeUserToProfile(this.partnerId, profileId, username).subscribe({
      next: (result) => {
        if (result.Data) {
          this.shared.useToast('Success!', this.shared.translate("Revoked profile of " + username), 'success');

        } else {
          this.shared.useToast('Error!', result.Errors[0], 'error');
        }
      },
      error: (error) => {
        this.shared.useToast('Error!', error.message, 'error');
        checkbox.checked = true;
      }
    })
  }

  profileChange(checkbox, profileId, username) {
    if (checkbox.checked) {
      this.grantUserProfile(profileId, username, checkbox)
    }else {
      this.revokeUserProfile(profileId, username, checkbox);
    }
  }

}
