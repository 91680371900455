<div class="tds-container">
    <tds-block>
        <div class="tds-row">
            <div class="tds-col-md-10">
                <h3>Partner Information</h3>
            </div>
            <div class="tds-col-md-2 tds-col-xs-12 align-center">
                <tds-button class="tds-u-mr1" type="button" variant="secondary" size="sm"
                    text="{{shared.translate('Clear')}}"
                    (click)="clearInputs(partnerName, docType, docNumber, mainType)"></tds-button>
                <tds-button type="button" class="partnerModalButton" variant="primary" size="sm"
                    text="{{shared.translate('New')}}" (click)="requestType='POST'"></tds-button>
            </div>
        </div>
        <tds-block>
            <div class="tds-row" style="position: relative;"
                [ngStyle]="{ 'filter': searchStatus ? 'blur(0.5px)' : 'none' }">
                <div class="tds-col-md-1 tds-col-xs-12">
                    <div class="tds-row">
                        <span class="letter-adjust">{{shared.translate('MU')}}</span>
                    </div>
                    <div class="tds-row">
                        <h6>{{marketUnit}}</h6>
                    </div>
                </div>
                <div class="tds-col-md tds-col-xs-12">
                    <tds-text-field (keydown.enter)="search()" no-min-width #partnerName (keyup)="changeValue('partnerName', $event)"
                        size="sm" placeholder="{{shared.translate('Partner Name')}}"
                        label="{{shared.translate('Partner Name')}}" label-position="outside">
                    </tds-text-field>
                </div>
                <div class="tds-col-md tds-col-xs-12">
                    <tds-dropdown #docType (tdsSelect)="changeValue('documentType', $event)" size="sm"
                        placeholder="{{shared.translate('Select')}}" label="{{shared.translate('Doc. Type')}}"
                        label-position="outside">
                        <tds-dropdown-option *ngFor="let document of documentList"
                            value="{{document.DocumentType}}">{{document.DocumentType}}</tds-dropdown-option>
                    </tds-dropdown>
                </div>
                <div class="tds-col-md tds-col-xs-12  ">
                    <tds-text-field (keydown.enter)="search()" #docNumber no-min-width
                        (keyup)="changeValue('documentNumber', $event)" [disabled]="documentType == ''" size="sm"
                        placeholder="{{shared.translate('Doc Number')}}" label="{{shared.translate('Doc Number')}}"
                        label-position="outside">
                    </tds-text-field>
                </div>
                <div class="tds-col-md tds-col-xs-12">
                    <tds-dropdown #mainType (tdsSelect)="changeValue('mainType', $event)" size="sm"
                        placeholder="{{shared.translate('Select')}}" label="{{shared.translate('Main Type')}}"
                        label-position="outside">
                        <tds-dropdown-option *ngFor="let type of typeList"
                            value="{{type.Type}}">{{type.Description}}</tds-dropdown-option>
                    </tds-dropdown>
                </div>
                <div id="searchButton" class="tds-col-md tds-col-xs-12">
                    <tds-button type="button" variant="primary" size="sm" text="{{shared.translate('Search')}}"
                        (click)="search()"></tds-button>
                </div>
            </div>
            <div style="width:208px">
            </div>

            <!-- Table Results -->
            <tds-table table-id="pagination-table" no-min-width vertical-dividers="false" compact-design="true"
                responsive="true">
                <tds-table-header>
                    <tds-header-cell id="name" column-title="{{setHeaderNames()}}"></tds-header-cell>
                    <tds-header-cell id="type" column-title=""></tds-header-cell>
                    <tds-header-cell id="creationDate" column-title=""></tds-header-cell>
                    <tds-header-cell id="inactiveDate" column-title=""></tds-header-cell>
                    <tds-header-cell id="edit" column-title=""></tds-header-cell>
                    <tds-header-cell id="delete" column-title=""></tds-header-cell>
                </tds-table-header>
                <!-- tá com slice pra não carregar tudo de uma vez pq se for mt dado ele trava. Tem que mexer quando decidir se vai filtrar a qtd pela API ou por aqui -->
                <tds-table-body-row *ngFor="let partner of partnerList.slice(0, 25); let i = index">
                    <tds-body-cell id="rowName{{i}}"></tds-body-cell>
                    <tds-body-cell id="rowType{{i}}"></tds-body-cell>
                    <tds-body-cell id="rowCreationDate{{i}}"></tds-body-cell>
                    <tds-body-cell id="rowInactiveDate{{i}}"></tds-body-cell>
                    <tds-body-cell id="rowEdit{{i}}">
                        <img (click)="editPartner(partner.Id, partnerModal)" class="editPartnerButton"
                            style="width: 25px; margin-left: 10px; cursor: pointer"
                            src="../../../../../assets/images/edit.svg" id="EditPartner{{partner.Id}}">

                        <tds-tooltip placement="bottom" selector="#EditPartner{{partner.Id}}"
                            text="{{shared.translate('Edit')}}" mouse-over-tooltip="true"> </tds-tooltip>
                    </tds-body-cell>
                    <tds-body-cell id="rowDelete{{i}}" cell-value="{{setTableValues(i)}}">
                        <img (click)="removePartner(partner.Id, partner.IsCustomerBr)" class="deletePartnerButton"
                            style="width: 25px; margin-left: 10px; cursor: pointer"
                            src="../../../../../assets/images/trash.svg" id="DeletePartner{{partner.Id}}">

                        <tds-tooltip placement="bottom" selector="#DeletePartner{{partner.Id}}"
                            text="{{shared.translate('Delete')}}" mouse-over-tooltip="true"> </tds-tooltip>
                    </tds-body-cell>
                </tds-table-body-row>
                <tds-table-footer pagination [pages]="Math.ceil(partnerList.length/26)" [paginationValue]="pagination"
                    (tdsPagination)="sim($event)"></tds-table-footer>
            </tds-table>
        </tds-block>
    </tds-block>
</div>

<tds-modal (tdsClose)="closeModal(partnerModal)" selector=".partnerModalButton" id="partnerModal" size="sm" #partnerModal actions-position="sticky">
    <span slot="body">
        <form [formGroup]="form">
            <tds-block>
                <tds-block>
                    <div class="tds-row">
                        <div class="tds-col-md-10 tds-u-flex">
                            <img style="width: 30px;" src="../../../../../assets/images/user.svg">
                            <h6>Partner Information</h6>
                        </div>
                    </div>

                    <div class="tds-row">
                        <div class="tds-col-md-5 tds-col-xs-12">
                            <div>
                                <tds-text-field read-only ngDefaultControl formControlName="Id" size="sm" no-min-width
                                    placeholder="{{shared.translate('Id')}}" label="{{shared.translate('Id')}}"
                                    label-position="outside">
                                </tds-text-field>
                            </div>
                        </div>
                    </div>
                    <div class="tds-row">
                        <div class="tds-col-md tds-col-xs-12">
                            <tds-text-field ngDefaultControl formControlName="Name" id="partnerName" size="sm"
                                placeholder="{{shared.translate('Complete Name')}}"
                                (focusout)="f['Name'].markAsTouched()"
                                state="{{f['Name'].touched && f['Name'].errors ? 'error' : ''}}"
                                label="{{shared.translate('Complete Name')}}" label-position="outside"
                                helper="{{f['Name'].touched && f['Name'].errors ? shared.translate('Required Field.') : ''}}">
                            </tds-text-field>
                        </div>
                    </div>
                    <div class="tds-row">
                        <div class="tds-col-md tds-col-xs-12">
                            <div>
                                <tds-datetime label="{{shared.translate('Creation Date')}}" formControlName="CreatedAt"
                                    ngDefaultControl no-min-width type="date" size="sm" id="createdAt">
                                </tds-datetime>
                            </div>
                        </div>
                        <div class="tds-col-md tds-col-xs-12">
                            <div>
                                <tds-datetime label="{{shared.translate('InactiveDate')}}" ngDefaultControl
                                    formControlName="InactiveDate" no-min-width type="date" size="sm" id="inactiveDate">
                                </tds-datetime>
                            </div>
                        </div>
                    </div>
                    <div class="tds-row" *ngIf="shared.isMusysBR()">
                        <div class="tds-col-md tds-col-xs-12">
                            <tds-dropdown #mainType id="mainType" (tdsSelect)="setDropdown($event)" ngDefaultControl
                                formControlName="Type" size="sm" placeholder="{{shared.translate('Main Type')}}"
                                label="{{shared.translate('Main Type')}}" label-position="outside"
                                [error]="f['Type'].touched && f['Type'].errors"
                                helper="{{f['Type'].touched && f['Type'].errors ? shared.translate('Selection is required.') : ''}}">
                                <tds-dropdown-option *ngFor="let type of typeList"
                                    value="{{type.Type}}">{{type.Description}}</tds-dropdown-option>
                            </tds-dropdown>
                        </div>
                        <div class="tds-col-md tds-col-xs-12  ">
                            <tds-dropdown #personIdentity id="personIdentity" ngDefaultControl
                                formControlName="PersonIdentity" (tdsSelect)="setDropdown($event)" size="sm"
                                placeholder="{{shared.translate('Person Identity')}}"
                                label="{{shared.translate('Person Identity')}}" label-position="outside"
                                [error]="f['PersonIdentity'].touched && f['PersonIdentity'].errors"
                                helper="{{f['PersonIdentity'].touched && f['PersonIdentity'].errors ? shared.translate('Selection is required.') : ''}}">
                                <tds-dropdown-option *ngFor="let personIdentity of personIdentityList"
                                    value="{{personIdentity.Code}}">{{personIdentity.Description}}</tds-dropdown-option>
                            </tds-dropdown>
                        </div>
                    </div>
                    <div *ngIf="!shared.isMusysBR()">
                        <div class="tds-row">
                            <div class="tds-col-md tds-col-xs-12  ">
                                <tds-dropdown ngDefaultControl formControlName="Type" (tdsSelect)="setDropdown($event)"
                                    #mainType id="mainType" size="sm" placeholder="{{shared.translate('Main Type')}}"
                                    label="{{shared.translate('Main Type')}}" label-position="outside"
                                    [error]="f['Type'].touched && f['Type'].errors"
                                    helper="{{f['Type'].touched && f['Type'].errors ? shared.translate('Selection is required.') : ''}}">
                                    <tds-dropdown-option *ngFor="let type of typeList"
                                        value="{{type.Type}}">{{type.Description}}</tds-dropdown-option>
                                </tds-dropdown>
                            </div>
                            <div formGroupName="Currency" class="tds-col-md tds-col-xs-12">
                                <tds-dropdown #currency id="currency" ngDefaultControl formControlName="Id" size="sm"
                                    (tdsSelect)="setCurrency($event)" placeholder="{{shared.translate('Currency')}}"
                                    label="{{shared.translate('Currency')}}" label-position="outside"
                                    [error]="f['Currency']['controls']['Id'].touched && f['Currency']['controls']['Id'].errors"
                                    helper="{{f['Currency']['controls']['Id'].touched && f['Currency']['controls']['Id'].errors ? shared.translate('Selection is required.') : ''}}">
                                    <tds-dropdown-option *ngFor="let currency of currencyList"
                                        value="{{currency.Id}}">{{currency.Description}}</tds-dropdown-option>
                                </tds-dropdown>
                            </div>
                        </div>
                        <div class="tds-row">
                            <div class="tds-col-md tds-col-xs-12">
                                <tds-text-field ngDefaultControl formControlName="LocalCode" id="code" size="sm"
                                    placeholder="{{shared.translate('Local Code')}}" (focusout)="f['LocalCode'].markAsTouched()"
                                    state="{{f['LocalCode'].touched && f['LocalCode'].errors ? 'error' : ''}}"
                                    label="{{shared.translate('Local Code')}}" label-position="outside"
                                    helper="{{f['LocalCode'].touched && f['LocalCode'].errors ? shared.translate('Required Field.') : ''}}">
                                </tds-text-field>
                            </div>
                        </div>
                    </div>
                    <div class="tds-row">
                        <div class="tds-col-md tds-col-xs-12">
                            <div class="tds-checkbox-item">
                                <tds-checkbox ngDefaultControl formControlName="CaptiveDealer" id="captiveDealer">
                                    <div slot="label">{{shared.translate('Captive Dealer')}}</div>
                                </tds-checkbox>
                            </div>
                        </div>
                    </div>
                </tds-block>

                <tds-block>
                    <div class="tds-card">
                        <div class="tds-card-header">
                            <div class="tds-row">
                                <div class="tds-col-md-10 tds-col-xs-10 tds-u-flex">
                                    <img style="width: 30px;" src="../../../../../assets/images/document.svg">
                                    <h6>Documents</h6>
                                </div>
                                <div class="tds-col-md-2 tds-col-xs-2 align-center">
                                    <img class="imgButtons" (click)="addDocument()"
                                        src="../../../../../assets/images/plus.svg">
                                </div>
                            </div>
                        </div>

                        <div class="tds-card-body" formArrayName="Documents">
                            <div class="tds-row" [formGroupName]="i"
                                *ngFor="let document of documents.controls; let i = index">
                                <div class="tds-col-md-4 tds-col-xs-12">
                                    <tds-dropdown id="document{{i}}" ngDefaultControl formControlName="Type"
                                        (tdsSelect)="setDocument($event, i)"
                                        selected-option="{{partnerInformation?.Documents[i]?.Type}}" id="documentType"
                                        size="sm" placeholder="{{partnerInformation?.Documents[i]?.Type}}"
                                        label="{{shared.translate('Type')}}" label-position="outside"
                                        [error]="documents.controls[i]['controls']['Type'].touched && documents.controls[i]['controls']['Type'].errors"
                                        helper="{{documents.controls[i]['controls']['Type'].touched && documents.controls[i]['controls']['Type'].errors ? shared.translate('Selection is required.') : ''}}">
                                        <tds-dropdown-option *ngFor="let docType of documentList"
                                            value="{{docType.DocumentType}}">{{docType.DocumentType}}</tds-dropdown-option>
                                    </tds-dropdown>
                                </div>
                                <div class="tds-col-md tds-col-xs-9">
                                    <tds-text-field ngDefaultControl formControlName="Number" id="documentNumber"
                                        size="sm" no-min-width placeholder="{{shared.translate('Number')}}"
                                        (focusout)="documents.controls[i]['controls']['Number'].markAsTouched()"
                                        state="{{documents.controls[i]['controls']['Number'].touched && documents.controls[i]['controls']['Number'].errors ? 'error' : ''}}"
                                        helper="{{documents.controls[i]['controls']['Number'].touched && documents.controls[i]['controls']['Number'].errors ? shared.translate('Required Field.') : ''}}"
                                        label="{{shared.translate('Number')}}" label-position="outside">
                                    </tds-text-field>
                                </div>
                                <div class="tds-col-md-2 tds-col-xs-3 align-center">
                                    <img class="imgButtons" style="margin-top: 16px;" (click)="removeDocument(i)"
                                        src="../../../../../assets/images/minus.svg">
                                </div>
                            </div>
                        </div>
                    </div>
                </tds-block>

                <tds-block>
                    <div formGroupName="Address">
                        <div class="tds-card">
                            <div class="tds-card-header">
                                <div class="tds-row">
                                    <div class="tds-col-md-10 tds-u-flex">
                                        <img style="width: 30px;" src="../../../../../assets/images/pin.svg">
                                        <h6>Address</h6>
                                    </div>
                                </div>
                            </div>
                            <div class="tds-card-body">
                                <div class="tds-row">
                                    <div class="tds-col-md tds-col-xs-12">
                                        <tds-text-field id="address" size="sm" ngDefaultControl
                                            formControlName="Description" placeholder="{{shared.translate('Address')}}"
                                            (focusout)="address['Description'].markAsTouched()"
                                            state="{{address['Description'].touched && address['Description'].errors ? 'error' : ''}}"
                                            helper="{{address['Description'].touched && address['Description'].errors ? shared.translate('Required Field.') : ''}}"
                                            label="{{shared.translate('Address')}}" label-position="outside">
                                        </tds-text-field>
                                    </div>
                                </div>
                                <div class="tds-row">
                                    <div class="tds-col-md tds-col-xs-12">
                                        <tds-dropdown #addresType ngDefaultControl formControlName="Type"
                                            (tdsSelect)="setAddressType($event)" size="sm" open-direction="up"
                                            placeholder="{{shared.translate('Type')}}" id="addressType"
                                            label="{{shared.translate('Type')}}" label-position="outside"
                                            [error]="address['Type'].touched && address['Type'].errors"
                                            helper="{{address['Type'].touched && address['Type'].errors ? shared.translate('Selection is required.') : ''}}">
                                            <tds-dropdown-option *ngFor="let address of addressTypeList"
                                                value="{{address.Id}}">{{address.TypeDescription}}</tds-dropdown-option>
                                        </tds-dropdown>
                                    </div>
                                    <div class="tds-col-md tds-col-xs-12">
                                        <tds-dropdown ngDefaultControl formControlName="CountryCode" #country
                                            id="country" (tdsSelect)="getStates($event)" size="sm"
                                            placeholder="{{shared.translate('Selecione')}}"
                                            label="{{shared.translate('Country')}}" label-position="outside"
                                            [error]="address['CountryCode'].touched && address['CountryCode'].errors"
                                            helper="{{address['CountryCode'].touched && address['CountryCode'].errors ? shared.translate('Selection is required.') : ''}}">
                                            <tds-dropdown-option *ngFor="let country of countryList"
                                                value="{{country.Code}}">{{country.Name}}</tds-dropdown-option>
                                        </tds-dropdown>
                                    </div>
                                </div>
                                <div class="tds-row">
                                    <div class="tds-col-md tds-col-xs-12">
                                        <tds-dropdown ngDefaultControl formControlName="StateCode" #state id="state"
                                            (tdsSelect)="getCities($event, city)" size="sm"
                                            placeholder="{{shared.translate('Select')}}"
                                            label="{{shared.translate('State')}}" label-position="outside"
                                            [error]="address['StateCode'].touched && address['StateCode'].errors"
                                            helper="{{address['StateCode'].touched && address['StateCode'].errors ? shared.translate('Selection is required.') : ''}}">
                                            <tds-dropdown-option *ngFor="let state of stateList"
                                                value="{{state.Code}}">{{state.Name}}</tds-dropdown-option>
                                        </tds-dropdown>
                                    </div>
                                    <div class="tds-col-md tds-col-xs-12">
                                        <tds-dropdown ngDefaultControl formControlName="CityCode" #city id="city"
                                            (tdsSelect)="setCity($event)" size="sm"
                                            placeholder="{{shared.translate('Select')}}"
                                            label="{{shared.translate('City')}}" label-position="outside"
                                            [error]="address['CityCode'].touched && address['CityCode'].errors"
                                            helper="{{address['CityCode'].touched && address['CityCode'].errors ? shared.translate('Selection is required.') : ''}}">
                                            <tds-dropdown-option *ngFor="let city of cityList"
                                                value="{{city.CityCode.toString()}}">{{city.Name}}</tds-dropdown-option>
                                        </tds-dropdown>
                                    </div>
                                </div>
                                <div class="tds-row">
                                    <div class="tds-col-md tds-col-xs-12">
                                        <tds-text-field ngDefaultControl formControlName="PostalCode" size="sm"
                                            id="zipCode" no-min-width placeholder="{{shared.translate('Zip Code')}}"
                                            label="{{shared.translate('Zip Code')}}" label-position="outside">
                                        </tds-text-field>
                                    </div>
                                    <div class="tds-col-md tds-col-xs-12">
                                        <tds-text-field ngDefaultControl formControlName="Phone" size="sm"
                                            id="phoneNumber" no-min-width
                                            placeholder="{{shared.translate('Phone Number')}}"
                                            label="{{shared.translate('Phone Number')}}" label-position="outside">
                                        </tds-text-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tds-block>
            </tds-block>
        </form>
    </span>

    <span slot="actions">
        <tds-button size="md" text="{{shared.translate('Save')}}" (click)="savePartner(partnerModal)"></tds-button>
        <tds-button class="tds-u-ml1" size="md" text="{{shared.translate('Cancel')}}" (click)="closeModal(partnerModal)"
            type="danger" variant="danger"></tds-button>
    </span>
</tds-modal>