import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DealerUsersResponse } from 'src/app/models/dealerusersresponse.model';
import { Language } from 'src/app/models/language.model';
import { ProductSeries } from 'src/app/models/models/productseries.model';
import { Result } from 'src/app/models/result.model';
import { musys } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AccessService {
  constructor(private http: HttpClient) { }

  getDealerAdmin():Observable<Result<DealerUsersResponse[]>> {
    return this.http.get<Result<DealerUsersResponse[]>>(musys.api + '/access/dealer-admin');    
  }

  getDealerUsers():Observable<Result<DealerUsersResponse[]>> {
    return this.http.get<Result<DealerUsersResponse[]>>(musys.api + '/access/dealer-users');    
  }

  getProfileAccess(): Observable<Result<any[]>> {
    return this.http.get<Result<DealerUsersResponse[]>>(musys.api + '/access/profile-access');    
  }

  getUserProductSeries(partnerId: number) {
    return this.http.get<Result<ProductSeries[]>>(musys.api + `/access/user-product-series?partnerId=${partnerId}`);
  }
  
  GrantDealerAdmin(request) {
    return this.http.put<Result<boolean>>(musys.api + `/access/grant-dealer-admin`, request); 
  }

  revokeUserToProfile(partnerId: number, profileId: number, user: string) {
    return this.http.delete<Result<boolean>>(musys.api + `/access/revoke-user-to-profile?partnerId=${partnerId}&profileId=${profileId}&user=${user}`); 
  }

  GrantDealerUser(request) {
    return this.http.put<Result<boolean>>(musys.api + `/access/grant-dealer-user`, request); 
  }

  GrantUserToProfile(request) {
    return this.http.put<Result<boolean>>(musys.api + `/access/grant-user-to-profile`, request); 
  }

  getLanguages(): Observable<Result<Language[]>> {
    return this.http.get<Result<Language[]>>(
      musys.api + '/access/languages'
    );
  }
}
