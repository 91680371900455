<div class="tds-container">
  <tds-block>
    <div class="tds-row wrap-adjust">
      <div class="tds-col-md-12 sdds-col-sm-12 sdds-col-xs-12">
        <h2 class="tds-headline-02">
          {{ this.shared.translate("Exchange Rate") }}
        </h2>
      </div>
    </div>
    <tds-block>
      <div class="tds-row">
        <div class="tds-col-max-11 tds-col-md-11 tds-col-sm-11 tds-col-xs-11">
          <!-- Caso tenha coisa na lista -->
          <tds-dropdown
            label="{{ shared.translate('Rate Type') }}"
            *ngIf="rateTypesList.length > 0"
            label-position="outside"
            placeholder="Select"
            size="sm"
            id="rateTypeDropdown"
            name="dropdown"
            open-direction="down"
            style="z-index: 101"
            (tdsSelect)="changeValue('type', $event)"
          >
            <tds-dropdown-option
              *ngFor="let rateType of rateTypesList"
              value="{{ rateType.Code }}"
              >{{ rateType.Description }}</tds-dropdown-option
            >
          </tds-dropdown>
          <!-- Caso não tenha coisa na lista -->
          <tds-dropdown
            label="{{ shared.translate('Rate Type') }}"
            label-position="outside"
            placeholder="Select"
            *ngIf="rateTypesList.length == 0"
            disabled
            size="sm"
            id="rateTypeDropdown"
            error="true"
            name="dropdown"
            helper="{{ shared.translate('No exchange rate types found!') }}"
            open-direction="down"
            style="z-index: 101"
          >
            <tds-dropdown-option
              *ngFor="let rateType of rateTypesList"
              value="{{ rateType.Code }}"
              >{{ rateType.Description }}</tds-dropdown-option
            >
          </tds-dropdown>
        </div>
        <div
          class="tds-col-max-1 tds-col-md-1 tds-col-sm-1 tds-col-xs-1 align-items" *ngIf="rateTypesList.length > 0"
        >
          <tds-button
            type="button"
            variant="secondary"
            size="sm"
            text="{{ shared.translate('Open') }}"
            (click)="getListExchangeRate()"
          ></tds-button>
        </div>
      </div>

      <div class="tds-row tds-u-mt2">
        <div class="tds-col-md-11 sdds-col-sm-11 sdds-col-xs-11">
          <h3 class="tds-headline-03">
            {{ this.shared.translate("Exchange Rates item") }}
          </h3>
        </div>
        <div class="tds-col-md-1 tds-col-sm-1 tds-col-xs-1 align-items">
          <tds-button
            type="button"
            (click)="createExchangeRateForm()"
            variant="primary"
            size="sm"
            id="newExchangeRate"
            text="{{ shared.translate('New') }}"
            disabled
          >
          </tds-button>
        </div>
      </div>

      <div id="resultsRate" *ngIf="this.rateList.length > 0">
        <div class="tds-row tds-u-mt2">
          <div class="tds-col-md-11 sdds-col-sm-11 sdds-col-xs-11">
            <h4 class="tds-headline-04">
              {{ rateTitle }}
            </h4>
          </div>
        </div>
        <div class="tds-row tds-u-mt2">
          <div class="tds-col-max-10 tds-col-md-10 tds-col-sm-10 tds-col-xs-10">
            <tds-table
              class="overflow"
              vertical-dividers="false"
              compact-design="true"
              responsive="true"
              no-min-width
            >
              <tds-table-header>
                <tds-header-cell
                  id="dateRate"
                  column-title="{{ setHeaderNames() }}"
                ></tds-header-cell>
                <tds-header-cell
                  id="currencyF"
                  column-title=""
                ></tds-header-cell>
                <tds-header-cell
                  id="currencyT"
                  column-title=""
                ></tds-header-cell>
                <tds-header-cell id="rate" column-title=""></tds-header-cell>
                <tds-header-cell id="edit" column-title=""></tds-header-cell>
                <tds-header-cell id="delete" column-title=""></tds-header-cell>
              </tds-table-header>
              <tds-table-body>
                <tds-table-body-row
                  *ngFor="let rateItem of rateList.slice(0, 25); let i = index"
                >
                  <tds-body-cell id="rowDate{{ i }}"></tds-body-cell>
                  <tds-body-cell id="rowCurrencyF{{ i }}"></tds-body-cell>
                  <tds-body-cell id="rowCurrencyT{{ i }}"></tds-body-cell>
                  <tds-body-cell id="rowRate{{ i }}"></tds-body-cell>
                  <tds-body-cell id="rowEdit{{ i }}">
                    <img
                      class="tableButtons"
                      (click)="editRate(rateItem, newExchangeRate)"
                      id="updRate{{ i }}"
                      src="../../../../../assets/images/edit.svg"
                    />
                    <tds-tooltip
                      placement="bottom"
                      selector="#updRate{{ i }}"
                      text="{{ shared.translate('Edit') }}"
                      mouse-over-tooltip="true"
                    />
                  </tds-body-cell>
                  <tds-body-cell
                    id="rowDelete{{ i }}"
                    cell-value="{{ setTableValues(i) }}"
                  >
                    <img
                      class="tableButtons"
                      (click)="deleteRate(rateItem)"
                      src="../../../../../assets/images/trash.svg"
                      id="removeRate{{ i }}"
                    />
                    <tds-tooltip
                      placement="bottom"
                      selector="#removeRate{{ i }}"
                      text="{{ shared.translate('Delete') }}"
                      mouse-over-tooltip="true"
                    />
                  </tds-body-cell>
                </tds-table-body-row>
              </tds-table-body>
            </tds-table>
          </div>
        </div>
      </div>

      <!-- MODAL -->

      <tds-modal
        selector="#newExchangeRate"
        id="newExchangeRate"
        #newExchangeRate
        size="md"
        actions-position="sticky"
        prevent="true"
        (tdsClose)="
          closeModal(newExchangeRate, exchangeType, currencyFrom, currencyTo)
        "
      >
        <span slot="body">
          <form [formGroup]="formExchangeRate">
            <tds-block>
              <tds-block>
                <h3 class="tds-headline-05">
                  {{ shared.translate("Exchange Rate Creation") }}
                </h3>
                <div class="tds-row">
                  <div class="tds-col-md-6 tds-col-sm-6 tds-col-xs-6">
                    <tds-dropdown
                      #exchangeType
                      id="modalExchangeType"
                      size="md"
                      name="dropdown"
                      label="{{ this.shared.translate('Rate Type') }}"
                      label-position="outside"
                      placeholder="Select"
                      size="md"
                      open-direction="down"
                      ngDefaultControl
                      formControlName="RateType"
                      style="z-index: 3"
                      (tdsSelect)="changeValue('exchangeType', $event)"
                      [error]="
                        Exchange['RateType'].touched &&
                        Exchange['RateType'].errors
                      "
                      helper="{{
                        Exchange['RateType'].touched &&
                        Exchange['RateType'].errors
                          ? 'Required field'
                          : ''
                      }}"
                    >
                      <tds-dropdown-option
                        *ngFor="let rateType of rateTypesList"
                        value="{{ rateType.Code }}"
                        >{{ rateType.Description }}</tds-dropdown-option
                      >
                    </tds-dropdown>
                  </div>
                  <div class="tds-col-md-6 tds-col-sm-6 tds-col-xs-6">
                    <tds-datetime
                      label="{{ shared.translate('Exchange Date') }}"
                      ngDefaultControl
                      formControlName="ExchangeDate"
                      no-min-width
                      type="date"
                      size="md"
                      id="exchangeDate"
                      helper="{{
                        Exchange['ExchangeDate'].touched &&
                        Exchange['ExchangeDate'].errors
                          ? 'Required Field'
                          : ''
                      }}"
                      (focusout)="Exchange['ExchangeDate'].markAsTouched()"
                      state="{{
                        Exchange['ExchangeDate'].touched &&
                        Exchange['ExchangeDate'].errors
                          ? 'error'
                          : ''
                      }}"
                    >
                    </tds-datetime>
                  </div>
                </div>
                <div class="tds-row">
                  <div class="tds-col-md-4 tds-col-sm-4 tds-col-xs-6">
                    <tds-dropdown
                      #currencyFrom
                      id="currencyFrom"
                      size="md"
                      name="dropdown"
                      label="{{ shared.translate('Currency From') }}"
                      label-position="outside"
                      placeholder="Select"
                      size="md"
                      open-direction="down"
                      ngDefaultControl
                      formControlName="CurrencyIdFrom"
                      (tdsSelect)="changeValue('currencyFrom', $event)"
                      style="z-index: 2"
                      [error]="
                        Exchange['CurrencyIdFrom'].touched &&
                        Exchange['CurrencyIdFrom'].errors
                      "
                      helper="{{
                        Exchange['CurrencyIdFrom'].touched &&
                        Exchange['CurrencyIdFrom'].errors
                          ? 'Required field'
                          : ''
                      }}"
                    >
                      <tds-dropdown-option
                        *ngFor="let currencyFrom of currencyList"
                        value="{{ currencyFrom.Id }}"
                        >{{ currencyFrom.Description }}</tds-dropdown-option
                      >
                    </tds-dropdown>
                  </div>
                  <div class="tds-col-md-4 tds-col-sm-4 tds-col-xs-6">
                    <tds-dropdown
                      #currencyTo
                      id="currencyTo"
                      size="md"
                      name="dropdown"
                      label="{{ shared.translate('Currency To') }}"
                      label-position="outside"
                      placeholder="Select"
                      size="md"
                      open-direction="down"
                      ngDefaultControl
                      formControlName="CurrencyIdTo"
                      (tdsSelect)="changeValue('currencyTo', $event)"
                      style="z-index: 1"
                      [error]="
                        Exchange['CurrencyIdTo'].touched &&
                        Exchange['CurrencyIdTo'].errors
                      "
                      helper="{{
                        Exchange['CurrencyIdTo'].touched &&
                        Exchange['CurrencyIdTo'].errors
                          ? 'Required field'
                          : ''
                      }}"
                    >
                      <tds-dropdown-option
                        *ngFor="let currencyTo of currencyList"
                        value="{{ currencyTo.Id }}"
                        >{{ currencyTo.Description }}</tds-dropdown-option
                      >
                    </tds-dropdown>
                  </div>
                  <div class="tds-col-md-4 tds-col-sm-4 tds-col-xs-12">
                    <tds-text-field
                      no-min-width
                      type="number"
                      size="md"
                      id="ExchangeRate"
                      ngDefaultControl
                      formControlName="ExchangeRate"
                      label="{{ shared.translate('Exchange Rate') }}"
                      label-position="outside"
                      helper="{{
                        Exchange['ExchangeRate'].touched &&
                        Exchange['ExchangeRate'].errors
                          ? 'Required Field'
                          : ''
                      }}"
                      (focusout)="Exchange['ExchangeRate'].markAsTouched()"
                      state="{{
                        Exchange['ExchangeRate'].touched &&
                        Exchange['ExchangeRate'].errors
                          ? 'error'
                          : ''
                      }}"
                      (keydown.enter)="saveRate(newExchangeRate)"
                    ></tds-text-field>
                  </div>
                </div>
              </tds-block>
            </tds-block>
          </form>
        </span>
        <span slot="actions">
          <div class="tds-row">
            <div class="tds-col-xs-6">
              <tds-button
                type="button"
                variant="danger"
                size="sm"
                text="{{ shared.translate('Cancel') }}"
                data-dismiss-modal
              ></tds-button>
            </div>
            <div class="tds-col-xs-6">
              <tds-button
                type="button"
                variant="primary"
                size="sm"
                text="{{ this.shared.translate('Save') }}"
                (click)="saveRate(newExchangeRate)"
              ></tds-button>
            </div>
          </div>
        </span>
      </tds-modal>
    </tds-block>
  </tds-block>
</div>
