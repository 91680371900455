import { Component } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { ExchangeCurrencyResponse } from "src/app/models/exchangecurrencyresponse.model";
import { ExchangeRateResponse } from "src/app/models/exchangerateresponde.model";
import { ExchangeRateTypeResponse } from "src/app/models/exchangeratetyperesponse.model";
import { ExchangeRateService } from "src/app/service/api/exchange-rate.service";
import { SharedService } from "src/app/service/shared.service";

@Component({
  selector: "app-exchange-rate",
  templateUrl: "./exchange-rate.component.html",
  styleUrls: ["./exchange-rate.component.css"],
})
export class ExchangeRateComponent {
  constructor(
    public shared: SharedService,
    private exchange: ExchangeRateService,
    private fb: FormBuilder,
    private cookieService: CookieService
  ) { }

  rateTitle: string = "";
  rateType: string;
  currencyList: ExchangeCurrencyResponse[] = [];
  rateTypesList: ExchangeRateTypeResponse[] = [];
  rateList: ExchangeRateResponse[] = [];
  requestType: string = "POST";
  formExchangeRate: FormGroup;

  ngOnInit() {
    this.createExchangeRateForm();
    this.getCurrencies();
    this.getRateTypes();
  }

  get Exchange() {
    return this.formExchangeRate.controls;
  }

  createExchangeRateForm() {
    this.formExchangeRate = this.fb.group({
      PartnerId: [
        this.cookieService.get("Musys.Ui.User.MarketUnitId.Current"),
        Validators.required,
      ],
      RateType: ["", Validators.required],
      ExchangeDate: ["", Validators.required],
      CurrencyIdFrom: ["", Validators.required],
      CurrencyIdTo: ["", Validators.required],
      ExchangeRate: ["", Validators.required],
    });
  }

  getCurrencies() {
    this.exchange.getExchangeCurrencies().subscribe({
      next: (result) => {
        this.currencyList = Object.assign(result.Data);
      },
      error: () => {
        this.shared.errorAlert(
          this.shared.translate(`Error getting Currencies!`)
        );
      },
    });
  }

  getRateTypes() {
    this.exchange.getRateTypes().subscribe({
      next: (result) => {
        if (result.Data.length > 0) {
          document
            .getElementById("newExchangeRate")
            .setAttribute("disabled", "false");
          this.rateTypesList = result.Data;
        }
      },
      error: () => {
        this.shared.errorAlert(
          this.shared.translate("Error getting exchange rate types!")
        );
      },
    });
  }

  getListExchangeRate() {

    if (this.rateType == null) {
      this.shared.warningAlert(
        this.shared.translate("Please select an exchange rate!")
      );
      return;
    }
    this.exchange.Index(this.rateType).subscribe({
      next: (result) => {
        if (result.Data.length > 0) {
          this.rateList = result.Data;

          this.rateTitle = this.rateTypesList.find((e) => e.Code == this.rateType).Description

        } else {
          this.shared.warningAlert(
            this.shared.translate("No data found!"),
            "sm"
          );
        }
      },
      error: () => {
        this.shared.errorAlert(
          this.shared.translate("Error getting exchange rates!")
        );
        return;
      },
    });
  }

  changeValue(dropdown: string, event: any) {
    switch (dropdown) {
      case "type":
        this.rateType = event.target.value;
        break;
      case "currencyFrom":
        this.formExchangeRate
          .get("CurrencyIdFrom")
          .patchValue(event.target.value);
        break;

      case "currencyTo":
        this.formExchangeRate
          .get("CurrencyIdTo")
          .patchValue(event.target.value);
        break;
      case "exchangeType":
        this.formExchangeRate.get("RateType").patchValue(event.target.value);
    }
  }

  setHeaderNames() {
    document
      .getElementById("dateRate")
      .setAttribute("column-title", this.shared.translate("Date"));

    document
      .getElementById("currencyF")
      .setAttribute("column-title", this.shared.translate("Currency from"));

    document
      .getElementById("currencyT")
      .setAttribute("column-title", this.shared.translate("Currency to"));

    document
      .getElementById("rate")
      .setAttribute("column-title", this.shared.translate("Rate"));
    document
      .getElementById("edit")
      .setAttribute("column-title", this.shared.translate(""));

    document
      .getElementById("delete")
      .setAttribute("column-title", this.shared.translate(""));
  }

  setTableValues(index: number) {
    document
      .getElementById(`rowDate${index}`)
      .setAttribute(
        "cell-value",
        this.rateList[index].ExchangeDate
          ? this.rateList[index].ExchangeDate.toString()?.substring(0, 10)
          : ""
      );
    document
      .getElementById(`rowCurrencyF${index}`)
      .setAttribute("cell-value", this.rateList[index].CurrencyIsoCodeFrom);
    document
      .getElementById(`rowCurrencyT${index}`)
      .setAttribute("cell-value", this.rateList[index].CurrencyIsoCodeTo || "");
    document
      .getElementById(`rowRate${index}`)
      .setAttribute("cell-value", this.rateList[index].ExchangeRate.toString());
    document.getElementById(`rowEdit${index}`).setAttribute("cell-value", "");
    document.getElementById(`rowDelete${index}`).setAttribute("cell-value", "");
  }

  saveRate(modal: any) {
    if (this.requestType == "POST") {
      this.addRate(modal);
    } else {
      this.updateRate(modal);
    }
  }

  addRate(modal: any) {
    if (!this.formExchangeRate.valid) {
      this.formExchangeRate.markAllAsTouched();
      this.shared.errorAlert(
        this.shared.translate("Please, fill all required fields!")
      );
      return;
    }

    var exchangeRateItem = {
      PartnerId: parseInt(this.formExchangeRate.get("PartnerId").value),
      RateType: this.formExchangeRate.get("RateType").value,
      ExchangeDate: this.formExchangeRate.get("ExchangeDate").value,
      CurrencyIdFrom: parseInt(
        this.formExchangeRate.get("CurrencyIdFrom").value
      ),
      CurrencyIdTo: parseInt(this.formExchangeRate.get("CurrencyIdTo").value),
      ExchangeRate: parseInt(this.formExchangeRate.get("ExchangeRate").value),
    };

    this.exchange.addExchangeRate(exchangeRateItem).subscribe({
      next: (result) => {
        if (result.Success) {
          this.getListExchangeRate();
          this.shared.successAlert(
            this.shared.translate("Item added successfully!")
          );
          modal.closeModal();
        } else {
          this.shared.errorAlert(
            this.shared.translate("Error while adding Exchange Rate!")
          );
        }
      },
      error: (error) => {
        this.shared.errorAlert(
          this.shared.translate("Error while adding Exchange Rate!")
        );
      },
    });
  }

  updateRate(modal: any) {
    if (!this.formExchangeRate.valid) {
      this.formExchangeRate.markAllAsTouched();
      this.shared.errorAlert(
        this.shared.translate("Please, fill all required fields!")
      );
      return;
    }

    var exchangeRateItem = {
      PartnerId: parseInt(this.formExchangeRate.get("PartnerId").value),
      RateType: this.formExchangeRate.get("RateType").value,
      ExchangeDate: this.formExchangeRate.get("ExchangeDate").value,
      CurrencyIdFrom: parseInt(
        this.formExchangeRate.get("CurrencyIdFrom").value
      ),
      CurrencyIdTo: parseInt(this.formExchangeRate.get("CurrencyIdTo").value),
      ExchangeRate: parseInt(this.formExchangeRate.get("ExchangeRate").value),
    };

    this.exchange.updateExchangeRate(exchangeRateItem).subscribe({
      next: (result) => {
        if (result.Success) {
          this.shared.successAlert(
            this.shared.translate("Item updated successfully!")
          );
          modal.closeModal();
          this.rateList.find(
            (r) =>
              r.CurrencyIdFrom == exchangeRateItem.CurrencyIdFrom &&
              r.CurrencyIdTo == exchangeRateItem.CurrencyIdTo &&
              r.RateType == exchangeRateItem.RateType
          ).ExchangeRate = exchangeRateItem.ExchangeRate;
        }
      },
      error: (error) => {
        this.shared.errorAlert(
          this.shared.translate("Error while updating Exchange Rate!")
        );
      },
    });
  }

  editRate(rateItem: any, modal: any) {
    this.requestType = "PUT";
    this.formExchangeRate.patchValue({ PartnerId: rateItem.PartnerId });
    this.formExchangeRate.patchValue({ RateType: rateItem.RateType });
    document
      .getElementById("modalExchangeType")
    ["setValue"](
      rateItem.RateType,
      this.rateTypesList.find((r) => r.Code == rateItem.RateType).Description
    );
    document
      .getElementById("currencyFrom")
    ["setValue"](
      rateItem.CurrencyIdFrom,
      this.currencyList.find((c) => c.Id == rateItem.CurrencyIdFrom)
        .Description
    );
    this.formExchangeRate.patchValue({
      CurrencyIdFrom: rateItem.CurrencyIdFrom,
    });
    document
      .getElementById("currencyTo")
    ["setValue"](
      rateItem.CurrencyIdTo,
      this.currencyList.find((c) => c.Id == rateItem.CurrencyIdTo).Description
    );
    this.formExchangeRate.patchValue({ CurrencyIdTo: rateItem.CurrencyIdTo });
    this.formExchangeRate.patchValue({ PartnerId: rateItem.PartnerId });
    this.formExchangeRate.patchValue({
      ExchangeDate:
        new Date(rateItem.ExchangeDate).toISOString().substring(0, 10) || null,
    });
    this.formExchangeRate.patchValue({ ExchangeRate: rateItem.ExchangeRate });

    this.formExchangeRate.get("RateType").disable();
    this.formExchangeRate.get("CurrencyIdFrom").disable();
    this.formExchangeRate.get("CurrencyIdTo").disable();
    this.formExchangeRate.get("ExchangeDate").disable();

    modal.showModal("newExchangeRate");
  }

  deleteRate(exchangeRate) {
    this.shared
      .warningOptionAlert(
        this.shared.translate("Are you sure you want to remove this item?")
      )
      .then((response) => {
        if (response.isConfirmed) {
          this.exchange.deleteExchangeRate(exchangeRate).subscribe({
            next: (result) => {
              this.rateList.splice(
                this.rateList.findIndex((r) => r == exchangeRate),
                1
              );
              this.shared.successAlert(
                this.shared.translate("Item removed successfully!")
              );
            },
            error: (error) => {
              this.shared.errorAlert(
                this.shared.translate("Error while removing Exchange Rate!")
              );
            },
          });
        }
      });
  }

  closeModal(modal: any, dropdownEX: any, dropdownCF: any, dropdownCT: any) {
    this.formExchangeRate.reset();
    if (dropdownEX) dropdownEX.reset();
    if (dropdownCF) dropdownCF.reset();
    if (dropdownCT) dropdownCT.reset();
    this.createExchangeRateForm();
    modal.closeModal();
  }
}
