<div class="tds-container">
  <tds-block>
    <div class="tds-row">
      <div class="tds-col-md-9 tds-col-sm-6 tds-col-xs-6">
        <h3>{{shared.translate('Adm Mkt Days')}}</h3>
      </div>
      <div class="tds-col-md-3 tds-col-sm-6 tds-col-xs-6 tds-u-flex-end">
        <tds-button class="tds-u-mr1" type="button" variant="secondary" size="sm" selector="#clear-button"
          text="{{shared.translate('Clear')}}"></tds-button>

        <tds-button type="button" variant="primary" size="sm" id="open-modal"
          (click)="openModal(admMktDaysModal, 'POST')" text="{{shared.translate('New')}}"></tds-button>
      </div>
    </div>
    <tds-block>
      <div class="tds-row" style="position: relative">
        <div class="tds-col-md-1 tds-col-sm-1 tds-col-xs-12">
          <div class="tds-row tds-u-mt2">
            <span slot="tds-label">{{shared.translate('MU')}}</span>
          </div>
          <div class="tds-row">
            <h6>{{marketUnit}}</h6>
          </div>
        </div>
        <div class="tds-col-md-10 tds-col-sm-9 tds-col-xs-10">
          <tds-dropdown #openDropdown 
            class="tds-u-mt2 tds-u-mb3 tds-u-mr1" label="{{shared.translate('Parameter Type')}}"
            label-position="outside" placeholder="{{shared.translate('Parameter Type')}}" size="md"
            open-direction="down" (tdsSelect)="changeDropdownValue('parameter-type', $event)">
            <tds-dropdown-option *ngFor="let parameterType of parameterTypesList"
              value="{{parameterType.Id}}">{{parameterType.Description}}</tds-dropdown-option>
          </tds-dropdown>
        </div>
        <div id="searchButton" class="tds-col-md-1 tds-col-sm-2 tds-col-xs-2 justify-end">
          <tds-button type="button" variant="primary" size="sm" text="{{shared.translate('Open')}}"
            (click)="open(parameterType)"></tds-button>
        </div>
      </div>
    </tds-block>

    <!-- Table Results -->
    <tds-block>
      <tds-table class="tds-col-md-12 tds-col-sm-12 tds-col-xs-12 tds-col-max-12 tds-u-pb3 tds-u-mt3"
        vertical-dividers="false" compact-design="false" responsive="true" no-min-width id="delivey-address-table"
        *ngIf="isTableVisible">
        <tds-table-header>
          <tds-header-cell id="parameter-type" column-title={{setHeaderNames()}}></tds-header-cell>
          <tds-header-cell id="vehicle-type" column-title=""></tds-header-cell>
          <tds-header-cell id="from" column-title=""></tds-header-cell>
          <tds-header-cell id="to" column-title=""></tds-header-cell>
          <tds-header-cell id="working-days" column-title=""></tds-header-cell>
          <tds-header-cell id="edit" column-title=""></tds-header-cell>
          <tds-header-cell id="delete" column-title=""></tds-header-cell>
        </tds-table-header>
        <tds-table-body class="tds-u-p3">
          <tds-table-body-row *ngFor="let admMktDays of admMktDaysList; let i = index">
            <tds-body-cell id="rowParameterType{{i}}" disable-padding="false"></tds-body-cell>
            <tds-body-cell id="rowVehicleType{{i}}" disable-padding="false"></tds-body-cell>
            <tds-body-cell id="rowFrom{{i}}" disable-padding="false"></tds-body-cell>
            <tds-body-cell id="rowTo{{i}}" disable-padding="false"></tds-body-cell>
            <tds-body-cell id="rowWorkingDays{{i}}" disable-padding="false"></tds-body-cell>
            <tds-body-cell id="rowEdit{{i}}" custom-width="22px" disable-padding="false">
              <tds-icon name="edit" size="22px" class="tds-u-mr1"
                (click)="openModal(admMktDaysModal, 'PUT', admMktDays)"></tds-icon>
            </tds-body-cell>
            <tds-body-cell id="rowDelete{{i}}" cell-value="{{setTableValues(i, admMktDays)}}" disable-padding="false">
              <tds-icon name="trash_inactive" size="20px" class="tds-u-mr1" (click)="RemoveAdmDays(admMktDays.TypeAdmDaysId, admMktDays.VehicleTypeId)"></tds-icon>
            </tds-body-cell>
          </tds-table-body-row>
        </tds-table-body>
      </tds-table>
    </tds-block>

    <tds-modal id="admMktDaysModal" size="md" #admMktDaysModal actions-position="sticky">
      <span slot="body">
        <form [formGroup]="admMarketDaysForm">
          <tds-block>
            <tds-block>
              <div class="tds-row">
                <div class="tds-col-md-10 tds-u-flex">
                  <h3>{{modalTitle}}</h3>
                </div>
              </div>
              <div class="tds-row">
                <div class="tds-col-md-6 tds-col-xs-12 tds-u-pb3">
                  <tds-text-field ngDefaultControl formControlName="MarketUnitId" size="sm" label="Market Unit"
                    label-position="outside" placeholder="{{shared.translate('Market Unit')}}" >
                  </tds-text-field>
                </div>
                <div class="tds-col-md-6 tds-col-xs-12 tds-u-pb3">
                  <tds-dropdown ngDefaultControl formControlName="VehicleTypeId" #vehicleType id="vehicleType"
                    (tdsSelect)="changeDropdownValue('vehicle-type', $event)" name="dropdown" label="Vehicle Type"
                    label-position="outside" placeholder="Vehicle Type" helper="" size="sm" open-direction="down"
                    [error]="f['VehicleTypeId'].touched && f['VehicleTypeId'].errors"
                    (focusout)="f['VehicleTypeId'].markAsTouched()"
                    helper="{{f['VehicleTypeId'].touched && f['VehicleTypeId'].errors ? shared.translate('Selection is required.') : ''}}">
                    <tds-dropdown-option *ngFor="let productSeries of productSeriesList"
                      value="{{productSeries.Id}}">{{productSeries.Description}}</tds-dropdown-option>
                  </tds-dropdown>
                </div>
              </div>
              <div class="tds-row">
                <div class="tds-col-md-6 tds-col-xs-12 tds-u-pb3">
                  <tds-dropdown ngDefaultControl formControlName="TypeAdmDaysId" #parameterTypeDropdown
                    id="parameterTypeDropdown" (tdsSelect)="changeDropdownValue('parameter-type-modal', $event)"
                    name="dropdown" size="sm" open-direction="down" label="Parameter Type" label-position="outside"
                    placeholder="{{shared.translate('Parameter Type')}}" 
                    [error]="f['TypeAdmDaysId'].touched && f['TypeAdmDaysId'].errors"
                    (focusout)="f['TypeAdmDaysId'].markAsTouched()"
                    helper="{{f['TypeAdmDaysId'].touched && f['TypeAdmDaysId'].errors ? shared.translate('Selection is required.') : ''}}">
                    <tds-dropdown-option *ngFor="let parameterType of parameterTypesList"
                      value="{{parameterType.Id}}">{{parameterType.Description}}</tds-dropdown-option>
                  </tds-dropdown>
                </div>
                <div class="tds-col-md-6 tds-col-xs-12">
                  <tds-text-field ngDefaultControl formControlName="WorkingDays" type="number" #workingDays id="workingDays" size="sm" no-min-width
                    label="Working Days" label-position="outside" placeholder="{{shared.translate('Working Days')}}"
                   
                    >
                  </tds-text-field>
                </div>
              </div>
              <div class="tds-row">
                <div class="tds-col-md-6 tds-col-xs-12 tds-u-pb3">
                  <tds-datetime ngDefaultControl formControlName="From" #dataTimeFrom id="dataTimeFrom" type="date"
                    size="sm" state="none" label="From"></tds-datetime>
                </div>
                <div class="tds-col-md-6 tds-col-xs-12 tds-u-pb3">
                  <tds-datetime ngDefaultControl formControlName="To" #dataTimeTo id="dataTimeTo" type="date" size="sm"
                    state="none" label="To"></tds-datetime>
                </div>
              </div>
            </tds-block>
          </tds-block>
        </form>
      </span>
      <span slot="actions">
        <tds-button class="tds-u-mr1" data-dismiss-modal size="sm" text="Save" variant="primary"
          (click)="SaveAdmDays()"></tds-button>
        <tds-button data-dismiss-modal size="sm" text="Cancel" variant="danger"
          (click)="closeModal(admMktDaysModal)"></tds-button>
      </span>
    </tds-modal>
  </tds-block>
</div>