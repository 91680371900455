<div class="tds-container">
    <tds-block>

        <tds-folder-tabs (tdsChange)="changeTab($event)">
            <tds-folder-tab>
                <button>{{shared.translate('Dealer Access')}}</button>
            </tds-folder-tab>
            <tds-folder-tab>
                <button>{{shared.translate('User roles')}}</button>
            </tds-folder-tab>
        </tds-folder-tabs>

        <div id="dealerAccess">
            <tds-block>
                <tds-block>
                    <div class="tds-row">
                        <h3>{{shared.translate('Dealer Access')}}</h3>
                    </div>
                    <div class="tds-row">
                        <div class="tds-col">
                            <p>{{shared.translate('Grant or revoke access to dealer')}}</p>
                        </div>
                        <div class="tds-col-2">
                            <tds-text-field placeholder="{{shared.translate('Search')}}" ngDefaultControl
                                [(ngModel)]='dealerFilter' type="text" size="sm" no-min-width></tds-text-field>
                        </div>
                    </div>
                    <tds-table no-min-width vertical-dividers="false" compact-design="true" responsive="true">
                        <tds-table-header>
                            <tds-header-cell id="dealer"
                                column-title="{{setDealerAccessHeaderNames()}}"></tds-header-cell>
                            <tds-header-cell class="sss" id="users" column-title=""></tds-header-cell>
                            <tds-header-cell id="edit" column-title=""></tds-header-cell>
                        </tds-table-header>

                        <tds-table-body-row *ngFor="let dealer of dealerList?.slice(0, 25) | filterDealers: dealerFilter">
                            <tds-body-cell id="rowDealer{{dealer.Code}}"></tds-body-cell>
                            <tds-body-cell class="sss" id="rowUsers{{dealer.Code}}"></tds-body-cell>
                            <tds-body-cell id="rowEdit{{dealer.Code}}"
                                cell-value="{{setDealerAccessTableValues(dealer)}}">
                                <img (click)="openModal(editModal, dealer)"
                                    style="width: 25px; margin-left: 10px; cursor: pointer"
                                    src="../../../../../assets/images/edit.svg" id="EditDealer{{dealer.Code}}">

                                <tds-tooltip placement="bottom" selector="#EditDealer{{dealer.Code}}" text="Edit"
                                    mouse-over-tooltip="true"></tds-tooltip>
                            </tds-body-cell>
                        </tds-table-body-row>
                    </tds-table>
                </tds-block>
            </tds-block>
        </div>

        <div id="userRoles" style="display: none;">
            <div class="tds-row">
                <h3>{{ shared.translate('User Roles') }}</h3>
            </div>
            <div class="tds-row">
                <div class="tds-col">
                    <p>{{ shared.translate('Indicate responsible user for managing the accesses of salesmen and managers at the dealer.') }}</p>
                </div>
                <div class="tds-col-2">
                    <tds-text-field placeholder="{{shared.translate('Search')}}" ngDefaultControl
                        [(ngModel)]='userRoleFilter' type="text" size="sm" no-min-width></tds-text-field>
                </div>
            </div>
            <tds-block>
                <tds-table no-min-width vertical-dividers="false" compact-design="true" responsive="true">
                    <tds-table-header>
                        <tds-header-cell id="urUsers"
                            column-title="{{setUserRolesHeaderNames('Users')}}"></tds-header-cell>
                        <tds-header-cell *ngFor="let profile of profileAccess" id="ur{{profile.Name}}"
                            column-title="{{setUserRolesHeaderNames(profile.Name)}}"></tds-header-cell>
                    </tds-table-header>

                    <tds-table-body-row *ngFor="let user of users?.slice(0, 25) | filterUserRoles: userRoleFilter">
                        <tds-body-cell id="rowUrUsers{{user.Username}}"
                            cell-value="{{setUserRolesTableValues(user, 'Users')}}"></tds-body-cell>
                        <tds-body-cell *ngFor="let profile of profileAccess" id="rowUr{{profile.Id}}{{user.Username}}">
                            <tds-checkbox #check (tdsChange)="profileChange(check, profile.Id, user.Username)"
                                [checked]="hasProfile(user, profile)"></tds-checkbox>
                        </tds-body-cell>
                    </tds-table-body-row>
                </tds-table>
            </tds-block>

        </div>
    </tds-block>
</div>

<tds-modal #editModal id="editModal" selector=".editModal" size="md" prevent>
    <span slot="body">
        <div class="tds-card">
            <div class="tds-card-header">
                <div class="tds-row">
                    <div class="tds-col-md-10 tds-u-flex">
                        <h6>{{selectedDealer?.Name}}</h6>
                    </div>
                </div>
            </div>
            <div class="tds-card-body" *ngIf="selectedDealer">
                <tds-block>
                    <div class="tds-row align-end justify-end">
                        <tds-text-field #addDealer (keydown.enter)="grantDealerUser(selectedDealer.Id, addDealer)"
                            id="searchResponsible" size="sm" placeholder="{{shared.translate('Username')}}">
                        </tds-text-field>
                        <tds-button (click)="grantDealerUser(selectedDealer.Id, addDealer)" size="sm" class="tds-u-ml2"
                            text="{{shared.translate('Add')}}"></tds-button>
                    </div>

                    <div class="tds-row tds-u-mt3">
                        <tds-table table-id="pagination-table" no-min-width vertical-dividers="false"
                            compact-design="true" responsive="true">
                            <tds-table-header>
                                <tds-header-cell id="editUsername"
                                    column-title="{{setEditHeaderNames()}}"></tds-header-cell>
                                <tds-header-cell id="editName" column-title=""></tds-header-cell>
                                <tds-header-cell id="remove" column-title=""></tds-header-cell>
                            </tds-table-header>

                            <tds-table-body-row *ngFor="let user of selectedDealer.Users let i = index">
                                <tds-body-cell id="rowEditUsername{{i}}"></tds-body-cell>
                                <tds-body-cell id="rowEditName{{i}}"></tds-body-cell>
                                <tds-body-cell id="rowRemove{{i}}" cell-value="{{setEditTableValues(i)}}">
                                    <img (click)="revokeDealer(selectedDealer.Id, selectedDealer.Profile.Id, user.Username)"
                                        class="removeDealerButton"
                                        style="width: 22px; margin-left: 10px; cursor: pointer"
                                        src="../../../../../assets/images/trash.svg" id="removeDealer{{i}}">
                                    <tds-tooltip placement="bottom" selector="#removeDealer{{i}}"
                                        text="{{shared.translate('Delete')}}" mouse-over-tooltip="true"> </tds-tooltip>
                                </tds-body-cell>
                            </tds-table-body-row>
                        </tds-table>
                    </div>
                </tds-block>
            </div>
        </div>
    </span>
</tds-modal>
