import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { AdmDays } from 'src/app/models/admdays.model';
import { GetPartnerTypesResponse } from 'src/app/models/getpartnertypesresponse.model';
import { ListPartnersRequest } from 'src/app/models/listpartnersrequest.model';
import { MarketUnit } from 'src/app/models/marketunit.model';
import { ParameterType } from 'src/app/models/parametertype.model';
import { PartnerType } from 'src/app/models/partnertype.model';
import { Result } from 'src/app/models/result.model';
import { musys } from 'src/environments/environment';

type AdmMarketDaysRequest = {
  MarketUnitId: number,
  TypeAdmDaysId: number,
  VehicleTypeId: number,
  WorkingDays: number,
  From: string,
  To: string,
}

@Injectable({
  providedIn: "root",
})

export class MarketService {
  constructor(private http: HttpClient) {}

  list(): Observable<Result<MarketUnit[]>> {
    return this.http.get<Result<MarketUnit[]>>(musys.api + `/market`);
  }

  listByUser(): Observable<Result<MarketUnit[]>> {
    return this.http.get<Result<MarketUnit[]>>(musys.api + `/market/list-by-user`);
  }

  listPartnerTypes(): Observable<Result<GetPartnerTypesResponse[]>> {
    return this.http.get<Result<GetPartnerTypesResponse[]>>(
      musys.api + `/market/partner-types`
    );
  }

  get() {
    return this.http.get<Result<any>>(musys.api + `/market/detail`);
  }

  createMarketUnit(marketUnit: FormGroup) {
    return this.http.post(
      musys.api + `/market`,
      JSON.stringify(marketUnit.value)
    );
  }

  editMarketUnit(marketUnit: FormGroup) {
    return this.http.put(
      musys.api + `/market`,
      JSON.stringify(marketUnit.value)
    );
  }

  
  getParameterTypes(): Observable<Result<ParameterType[]>> {
    return this.http.get<Result<ParameterType[]>>(musys.api + `/market/parameter-types`);
  }

  listAdmDays(parameterTypeId: number): Observable<Result<AdmDays[]>> {
    return this.http.get<Result<AdmDays[]>>(musys.api + `/market/adm-days/${parameterTypeId}`); 
  }

  RemoveAdmDays(parameterTypeId: number, VehicleTypeId: number): Observable<Result<any>> {
    return this.http.delete<Result<any[]>>(musys.api + `/market/adm-days/${parameterTypeId}/${VehicleTypeId}`); 
  }

  CreateAdmDays(request: AdmMarketDaysRequest): Observable<Result<any>> {
    return this.http.post<Result<any>>(musys.api + `/market/adm-days`, request); 
  }

  UpdateAdmDays(request: AdmMarketDaysRequest): Observable<Result<any>> {
    return this.http.put<Result<any>>(musys.api + `/market/adm-days`, request); 
  }
}
