import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CookieService } from 'ngx-cookie-service';
import { ExchangeCurrencyResponse } from 'src/app/models/exchangecurrencyresponse.model';
import { GetPartnerTypesResponse } from 'src/app/models/getpartnertypesresponse.model';
import { MarketUnit } from 'src/app/models/marketunit.model';
import { ExchangeRateService } from 'src/app/service/api/exchange-rate.service';
import { MarketService } from 'src/app/service/api/market.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-market-unit-information',
  templateUrl: './market-unit-information.component.html',
  styleUrls: ['./market-unit-information.component.css']
})
export class MarketUnitInformationComponent {

  form: FormGroup;

  requestType: string;

  typeList: GetPartnerTypesResponse[] = [];
  currencyList: ExchangeCurrencyResponse[] = [];
  marketParentList: MarketUnit[] = [];

  get f() {
    return this.form.controls;
  }

  constructor(public shared: SharedService, public cookie: CookieService, private fb: FormBuilder, private market: MarketService, private exchange: ExchangeRateService) { }

  ngOnInit() {
    this.getPartnerTypes();
    this.getCurrencies();
    this.getMarketParents();
    this.createForm();
  }

  setHeaderNames() {
    document.getElementById('partnerId').setAttribute('column-title', this.shared.translate('Partner Id'));
    document.getElementById('partnerName').setAttribute('column-title', this.shared.translate('Partner Name'));
    document.getElementById('localCode').setAttribute('column-title', this.shared.translate('Local Code'));
    // document.getElementById('Edit').setAttribute('', '');
  }

  setTableValues() {
    document.getElementById('rowPartnerId').setAttribute('cell-value', this.cookie.get('Musys.Ui.User.MarketUnitId.Current'));
    document.getElementById('rowPartnerName').setAttribute('cell-value', this.cookie.get('Musys.Ui.User.MarketUnitDescription.Current'));
    document.getElementById('rowLocalCode').setAttribute('cell-value', this.cookie.get('Musys.Ui.User.MarketUnitCode.Current'));
    //document.getElementById('rowEdit').setAttribute('', '');
  }

  createForm() {
    this.form = this.fb.group({
      Id: [null],
      CalendarSpec: [null],
      CurrencyCode: [null , Validators.required],
      CreatedAt: [{ value: new Date().toISOString().substring(0,10), disabled: true }],
      DisabledAt: [null],
      DefaultCustomerCode: [null],
      DefaultDealerCode: [null],
      CityCode: [0],
      LocalCode: [null, Validators.required],
      Name: [null, Validators.required],
      ParentId: [0, Validators.required],
      PostalCode: [null],
      Type: [null, Validators.required],
      CaptiveDealer: [false],
    })
  }

  openModal(requestType: string, modal: any) {
    this.requestType = requestType;
    this.createForm();
    document.getElementById('mainType')['reset']();
    document.getElementById('currencyCode')['reset']();
    document.getElementById('parentId')['reset']();
    modal.showModal();
  }

  editMarket(modal: any) {
    this.market.get().subscribe({
      next: (result) => {
        
        this.form.patchValue({Id: result.Data?.Id});
        this.form.patchValue({Name: result.Data?.Name});
        this.form.patchValue({CreatedAt: new Date(result.Data?.CreatedAt).toISOString().substring(0, 10)});
        this.f['CreatedAt'].disable();
        this.form.patchValue({CalendarSpec: result.Data?.CalendarSpec});
        this.form.patchValue({Type: result.Data?.Type});
        document.getElementById('mainType')['setValue'](result.Data.Type, result.Data.Type);

        this.form.patchValue({CurrencyCode: result.Data?.Currency.Id});
        document.getElementById('currencyCode')['setValue'](result.Data?.Currency.Id, result.Data?.Currency.Name);

        if (result.Data.Parent) {
          this.form.patchValue({Parent: result.Data?.Parent?.Id});
          document.getElementById('parentId')['setValue'](result.Data?.Parent?.Id, result.Data?.Parent?.Name);
        }

        this.form.patchValue({DisabledAt: result.Data.InactiveDate ? new Date(result.Data?.InactiveDate).toISOString().substring(0, 10) : null});
        this.form.patchValue({LocalCode: result.Data?.LocalCode});
        this.form.patchValue({DefaultDealerCode: result.Data?.DefaultDealerCode});
        this.form.patchValue({DefaultCustomerCode: result.Data?.DefaultCustomerCode});
        this.form.patchValue({CaptiveDealer: result.Data?.CaptiveDealer});
        this.requestType = 'PUT';
        modal.showModal();
      },
      error:() => {
        this.shared.errorAlert(`Error getting Market information!`);
      }
    })
  }

  closeModal() {
    document.getElementById('marketUnitModal').classList.remove('show');
    document.getElementById('marketUnitModal').classList.add('hide');
    this.createForm();
    this.form.patchValue({ParentId: 0});
    this.form.patchValue({CaptiveDealer: false});
    this.form.patchValue({CityCode: 0});
    document.getElementById('mainType')['reset']();
    document.getElementById('currencyCode')['reset']();
    document.getElementById('parentId')['reset']();
  }

  setDropdown(event: any) {
    var control = event.currentTarget.attributes.formcontrolname.value;
    this.f[control].patchValue(event.target.value);
  }

  getPartnerTypes() {
    this.market.listPartnerTypes().subscribe({
      next: (result) => {
        this.typeList = result.Data;
      },
      error:() => {
        this.shared.errorAlert(`Error getting Partner Types!`);
      }
    })
  }

  getCurrencies() {
    this.exchange.getExchangeCurrencies().subscribe({
      next: (result) => {
        this.currencyList = Object.assign(result.Data)
      },
      error:() => {
        this.shared.errorAlert(`Error getting Currencies!`);
      }
    })
  }

  getMarketParents() {
    this.market.list().subscribe({
      next: (result) => {
        this.marketParentList = result.Data;
      },
      error:() => {
        this.shared.errorAlert(`Error getting Market parents!`);
      }
    })
  }

  saveMarketUnit() {
    if (!this.form.valid) {
      this.form.markAllAsTouched()
      console.log(this.form.controls)
      this.shared.errorAlert(this.shared.translate("Please, fill all required fields!"))
      return
    }

    // Parsing values
    this.form.patchValue({CalendarSpec: parseInt(this.f['CalendarSpec'].value)})
    this.form.patchValue({CurrencyCode: parseInt(this.f['CurrencyCode'].value)})
    this.form.patchValue({DefaultDealerCode: parseInt(this.f['DefaultDealerCode'].value)})
    this.form.patchValue({DefaultCustomerCode: parseInt(this.f['DefaultCustomerCode'].value)})
    this.f['ParentId'].value? this.form.patchValue({ParentId: parseInt(this.f['ParentId'].value)}):  null
    this.f['CreatedAt'].enable()

    var request = this.requestType == 'POST' ? 'createMarketUnit' : 'editMarketUnit';
    debugger
    this.market[request](this.form).subscribe({
      next: (result) => {
        this.closeModal();
        this.shared.successAlert(`Market Unit ${this.requestType == 'POST' ? 'created' : 'updated'} Successfully!`);
      },
      error:() => {
        this.shared.errorAlert(`Error ${this.requestType == 'POST' ? 'creating' : 'updating'} Market unit!`);
      }
    })
  }
}
