<div class="tds-container">
  <tds-block>
    <div class="tds-row">
      <div class="tds-col-md-10 sdds-col-sm-10 sdds-col-xs-10">
        <h2 class="tds-headline-02">FFU & SOrder</h2>
      </div>
      <div class="tds-col-md-2 sdds-col-sm-2 sdds-col-xs-2 tds-u-flex-end">
        <tds-button (click)="clear()" class="tds-u-mr1" type="button" variant="secondary" size="sm"
          text="{{ 'Clear' }}"></tds-button>
        <tds-button (click)="this.requestType = 'POST'; resetModal()" type="button" id="ruleCreationBtn"
          variant="primary" size="sm" text="{{ 'New' }}"></tds-button>
      </div>
    </div>
    <tds-block>
      <form [formGroup]="searchForm">
        <div class="tds-row">
          <div class="tds-col-md-3 tds-col-sm-3 tds-col-xs-3">
            <tds-text-field ngDefaultControl formControlName="Number" (keydown.enter)="searchSOF()" id="searchNumber"
              type="number" size="sm" state="default" label="{{ shared.translate('Number') }}"
              label-position="outside" no-min-width placeholder="Number">
            </tds-text-field>
          </div>
          <div class="tds-col-md-3 tds-col-sm-3 tds-col-xs-3">
            <tds-dropdown (tdsSelect)="setDropdown($event)" ngDefaultControl formControlName="VehicleTypeId"
              id="searchVehicleTypeId" name="dropdown" label="{{ shared.translate('Vehicle type') }}"
              label-position="outside" placeholder="{{ shared.translate('Select') }}"
              (focusout)="search['VehicleTypeId'].markAsTouched()"
              state="{{search['VehicleTypeId'].touched && search['VehicleTypeId'].errors ? 'error' : ''}}"
              helper="{{search['VehicleTypeId'].touched && search['VehicleTypeId'].errors ? shared.translate('Required Field.') : ''}}"
              [error]="search['VehicleTypeId'].touched && search['VehicleTypeId'].errors" size="sm"
              open-direction="down">
              <tds-dropdown-option *ngFor="let product of productSeriesList"
                value="{{product.Id}}">{{product.Description}}</tds-dropdown-option>
            </tds-dropdown>
          </div>
          <div class="tds-col-md-3 tds-col-sm-3 tds-col-xs-3">
            <tds-dropdown ngDefaultControl formControlName="Type" (tdsSelect)="setDropdown($event)" id="searchType"
              name="dropdown" label="{{ shared.translate('Type') }}" label-position="outside"
              placeholder="{{ shared.translate('Select') }}" (focusout)="search['Type'].markAsTouched()"
              state="{{search['Type'].touched && search['Type'].errors ? 'error' : ''}}"
              helper="{{search['Type'].touched && search['Type'].errors ? shared.translate('Required Field.') : ''}}"
              [error]="search['Type'].touched && search['Type'].errors" size="sm" open-direction="down">
              <tds-dropdown-option value="F">FFU</tds-dropdown-option>
              <tds-dropdown-option value="S">S.Order</tds-dropdown-option>
            </tds-dropdown>
          </div>
          <div class="tds-col-md-3 tds-col-sm-3 tds-col-xs-3 align-items">
            <tds-button (click)="searchSOF()" class="tds-u-mr1" id="rule-creation" type="button" variant="primary"
              size="sm" text="{{ shared.translate('Search') }}"></tds-button>
          </div>
        </div>
      </form>
    </tds-block>

    <tds-block *ngIf="ffuSOrderList.length > 0">
      <tds-table table-id="pagination-table" no-min-width vertical-dividers="false" compact-design="true"
        responsive="true">
        <tds-table-header>
          <tds-header-cell id="number" column-title="{{ setHeaderNames() }}"></tds-header-cell>
          <tds-header-cell id="vehicleType" column-title=""></tds-header-cell>
          <tds-header-cell id="initialDate" column-title=""></tds-header-cell>
          <tds-header-cell id="finalDate" column-title=""></tds-header-cell>
          <tds-header-cell id="edit" column-title=""></tds-header-cell>
          <tds-header-cell id="delete" column-title=""></tds-header-cell>
        </tds-table-header>

        <tds-table-body-row *ngFor="let ffuSOrder of ffuSOrderList; let i = index">
          <tds-body-cell id="rowNumber{{ i }}"></tds-body-cell>
          <tds-body-cell id="rowVehicleType{{ i }}"></tds-body-cell>
          <tds-body-cell id="rowInitialDate{{ i }}"></tds-body-cell>
          <tds-body-cell id="rowFinalDate{{ i }}"></tds-body-cell>
          <tds-body-cell id="rowEdit{{ i }}">
            <img (click)="editFFUSorder(i, ruleModal)" style="width: 25px; margin-left: 10px; cursor: pointer"
              src="../../../../../assets/images/edit.svg" id="EditFFUSorder{{ i }}" />

            <tds-tooltip placement="bottom" selector="#EditFFUSorder{{ i }}" text="{{ shared.translate('Edit') }}"
              mouse-over-tooltip="true">
            </tds-tooltip>
          </tds-body-cell>
          <tds-body-cell id="rowDelete{{ i }}" cell-value="{{ setTableValues(i) }}">
            <img (click)="removeFFUSorder(ffuSOrder.SOFId)" style="width: 25px; margin-left: 10px; cursor: pointer"
              src="../../../../../assets/images/trash.svg" id="DeleteFFUSorder{{ i }}" />
            <tds-tooltip placement="bottom" selector="#DeleteFFUSorder{{ i }}" text="{{ shared.translate('Delete') }}"
              mouse-over-tooltip="true">
            </tds-tooltip>
          </tds-body-cell>
        </tds-table-body-row>
        <tds-table-footer></tds-table-footer>
      </tds-table>
    </tds-block>
  </tds-block>
</div>

<tds-modal #ruleModal header="{{ shared.translate('Rule creation') }}" selector="#ruleCreationBtn" id="ruleCreation"
  size="lg" actions-position="sticky">
  <span slot="body">
    <tds-block>
      <tds-block>
        <form [formGroup]="saveForm">
          <div class="tds-row">
            <div class="tds-col-lg-2 tds-col-md-6 tds-col-sm-6 tds-col-xs-6">
              <tds-text-field ngDefaultControl formControlName="Number" type="Number" size="sm"
                readonly="{{requestType=='PUT'}}" state="default" label="{{ shared.translate('Number') }}"
                label-position="outside" no-min-width (focusout)="save['Number'].markAsTouched()"
                state="{{save['Number'].touched && save['Number'].errors ? 'error' : ''}}"
                helper="{{save['Number'].touched && save['Number'].errors ? shared.translate('Required Field.') : ''}}"></tds-text-field>
            </div>
            <div class="tds-col-lg-3 tds-col-md-6 tds-col-sm-6 tds-col-xs-6">
              <tds-datetime ngDefaultControl formControlName="InitialDate" id="initialDate" type="date" size="sm"
                label="{{ shared.translate('Initial date') }}" no-min-width
                
                ></tds-datetime>
            </div>
            <div class="tds-col-lg-3 tds-col-md-6 tds-col-sm-6 tds-col-xs-6">
              <tds-datetime ngDefaultControl formControlName="FinalDate" id="finalDate" type="date" size="sm"
                state="{{save['FinalDate'].touched && save['FinalDate'].errors ? 'error' : ''}}"
                helper="{{save['FinalDate'].touched && save['FinalDate'].errors ? shared.translate('Required Field.') : ''}}"
                state="none" label="{{ shared.translate('Final date') }}" no-min-width></tds-datetime>
            </div>
           
            <div class="tds-col-lg-2 tds-col-md-6 tds-col-sm-6 tds-col-xs-6">
              <tds-dropdown (tdsSelect)="setDropdown($event)" id="saveType" name="dropdown"
                label="{{ shared.translate('Type') }}" label-position="outside"
                placeholder="{{ shared.translate('Select') }}" (focusout)="save['Type'].markAsTouched()"
                state="{{save['Type'].touched && save['Type'].errors ? 'error' : ''}}"
                helper="{{save['Type'].touched && save['Type'].errors ? shared.translate('Required Field.') : ''}}"
                [error]="save['Type'].touched && save['Type'].errors" size="sm" open-direction="down"
                disabled="{{requestType=='PUT'}}">
                <tds-dropdown-option value="F">FFU</tds-dropdown-option>
                <tds-dropdown-option value="S">S.Order</tds-dropdown-option>
              </tds-dropdown>
            </div>
             <div class="tds-col-lg-2 tds-col-md-6 tds-col-sm-6 tds-col-xs-6">
              <tds-dropdown (tdsSelect)="setDropdown($event)" id="saveVehicleTypeId" name="dropdown"
                label="{{ shared.translate('Vehicle type') }}" label-position="outside"
                placeholder="{{ shared.translate('Select') }}" (focusout)="save['VehicleTypeId'].markAsTouched()"
                state="{{save['VehicleTypeId'].touched && save['VehicleTypeId'].errors ? 'error' : ''}}"
                helper="{{save['VehicleTypeId'].touched && save['VehicleTypeId'].errors ? shared.translate('Required Field.') : ''}}"
                [error]="save['VehicleTypeId'].touched && save['VehicleTypeId'].errors" size="sm"
                disabled="{{requestType=='PUT'}}" open-direction="down">
                <tds-dropdown-option *ngFor="let product of productSeriesList"
                  value="{{product.Id}}">{{product.Description}}</tds-dropdown-option>
              </tds-dropdown>
            </div>
          </div>
        </form>
      </tds-block>
    </tds-block>
    <div class="tds-row">
      <div class="tds-col-lg-6 tds-col-md-12 tds-col-sm-12 tds-col-xs-6">
        <tds-block>
          <tds-block>
            <h3 class="tds-headline-04">
              {{ shared.translate("Available Variants") }}
            </h3>

            <div class="tds-row">
              <div class="tds-col-md-9">
                <tds-text-field (keyup)="setTextField($event)" (keydown.enter)="getAllVariants(variantSearch)"
                  id="searchVariant" type="Text" size="sm" no-min-width state="default" label="Label"
                  label-position="no label" no-min-width placeholder="Placeholder"></tds-text-field>
              </div>
              <div class="tds-col-md-3">
                <tds-button (click)="getAllVariants(variantSearch)" class="tds-u-mr1" id="rule-creation" type="button"
                  variant="primary" size="sm" text="{{ shared.translate('Search') }}"></tds-button>
              </div>
            </div>

            <!-- RESULTADOS -->

            <div class="container-variants" cdkDropList #availableVariantsList="cdkDropList"
              [cdkDropListData]="allVariantsList" [cdkDropListConnectedTo]="addedVariantsList"
              (cdkDropListDropped)="onDrop($event)">
              <div cdkDrag *ngFor="let variant of allVariantsList | filterVariants: variantSearch">
                <div class="card">
                  <img slot="thumbnail" src="../../../../../assets/images/burger.svg" alt="List icon."
                    style="width: 24px" />

                  <p class="headline">
                    {{ variant?.Code }} - {{ variant?.Description }}
                  </p>
                </div>
              </div>
            </div>
          </tds-block>
        </tds-block>
      </div>
      <div class="tds-col-lg-6 tds-col-md-12 tds-col-sm-12 tds-col-xs-6">
        <tds-block>
          <tds-block>
            <h3 class="tds-headline-04">
              {{ shared.translate("Added Variants") }}
            </h3>
            <div class="tds-row">
              <div class="tds-col-md-9">
                <tds-text-field (keyup)="setTextField($event)" id="filter" type="Text" size="sm" no-min-width
                  state="default" no-min-width placeholder="{{ shared.translate('Search') }}"></tds-text-field>
              </div>
              <div class="tds-col-md-3">
                <tds-button class="tds-u-mr1" id="rule-creation" type="button" variant="primary" size="sm"
                  text="{{ shared.translate('Search') }}"></tds-button>
              </div>
              <!-- aqui vai ser onde solta as variantes-->
              <div class="container-variants" cdkDropList #addedVariantsList="cdkDropList"
                [cdkDropListData]="addedVariants" [cdkDropListConnectedTo]="availableVariantsList"
                (cdkDropListDropped)="onDrop($event)">
                <div *ngFor="let added of addedVariants | filterVariants: filter" cdkDrag>
                  <div class="card">
                    <img slot="thumbnail" src="../../../../../assets/images/burger.svg" alt="List icon."
                      style="width: 24px" />

                    <p class="headline">
                      {{ added?.Code }} - {{ added?.Description }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </tds-block>
        </tds-block>
      </div>
    </div>
  </span>
  <span slot="actions">
    <tds-button data-dismiss-modal class="tds-u-mr1" variant="danger" type="danger" size="sm"
      text="{{ shared.translate('Cancel') }}"></tds-button>
    <tds-button size="sm" text="{{ shared.translate('Save') }}" (click)="saveFFUSorder()"></tds-button>
  </span>
</tds-modal>