import { Component } from '@angular/core';
import { DealerUsersResponse } from 'src/app/models/dealerusersresponse.model';
import { AccessService } from 'src/app/service/api/access.service';
import { SharedService } from 'src/app/service/shared.service';

@Component({
  selector: 'app-dealer-admin',
  templateUrl: './dealer-admin.component.html',
  styleUrls: ['./dealer-admin.component.css']
})
export class DealerAdminComponent {

  dealerList: DealerUsersResponse[];
  selectedDealer: DealerUsersResponse;
  newResponsible: string;

  constructor(public shared: SharedService, private access: AccessService) {}

  ngOnInit() {
    this.getDealers();
  }
  sim(event: any) {
    console.log(event)
    debugger
  }

  setHeaderNames() {
    document.getElementById('code').setAttribute('column-title', this.shared.translate('Code'));
    document.getElementById('name').setAttribute('column-title', this.shared.translate('Name'));
    document.getElementById('responsible').setAttribute('column-title', this.shared.translate('Responsible'));
  }

  setTableValues(index: number) {
    document.getElementById(`rowCode${index}`).setAttribute('cell-value', this.dealerList[index].Code);
    document.getElementById(`rowName${index}`).setAttribute('cell-value', this.dealerList[index].Name);
    document.getElementById(`rowResponsible${index}`).setAttribute('cell-value', this.dealerList[index].UsersList);
  }

  setEditTableHeaderNames() {
    document.getElementById(`editUsername`).setAttribute('column-title', this.shared.translate('Username'));
    document.getElementById(`editName`).setAttribute('column-title', this.shared.translate('Name'));
  }
  setEditTableValues(index: number) {
    document.getElementById(`rowEditUsername${index}`).setAttribute('cell-value', this.selectedDealer.Users[index].Username);
    document.getElementById(`rowEditName${index}`).setAttribute('cell-value', this.selectedDealer.Users[index].Name);
  }

  getDealers(partnerId?: number, username?: any) {
    this.access.getDealerAdmin().subscribe({
      next: (result) => {
        this.dealerList = result.Data
        this.dealerList.forEach(dealer => {
          dealer.UsersList = '';
          dealer.Users.forEach( user => {
            dealer.UsersList += user.Username + ';';
          })
          dealer.UsersList = dealer.UsersList.slice(0,-1);
        })
        if (partnerId) {
          this.selectedDealer = this.dealerList.find(d => d.Id == partnerId)
          username.value = '';
          this.shared.successAlert(this.shared.translate("User added successfully"))
        }
      },
      error: () => {
        this.shared.errorAlert(this.shared.translate('Error getting dealers!'));
      }
    })    
  }
  
  openModal(dealer: DealerUsersResponse, modal: any) {
    this.selectedDealer = dealer
    modal.showModal();
  }

  closeModal() {
    this.selectedDealer = null;
  }

  grantDealer(partnerId: number, username: any) {
    if (!username.value) {
      this.shared.warningAlert(this.shared.translate('User must be informed.'), 'sm');
      return
    }

    var request = {
      partnerId: partnerId,
      user: username.value
    }
    this.access.GrantDealerAdmin(request).subscribe({
      next:(result) => {
        if (result.Data) {
          this.getDealers(partnerId, username);
        }else if (result.Errors) {
          this.shared.translate(result.Errors[0]);
        }
      },
      error: (error) => {
        this.shared.errorAlert(this.shared.translate('Error while adding user!'));
      }
    })
  }

  revokeDealer(partnerId: number, profileId: number, user: string) {
    this.access.revokeUserToProfile(partnerId, profileId, user).subscribe({
      next:(result) => {
        if (result.Data) {
          this.selectedDealer.Users.splice(this.selectedDealer.Users.findIndex(u => u.Username == user), 1)
          var userNameToRemove = this.selectedDealer.UsersList.includes(`${user};`) ? `${user};` : this.selectedDealer.UsersList.includes(`;${user}`) ? `;${user}` : user
          this.dealerList.find( d => d.Id == this.selectedDealer.Id).UsersList = this.dealerList.find( d => d.Id == this.selectedDealer.Id).UsersList.replace(userNameToRemove, "");
          this.shared.successAlert(this.shared.translate("User removed successfully"), 'sm')
        }else {
          this.shared.errorAlert(this.shared.translate("User not found!"));
        }
      },
      error: () => {
        this.shared.errorAlert(this.shared.translate('Error while revoking user!'));
      }
    })
  }
}
