import { Component, DoCheck } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { LoginGuard } from './auth/login.guard';
import { HeaderService } from './service/header.service';
import { musys } from 'src/environments/environment';
import { SharedService } from './service/shared.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements DoCheck {
  title = 'musys-frontend';

  musys = musys;

  constructor(public auth: AuthService, public loginGuard: LoginGuard, private header: HeaderService, public shared: SharedService){}

  ngOnInit() {
    this.checkAuthentication()    
  }

  ngDoCheck() {
    this.checkAuthentication()
  }

  checkAuthentication() {
    this.loginGuard.canActivate();
  }
}
