import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { addTheme } from '@scania/components';
import { defineCustomElements } from '@scania/tegel/loader';
import { theme as scania } from '@scania/theme-light';
// Run function to initiate component

defineCustomElements(window);
// add theme
addTheme(scania);

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
