import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { musys } from 'src/environments/environment';
import { Wso2Token } from '../models/wso2token.model';
import { AuthenticatedUser } from '../models/authenticateduser.model';
import { Result } from '../models/result.model';

@Injectable()

export class AuthService {
  
  constructor(private cookieService: CookieService, private httpClient: HttpClient) {}
  
  public getFederationGatewayTokenFromCookie():string {
    return this.cookieService.get('Musys.Ui.User.Authentication.Token');
  }
  
  public checkFederationGatewayToken(): boolean {
    return this.cookieService.check('Musys.Ui.User.Authentication.Token');
  }
  
  public getMusysToken(username: string, marketUnit: number, token:string): Result<AuthenticatedUser> {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', musys.api + '/login/authenticate', false);
    xhr.setRequestHeader('Authorization', 'Bearer ' + token);
    xhr.setRequestHeader('Content-Type', 'application/json');
    xhr.send(JSON.stringify({username: username, marketUnitId: marketUnit}));

    if (xhr.status == 200) {
      const response: Result<AuthenticatedUser> = JSON.parse(xhr.responseText)
      return response;
    }
    else {
      throw new Error('Authentication failed! ' + xhr.status);
    }
  }
  
  public getMusysTokenFromCookie():string {
    return this.cookieService.get("Musys.Ui.User.UserToken.Current");
  }
  
  public checkMusysToken(): boolean {
    return this.cookieService.check("Musys.Ui.User.UserToken.Current");
  }
  
  public getUserFunctions():Result<string>{
    const xhr = new XMLHttpRequest();
    xhr.open('GET', musys.api + '/access/functions', false);
    xhr.setRequestHeader('Authorization', this.getMusysTokenFromCookie());
    xhr.send();

    if (xhr.status == 200) {
      const response: Result<string> = JSON.parse(xhr.responseText)
      return response;
    }
    else {
      throw new Error('Error getting user functions! ' + xhr.status);
    }
  }
}