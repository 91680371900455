import { Pipe, PipeTransform } from '@angular/core';
import { Term } from '../models/term.model';

@Pipe({
  name: 'filterTerms'
})
export class FilterTermsPipe implements PipeTransform {

  transform(terms: Term[], filter: string) {
    
    if (!terms || !filter) {
      return terms;
    }

    return terms.filter(t => t.NameTerm?.toUpperCase().includes(filter.toUpperCase()) || t.DescTerm?.toUpperCase().includes(filter.toUpperCase()) || t.DescTermTo?.toUpperCase().includes(filter.toUpperCase()));
  }

}
