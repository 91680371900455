<div class="tds-container-fluid">
    <tds-block>
        <div class="tds-row">
            <div class="tds-col-md-10">
                <h3>{{shared.translate("Dealer Governance")}}</h3>
            </div>
        </div>

        <tds-block>
            <tds-block>
                <tds-accordion>
                    <tds-accordion-item *ngFor="let dealer of dealerGovernanceList; let i = index"
                        header="{{dealer.Headquarter + ' ' + dealer.Branches[0].RazaoSocial}}" expanded="{{i == 0}}">
                        <div class="tds-row">
                            <table style="table-layout: fixed;width: 100%;">
                                <thead>
                                    <tr>
                                        <th style="width:35px">
                                            Code
                                        </th>
                                        <th>Partner Name</th>
                                        <th *ngFor="let bm of dealer.Branches[0].BusinessManagerList">
                                            {{bm.RvMeaning}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let branch of dealer.Branches; let k = index">
                                        <td style="width:25px">{{branch.Codigo}}</td>
                                        <td style="display: flex;align-items: center; justify-content: center;">{{branch.PartnerName}}</td>
                                        <td *ngFor="let bm of branch.BusinessManagerList">
                                            <div class="input-container" style="display: flex;align-items: center;">
                                                <ng-container *ngFor="let usernames of bm.Usernames">
                                                    <mat-form-field style="height: 110px; width: 125%;">
                                                        <mat-chip-grid #chipGrid aria-label="">
                                                            <mat-chip-row *ngFor="let username of usernames.Usernames"
                                                              (removed)="deleteManager(branch, bm.CodCategComl, username)">
                                                              {{username}}
                                                              <button matChipRemove [attr.aria-label]="'remove ' + username"    >
                                                                <mat-icon>cancel</mat-icon>
                                                              </button>
                                                            </mat-chip-row>
                                                          
                                                          <input placeholder="{{usernames.Usernames?.length == 2 ? '' : shared.translate('New...')}}" disabled="{{usernames.Usernames?.length == 2}}"
                                                                [matChipInputFor]="chipGrid"
                                                                [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                                                (matChipInputTokenEnd)="addManager(branch, bm.CodCategComl, $event)"/>
                                                        </mat-chip-grid>
                                                      </mat-form-field>
                                                </ng-container>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <hr>
                        </div>
                    </tds-accordion-item>
                </tds-accordion>
            </tds-block>
        </tds-block>
    </tds-block>
</div>
