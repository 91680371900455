<div class="tds-container">
    <tds-block>
        <h3>{{shared.translate('Dealer Admin')}}</h3>
        <p>{{shared.translate('Indicate responsible user for managing the accesses of salesmen and managers at the dealer.')}}</p>
        <tds-block>
            <tds-table table-id="pagination-table" no-min-width vertical-dividers="false" compact-design="true" responsive="true">
                <tds-table-header>
                    <tds-header-cell id="code" column-title="{{setHeaderNames()}}"></tds-header-cell>
                    <tds-header-cell id="name" column-title=""></tds-header-cell>
                    <tds-header-cell id="responsible" column-title=""></tds-header-cell>
                    <tds-header-cell id="edit" column-title=""></tds-header-cell>
                </tds-table-header>

                <tds-table-body-row *ngFor="let dealer of dealerList; let i = index">
                    <tds-body-cell id="rowCode{{i}}"></tds-body-cell>
                    <tds-body-cell id="rowName{{i}}"></tds-body-cell>
                    <tds-body-cell id="rowResponsible{{i}}"></tds-body-cell>
                    <tds-body-cell id="rowEdit{{i}}" cell-value="{{setTableValues(i)}}">
                        <img (click)="openModal(dealer, editModal)" class="editModal"
                            style="width: 25px; margin-left: 10px; cursor: pointer"
                            src="../../../../../assets/images/edit.svg" id="EditPartner{{i}}">

                        <tds-tooltip placement="bottom" selector="#EditPartner{{i}}" text="Edit"
                            mouse-over-tooltip="true"></tds-tooltip>
                    </tds-body-cell>
                </tds-table-body-row>
                <tds-table-footer pages="4" pagination></tds-table-footer>
            </tds-table>
        </tds-block>
    </tds-block>
</div>

<tds-modal  #editModal id="editModal" selector=".editModal" size="md" prevent>
    <span slot="body">
        <div class="tds-card">
            <div class="tds-card-header">
                <div class="tds-row">
                    <div class="tds-col-md-10 tds-u-flex">
                        <h6>{{selectedDealer?.Name}}</h6>
                    </div>
                </div>
            </div>
            <div class="tds-card-body" *ngIf="selectedDealer">
                <tds-block>
                    <div class="tds-row align-end justify-end">
                        <tds-text-field #addDealer (keydown.enter)="grantDealer(selectedDealer.Id, addDealer)" id="searchResponsible" size="sm" placeholder="{{shared.translate('Username')}}">
                        </tds-text-field>
                        <tds-button (click)="grantDealer(selectedDealer.Id, addDealer)" size="sm" class="tds-u-ml2" text="{{shared.translate('Add')}}"></tds-button>
                    </div>

                    <div class="tds-row tds-u-mt3">
                        <tds-table table-id="pagination-table" no-min-width vertical-dividers="false" compact-design="true" responsive="true">
                            <tds-table-header>
                                <tds-header-cell id="editUsername" column-title="{{setEditTableHeaderNames()}}"></tds-header-cell>
                                <tds-header-cell id="editName" column-title=""></tds-header-cell>
                                <tds-header-cell id="remove" column-title=""></tds-header-cell>
                            </tds-table-header>
            
                            <tds-table-body-row *ngFor="let user of selectedDealer.Users let i = index">
                                <tds-body-cell id="rowEditUsername{{i}}"></tds-body-cell>
                                <tds-body-cell id="rowEditName{{i}}"></tds-body-cell>
                                <!-- <tds-body-cell id="rowRemove{{i}}" cell-value="{{setEditTableValues(i)}}">
                                    <img class="imgButtons" (click)="revokeDealer(selectedDealer.Id, selectedDealer.Profile.Id, user.Username)"
                                        src="../../../../../assets/images/minus.svg">
                                    <span class="edit" (click)="revokeDealer(selectedDealer.Id, selectedDealer.Profile.Id, user.Username)"></span>
                                </tds-body-cell> -->
                                <tds-body-cell id="rowRemove{{i}}" cell-value="{{setEditTableValues(i)}}">
                                    <img (click)="revokeDealer(selectedDealer.Id, selectedDealer.Profile.Id, user.Username)" class="removeDealerButton"
                                        style="width: 22px; margin-left: 10px; cursor: pointer"
                                        src="../../../../../assets/images/trash.svg" id="removeDealer{{i}}">
                                        <tds-tooltip placement="bottom" selector="#removeDealer{{i}}"
                                        text="{{shared.translate('Delete')}}" mouse-over-tooltip="true"> </tds-tooltip>
                                </tds-body-cell>
                            </tds-table-body-row>
                        </tds-table>
                    </div>
                </tds-block>
            </div>
        </div>
    </span>
</tds-modal>