import { Component } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { LoginGuard } from 'src/app/auth/login.guard';
import { Market } from 'src/app/models/market.model';
import { SharedService } from 'src/app/service/shared.service';
import { Buffer } from 'buffer';

@Component({
  selector: 'app-markets',
  templateUrl: './markets.component.html',
  styleUrls: ['./markets.component.css']
})
export class MarketsComponent {

  public markets: Market[] = JSON.parse(localStorage.getItem('Musys.Ui.Markets.List') || '');

  constructor(public shared: SharedService, private cookie: CookieService, private login: LoginGuard) { }

  ngOnInit() {
  }

  setMarket(
    id: number
  ) {
  
    var markets: Market[] = JSON.parse(
      localStorage.getItem('Musys.Ui.Markets.List') || ''
    );
    markets.forEach((value) => {
      if (value.Id == id) {
        var user = localStorage.getItem('Musys.Ui.Markets.User') != null ? localStorage.getItem('Musys.Ui.Markets.User') : '';
        var const_ck_permissions = user?.toUpperCase() + '_' + this.cookie.get('Musys.Ui.User.MarketUnitId.Current') + '_ACCESS';
        var cookieName = Buffer.from(const_ck_permissions, 'utf8').toString('base64');
        this.cookie.delete(cookieName, '/');

        this.cookie.delete('Musys.Ui.User.UserToken.Current', '/');
        this.cookie.delete('Musys.Ui.User.MarketUnitId.Current', '/');
        this.cookie.delete('Musys.Ui.User.MarketUnitCode.Current', '/');
        this.cookie.delete('Musys.Ui.User.MarketUnitDescription.Current', '/');
        this.cookie.delete('Musys.Ui.User.MarketUnitSystem.Current', '/');
        
        this.login.canActivate(id);
      }
    });
  }

  setTableValues(index: number) {
    document.getElementById(`rowId${index}`).setAttribute("cell-value", this.markets[index].Id.toString());
    document.getElementById(`rowCode${index}`).setAttribute("cell-value", this.markets[index].Code);
    document.getElementById(`rowName${index}`).setAttribute("cell-value", this.markets[index].Name);
    document.getElementById(`rowSwitch${index}`).setAttribute("cell-value", this.shared.translate('Switch to Market'));
    document.getElementById(`rowSwitch${index}`).style.textDecoration = 'underline';
    document.getElementById(`rowSwitch${index}`).style.cursor = 'pointer';
  }
}
